import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/SwipeableDrawer";
import { useSelector } from "react-redux";
import useWindowSize from "../../CustomHooks/useWindow";
import "./styles.css";
import Filter from "./Filter";

export default function FilterDrawer({ ApplyFilter, isFilterOpen, setIsFilterOpen, filters, setFilters }) {

  const { isTablet, isMobile } = useWindowSize();
  const providerDetails = useSelector((state) => state.userDetails.providerDetails);
  const [wasSwipedClose, setWasSwipedClose] = useState(false);

  const handleFilterClick = (selectedDates) => {
    ApplyFilter(selectedDates);
  }

  return (
    <div>
      <Drawer
        id="drawerFilter"
        anchor="bottom"
        open={isFilterOpen}
        onOpen={() => setIsFilterOpen(true)}
        onClose={(e) => setWasSwipedClose(true)}
        PaperProps={{
          sx: {
            width:
              (providerDetails.role === "APC" ||
                providerDetails.role === "Demo User") &&
                !isTablet &&
                !isMobile
                ? "52%"
                : "100%",
            marginLeft:
              (providerDetails.role === "APC" ||
                providerDetails.role === "Demo User") &&
              !isTablet &&
              !isMobile &&
              "23.5%",
            borderRadius: "20px 20px 0px 0px",
            backgroundColor: "#fff",
            color: "rgba(0, 0, 0, 0.87)",
            boxShadow: "0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12)",
            overflowY: "auto",
            display: "flex",
            height: "auto",
            flex: "1 0 auto",
            Zindex: "1200",
            position: "fixed",
            top: "auto",
            outline: 0,
            maxHeight: "100%",
          },
        }}
      >
        <div id="drawerContent">
          <Filter
            ApplyFilter={handleFilterClick}
            isFilterOpen={isFilterOpen}
            setIsFilterOpen={setIsFilterOpen}
            filters={filters}
            setFilters={setFilters}
            wasSwipedClose={wasSwipedClose}
            setWasSwipedClose={setWasSwipedClose}
          />
        </div>
      </Drawer>
    </div >
  );
}
