import React from "react";
import { Navigate, useNavigate } from "react-router-dom";
import axios from "../../interceptors/interceptors";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  saveCurrentScreen,
  saveIsSummaryOpen,
  saveAddNewUserModelOpen,
} from "../../features/uiSlice";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import "../PatientList/style.css";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import useWindowSize from "../../CustomHooks/useWindow";
import { Button } from "@mui/material";

import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import PauseIcon from "@mui/icons-material/Pause";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Modal from "@mui/material/Modal";
import { BlobServiceClient } from "@azure/storage-blob";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import { EditOutlined } from "@ant-design/icons";
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";
import { SetMeal } from "@mui/icons-material";
import LinearProgress from "@mui/material/LinearProgress";
import CircularProgress from "@mui/material/CircularProgress";
import { appInsights } from "../Logger/appinsights";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import AppointmentWidget from "./Widgets/TotalAppointment";
import PieChartWidget from "./Widgets/PieChart";
import AgeChart from "./Widgets/AgeChart";
import ErrorLogsTable from "./Widgets/ErrorLogsTable";
import UIChatWidgetProvider from "../chat-widget/UIChatWidgetProvider";

// const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
//     <Tooltip {...props} classes={{ popper: className }} />
//   ))({
//     [`& .${tooltipClasses.tooltip}`]: {
//       maxWidth: 500,
//       backgroundColor: "rgb(88 156 163)",
//       fontSize: "15px",
//     },
//   });

const bull = (
  <Box
    component="span"
    sx={{ display: "inline-block", mx: "2px", transform: "scale(0.8)" }}
  >
    •
  </Box>
);

const AnalyticsDashboard = (props) => {
  const { isTablet } = useWindowSize();
  const navigate = useNavigate();
  const providerDetails = useSelector(
    (state) => state.userDetails.providerDetails
  );
  const addNewUserModelOpen = useSelector(
    (state) => state.userDetails.addNewUserModelOpen
  );
  const dispatch = useDispatch();

  const [data, setData] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [isSummaryPageOpen, setIsSummaryPageOpen] = useState(false);
  const [currentName, setCurrentName] = useState("");
  const [currentAddress, setCurrentAddress] = useState("");
  const [currentDate, setCurrentDate] = useState("");
  const [currentSummary, setCurrentSummary] = useState("");
  const [currentAudioFile, setCurrentAudioFile] = useState("");
  const [isPaused, setIsPaused] = useState(false);
  const [audioData, setAudioData] = useState("");
  const [userID, setuserID] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isVisible, setisVisible] = useState(false);
  const [value, setValue] = useState("Fax Step");
  // const [fileBase64String, setFileBase64String] = useState<string>("JVBERi0xLjQKJcOkw7zDtsOfCjIgMCBvYmoKPDwvTGVuZ3RoIDQgMCBSL0ZpbHRlci9GbGF0ZURlY29kZS9MZW5ndGggNDAwL1R5cGUvUGFnZXM+PgplbmRvYmoKNiAwIG9iago8PC9MZW5ndGggNDIgMCBSL0ZpbHRlci9GbGF0ZURlY29kZS9MZW5ndGggNDIwL1R5cGUvUGFnZS1UeXBlMS9YTUwvVHlwZTE+PgpzdHJlYW0KeNrVVNs2zAQBuB/3bQd4sU6VWqVqFq1tbtm2t7d3fW6u6r2nK1pJt2rbbd3fW6u6r2nK1pJt2rbbd3fW6u6r2nK1pJt2rbbd3fW6u6r2nK1pJt2rbbd3fW6u6r2nK1pJt2rbbd3fW+ruq9pytaSbdqttt33Vrq62trtt33Vrq62trtt33Vrq62trtt33Vrq62trtt33Vrq62trtt33Vrq62trtt33X/vP/7z/vP/7z/vP/7z/vP/7z/vP/7z/vP/7z/vP/7z/vP/7z/f//+XsCp76A/XiDnqUa8KU1SgA=");
  const [openPreview, setOpenPreview] = useState(false);
  const [visible, setVisible] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [pageNumber1, setPageNumber] = useState(1);
  const [base64String, setBase64String] = useState({ type: "", value: "" });
  const [loadingPdf, setLoadingPdf] = useState(false);
  const [currentFileID, setCurrentFileID] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [oneHealthId, setoneHealthId] = useState("");
  const [email, setEmail] = useState("");
  const [apcID, setApcID] = useState("");
  const [userRole, setUserRole] = useState("");
  const [loadDate, setLoadDate] = useState("");
  const [loadingAddUser, setLoadingAddUser] = useState(false);
  const [saveClicked, setSaveClicked] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    dispatch(saveCurrentScreen("AnalyticsDashboard"));
    // appInsights.trackEvent({
    //   name: "customLogs",
    //   properties: {
    //     userid: providerDetails.oneHealthId,
    //     type: "active",
    //     file: "ManageUsers",
    //     role: providerDetails.role,
    //     apcId: providerDetails.apcId,
    //   },
    // });
    getPatientData();
    // console.log(
    //   "Session Storage 2 in ManageUser",
    //   window.sessionStorage.getItem("UserOnehealthID")
    // );
    // getPatientData2();
  }, []);

  const handleChangePage = (event, newPage) => {
    console.log("event222", newPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    console.log("event22", event.target.value);

    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleOpenModal = (row) => {
    setOpenPreview(true);
    console.log("Rowwww", row);

    // GetFileContent(row.fileId, row.fileType);
  };

  const handleOpenEditPage = (row) => {
    navigate("/fax-workflow-manual-edit", { state: { row } });

    // GetFileContent(row.fileId, row.fileType);
  };

  const validateInput = () => {
    if (
      firstName !== "" &&
      lastName !== "" &&
      email !== "" &&
      userRole !== "" &&
      oneHealthId !== "" &&
      apcID !== ""
    ) {
      return true;
    }
    return false;
  };

  const handleCloseModal = () => {
    setUserRole("");
    setFirstName("");
    setLastName("");
    setEmail("");
    setoneHealthId("");
    setApcID("");
    setSaveClicked(false);
    dispatch(saveAddNewUserModelOpen(false));
  };
  const handleSave = async () => {
    try {
      setSaveClicked(true);
      var validate = validateInput();
      if (validate) {
        setLoadingAddUser(true);

        const date = new Date();
        const cstTime = date
          .toLocaleString("en-US", {
            timeZone: "America/Chicago",
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            // hour: "2-digit",
            // minute: "2-digit",
            // hour12: true,
          })
          .replace(/\//g, "-");
        console.log("2wedate", cstTime);

        const response = await axios.post(
          process.env.REACT_APP_saveProfileInfo,
          {
            firstName: firstName,
            lastName: lastName,
            id: oneHealthId,
            oneHealthId: oneHealthId,
            apcId: apcID,
            useremail: email,
            role: userRole,
            datasecurity: "example",
            organization: "optum",
            loadDate: isEdit ? loadDate : cstTime,
          }
        );
        setLoadingAddUser(false);
        setIsEdit(false);
        handleCloseModal();
        getPatientData();
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleEditThisUser = (record) => {
    setEmail(record.useremail);
    setApcID(record.apcId);
    setUserRole(record.role);
    setFirstName(record.firstName);
    setLastName(record.lastName);
    setLoadDate(record.loadDate);
    // setUserRole(record.role);
    setoneHealthId(record.oneHealthId);
  };

  const convertToCST = (val, type) => {
    const utcTime = new Date("2023-12-12T13:00:00.000Z");
    const cstTime = utcTime.toLocaleString("en-US", {
      timeZone: "America/Chicago",
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });

    console.log(cstTime);

    console.log("cstTime", cstTime);
    const dateAndTime = cstTime.split(", ");
    const date = dateAndTime[0];
    const time = dateAndTime[1];
    if (type === "date") return date;
    return time;
  };

  const getPatientData = async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_getProfileInfo);
      console.log("resposne23", response.data);

      const tempStore = response.data.map((item) => {
        item = {
          firstName: item.firstName,
          lastName: item.lastName,
          fullName: item.firstName + " " + item.lastName,
          oneHealthId: item.oneHealthId,
          apcId: item.apcId,
          useremail: item.useremail,
          role: item.role,
          organization: item.organization,
          loadDate: item.loadDate,
          thisUserDetail: item,
        };
        return item;
      });
      // console.log("dummyData", tempStore);
      setData(tempStore);
    } catch (e) {
      console.log(e);
    }
  };

  const columns1 = [
    {
      label: "User Name",
      id: "fullName",
      key: "fullName",
      // format: (fullName) => <p>Test User</p>,
    },
    {
      label: "APC ID",
      id: "apcId",
      key: "apcId",
      minWidth: "80px",
      // format: (patientDetails) => <p>APC023</p>,
    },
    {
      label: "Email",
      id: "useremail",
      key: "useremail",
      minWidth: "80px",
      // format: (patientDetails) => <p>testuser@optum.com</p>,
    },

    {
      label: "User Role",
      id: "role",
      key: "role",
      format: (role) => (
        <>
          <span
            style={{
              fontSize: "16px",
              fontWeight: "700",
              // color:
              //   scoring < 51 ? "#990000" : scoring < 76 ? "#826100" : "#007000",
            }}
          >
            {role}
          </span>
        </>
      ),
    },

    {
      label: "OHID",
      id: "oneHealthId",
      key: "oneHealthId",
      // format: (autoMatch) => <>{autoMatch ? "Yes" : "No"}</>,
    },

    {
      label: "Edit User Detail ",
      id: "thisUserDetail",
      key: "thisUserDetail",
      format: (record) => {
        console.log("recordwed", record);
        return (
          <EditOutlined
            //  disabled={!record}
            onClick={() => {
              // console.log("record", record);
              setIsEdit(true);
              handleEditThisUser(record);
              dispatch(saveAddNewUserModelOpen(true));
              //   setCurrentFileID(record.fileId);
              //   handleOpenEditPage(record);
              //   setisVisible(true);
            }}
          />
        );
      },
    },
  ];

  const handleClose = (event, reason) => {
    // console.log("reason", event, reason);

    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    setIsPaused(false);
  };
  const action = (
    <React.Fragment>
      <Button color="warning" size="small" onClick={handleClose}>
        Close
      </Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={() => {
          setIsPaused((currentValue) => !currentValue);
        }}
      >
        {isPaused ? <PauseIcon /> : <PlayArrowIcon fontSize="small" />}
      </IconButton>
    </React.Fragment>
  );

  const handleSummaryNavigate = (data) => {
    console.log("dataa", data.completeSummary);
    setCurrentName(data.name);

    setCurrentAddress(data.address);
    setCurrentDate(data.date);
    setCurrentSummary(
      data.completeSummary === undefined ? "" : data.completeSummary
    );
    setCurrentAudioFile(data.podcastFile);
    setIsSummaryPageOpen(true);
    dispatch(saveIsSummaryOpen(true));
    // navigate("/SummaryForPatient", { state: { data } });
  };

  const handleListenSummaryClick = (data) => {
    console.log("dataa", data);
    setCurrentName(data.name);
    setCurrentAddress(data.address);
    setCurrentDate(data.date);
    setCurrentSummary(data.summary);
    setCurrentAudioFile(data.podcastFile);
    setOpen(true);
    // fetchAudioFile(data.podcastFile);
    // navigate("/SummaryForPatient", { state: { data } });
  };

  // const fetchAudioFile = async (generatedPodcastFileName) => {
  //   const connectionString =
  //     "BlobEndpoint=https://sthousecallssandbox.blob.core.windows.net/;QueueEndpoint=https://sthousecallssandbox.queue.core.windows.net/;FileEndpoint=https://sthousecallssandbox.file.core.windows.net/;TableEndpoint=https://sthousecallssandbox.table.core.windows.net/;SharedAccessSignature=sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2023-11-09T20:11:03Z&st=2023-11-06T12:11:03Z&spr=https,http&sig=51Zpp5wdvTKp%2B2mgpRSdaqBwQNBe6ztDRUSQwamM6yc%3D";
  //   const containerName = "outbound";

  //   const blobServiceClient =
  //     BlobServiceClient.fromConnectionString(connectionString);
  //   const containerClient = blobServiceClient.getContainerClient(containerName);
  //   // Accessing the Folder B
  //   const folderBName = "PodcastData";
  //   const folderBClient = containerClient.getBlobClient(`${folderBName}/`);
  //   // Accessing the xyz.wav file inside Folder B

  //   const fileName = generatedPodcastFileName;
  //   // console.log("fileName", fileName , fileName=== undefined);
  //   const fileClient = containerClient.getBlobClient(
  //     `${folderBName}/${fileName}`
  //   );
  //   let latestBlob;
  //   // console.log("fileClient", fileClient.url);
  //   setAudioData(generatedPodcastFileName === undefined ? "" : fileClient.url);
  // };

  return (
    <div style={{ marginTop: "120px", marginLeft: "60px" }}>
      <div
        style={{
          zIndex: -1,
          position: "fixed",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          background:
            "linear-gradient(to right, rgb(144 154 128 / 10%), rgb(115 255 220 / 20%))",
          height: "100%",
          width: "100%",
          fontSize: "24px",
        }}
      >
        <div style={{ marginTop: "140px", marginLeft: "80px" }}>
          <div style={{ display: "flex" }}>
            <div
              style={{
                width: "30%",
                position: "fixed",
                height: "100%",
              }}
            >
              <UIChatWidgetProvider />
            </div>
            <div
              className="scroll"
              style={{
                marginLeft: "30%",
                width: "70%",
                paddingLeft: "40px",
                paddingTop: "10px",
                overflowY: "auto",
                height: "calc(100vh - 160px)",
              }}
            >
              <div style={{ display: "flex", gap: "2%" }}>
                <AppointmentWidget />
                <AppointmentWidget />
                <AppointmentWidget />
                <AppointmentWidget />
              </div>
              <div style={{ display: "flex", marginTop: "40px", gap: "10px" }}>
                <AgeChart />
                <PieChartWidget />
              </div>
              <div style={{ display: "flex", marginTop: "40px", gap: "10px" }}>
                <ErrorLogsTable />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnalyticsDashboard;
