import React, { useEffect } from "react";
import { Dialog, Slide } from "@mui/material";
// import { TransitionProps } from "@mui/material/transitions";
// import useIsFirstRender from "../../lib/hooks/useIsFirstRender";
import { useSelector, useDispatch } from "react-redux";
// import { useAppDispatch, useAppSelector } from "../../app/hooks";
// import { updateAIChatOpen } from "../../features/ui/uiSlice";
import ChatWidget from "./ChatWidget";
// import { appDialogTransparentPropsNoBlur } from "../../theme";

// const DEBUG_SHOW_WIDGET = false && process.env.NODE_ENV === "development";

// const Transition = React.forwardRef(function Transition(
//   props: TransitionProps & {
//     children: React.ReactElement<any, any>,
//   },
//   ref: React.Ref<unknown>
// ) {
//   return <Slide direction="left" ref={ref} {...props} />;
// });

// interface UIChatWidgetProviderProps {
//   FaxFileIds?: Object;
// }

const UIChatWidgetProvider = () => {
  // const dispatch = useAppDispatch();
  // const isFirstRender = useIsFirstRender();
  // const { FaxFileIds } = props;
  // const aiChatOpen: boolean =
  // useAppSelector < boolean > ((state) => state.ui.aiChatOpen);
  const dynamicPrompt = useSelector((state) => state.userDetails.dynamicPrompt);

  const handleClose = async () => {
    // await dispatch(updateAIChatOpen(false));
  };

  // debug only
  // useEffect(() => {
  //   if (DEBUG_SHOW_WIDGET && isFirstRender) {
  //     setTimeout(() => {
  //       dispatch(updateAIChatOpen(true));
  //     }, 100);
  //   }
  // }, [dispatch, isFirstRender]);

  return (
    // <Dialog
    //   fullScreen={true}
    //   disableRestoreFocus={false}

    //   open={aiChatOpen}
    //   onClose={handleClose}
    //   TransitionComponent={Transition}
    //   PaperProps={appDialogTransparentPropsNoBlur}
    // >
    <ChatWidget FaxFileIds={"2556575"} onClose={handleClose} />
    // </Dialog>
  );
};

export default UIChatWidgetProvider;
