import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as MenuBar } from "../../assets/MenuBar.svg";
import { ReactComponent as AccountIcon } from "../../assets/account_circle.svg";
import BorderLine from "../../assets/BorderLine.svg";
import CloseIcon from "@mui/icons-material/Close";
import LogoutIcon from "@mui/icons-material/Logout";
import {
  saveCurrentScreen,
  saveIsMenuOpen,
  clearUserDetails,
  saveIsAudioFooterOpen,
} from "../../features/uiSlice";
import { appInsights } from "../Logger/appinsights";
import "./styles.css";
import { LogoutHandler } from "../../api/Logout";
import styled from "styled-components";

const Root = styled.div`
  margin-bottom: 32px;
  background-color: white;
  overflow: hidden;
  border-radius: 20px 20px 0px 0px;
  `

const MenuGrabBar = styled.div`
  height: 12px;
  text-align: center;
  width: 100px;
  margin: auto;

  & svg {
    position: relative;
    top: -4px;
  }
  `

const MenuHeader = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  color: #001D5B;
  padding: 16px 0;
  margin-bottom: 16px; 

  border-bottom: solid 1px #CBCCCD;
  `

const HeadDiv = styled.div`
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;  
  font-family: "Optum Sans", Helvetica, Arial;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; 
  padding: 0 16px;
  
  &:last-child {
    justify-content: flex-end;
  }
`

const MenuItem = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 6px;
  align-self: stretch;
  font-family: "Optum Sans", Helvetica, Arial;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: #4B4D4F;
  padding: 10px 16px;

  &.selected {
    font-weight: 700;
    background-image: url(${BorderLine});
    background-repeat: no-repeat;
    background-position: 2px center;
  }

  &:first-child {
    margin-top: 16px;
    border: solid 1px red;
  }

  & svg {
    width: 20px;
    height: 20px;
  }
  `

const MenuInfo = styled.div`
  display: flex;
  padding: 0px 16px;
  align-items: flex-start;
  gap: 6px;
  align-self: stretch;
  font-family: "Optum Sans", Helvetica, Arial;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  color: #4B4D4F;
  margin-top: 24px;

  `

const Menu = ({ closeMenu }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const providerDetails = useSelector(
    (state) => state.userDetails.providerDetails
  );
  const currentScreen = useSelector((state) => state.userDetails.currentScreen);

  const handleMenuItemClick = (screen, navigateTo) => {
    dispatch(saveIsAudioFooterOpen(false));
    screen && dispatch(saveCurrentScreen(screen));
    dispatch(saveIsMenuOpen(false));
    navigateTo && navigate(navigateTo);
  }

  const handleLogout = (event) => {
    logoutTracking();
    LogoutHandler(providerDetails, (response) => {
      window.sessionStorage.clear();
      dispatch(clearUserDetails());
    })

  };

  const logoutTracking = () => {
    appInsights.trackEvent({
      name: "customLogs",
      properties: {
        userid: providerDetails.oneHealthId,
        type: "logout",
        file: "AppLayout",
        role: providerDetails.role,
        apcId: providerDetails.apcId,
      },
    });
  };

  const handleGrabBarOnly = (e) => {
    if (['MenuGrabBar', 'GrabBarSVG'].includes(e.target.id)) return;

    e.stopPropagation();
  }

  const handleGrabBarClick = (e) => {
    if (['MenuGrabBar', 'GrabBarSVG'].includes(e.target.id))
      closeMenu();

    e.stopPropagation();
  }

  return (
    <Root
      id="divMenuBarContainerDiv"
      onClick={handleGrabBarClick}
      onTouchStart={handleGrabBarOnly}
    >
      <MenuGrabBar id='MenuGrabBar'>
        <MenuBar />
      </MenuGrabBar>
      <MenuHeader id='MenuHeader'>
        <HeadDiv>
          Menu
        </HeadDiv>

        <HeadDiv>
          <CloseIcon
            id="iconClose"
            style={{ cursor: "pointer" }}
            onClick={() => { dispatch(saveIsMenuOpen(false)); }}
          />
        </HeadDiv>
      </MenuHeader>

      <MenuItem
        id="appointmentLabelDiv"
        className={window.location.pathname.includes('Summary') ? 'selected' : ''}
        onClick={() => handleMenuItemClick('patientList', '/Summary')}
      >
        Appointments
      </MenuItem>

      {/* <MenuItem
        id="divAccountSettings"
        className={window.location.pathname.includes('Settings') ? 'selected' : ''}
        onClick={() => handleMenuItemClick('Setting', 'Setting', '/Setting')}
      >
        Account settings
      </MenuItem> */}

      <MenuItem
        id="divLeaveFeedback"
        className={window.location.pathname.includes('User-Feedback') ? 'selected' : ''}
        onClick={() => handleMenuItemClick('Feedback', '/User-Feedback')}
      >
        Leave us feedback
      </MenuItem>

      <MenuItem
        id="divLogout"
        onClick={handleLogout}
      >
        Log out
        <LogoutIcon id="iconLogout" />
      </MenuItem>

      <MenuInfo id="divUserProfile"      >
        <AccountIcon />
        {providerDetails.firstName + " " + providerDetails.lastName}
      </MenuInfo>
    </Root>
  );
};

export default Menu;
