import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { appInsights } from "../Logger/appinsights";
import LogoutIcon from "@mui/icons-material/Logout";
import { ReactComponent as Alert } from "../../assets/Alert.svg";
import Modal from "@mui/material/Modal";
import { clearUserDetails } from "../../features/uiSlice";
import { LogoutHandler } from "../../api/Logout";
import styled from "styled-components";

const emailAddress = "housecalls_support@optum.com";  
const emailSubject = `Please add my One Health Id to HouseCalls-${process.env.REACT_APP_Environment}`;  
const emailBody = "Hello, My One Health ID is: ";  


const ModalStyles = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center'
}

const Content = styled.div`
  text-align: center;
  width: 90%;
  padding: 16px;
  padding-top: 24px;
  background-color: white;
  border-radius: 16px;
  box-sizing: border-box;
`

const Header = styled.div`
  text-align: center;
  margin-left: auto;
  margin-right: auto;
`

const Link = styled.span`
  margin: 16px 0;
  color: #0C55B8;
  cursor: pointer;
  text-decoration: underline;
  font-family: "Optum Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`

const LogoutPanel = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #0C55B8;
  font-family: 'Optum Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  margin: 8px;
`

const LogoutText = styled.span`
  margin-right: 8px; 
`

const ModalTitle = styled.div`
  color: #002677;
  text-align: center;
  margin-top: 16px;

  font-family: "Optum Sans", Helvetica, Arial;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
`

const ModalText = styled.div`
  color: #323334;
  text-align: center;
  align-self: stretch;
  font-family: "Optum Sans", Helvetica, Arial;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; 
  margin-top: -8px;
`

const ErrorScreen = ({ unknownUser, onlySmallScreen }) => {
  const [ohid, setOhid] = useState("");
  const dispatch = useDispatch();

  const providerDetails = useSelector(
    (state) => state.userDetails.providerDetails
  );

  useEffect(() => {
    setOhid(window.sessionStorage.getItem("userID"));
  }, [])

  const handleLogout = (event) => {
    logoutTracking();
    LogoutHandler(providerDetails, (response) => {
      window.sessionStorage.clear();
      dispatch(clearUserDetails());
    })
  };
  const logoutTracking = () => {

    appInsights.trackEvent({
      name: "customLogs",
      properties: {
        userid: providerDetails.oneHealthId,
        type: "logout",
        file: "AppLayout",
        role: providerDetails.role,
        apcId: providerDetails.apcId,
      },
    });
  };

  return (
    <Modal style={ModalStyles} open={unknownUser} disableEnforceFocus>
      <Content>
        <Header>
          <Alert />
        </Header>

        <ModalTitle>Account Not Verified</ModalTitle>

        <ModalText>
          <p>
            Your account needs to be verified before you can view this
            application. Please contact us at
            &nbsp;
          <Link>
          <a href={`mailto:${emailAddress}?subject=${encodeURIComponent(emailSubject)}&body=${encodeURIComponent(emailBody)}${ohid}`} 
          style={{color:'#0C55B8 '}}
          >
          housecalls_support@optum.com
          </a>
            
          </Link>.
          </p>
          <p>
            We apologize for any inconvenience.
          </p>
        </ModalText>

        <LogoutPanel onClick={handleLogout} >
          <LogoutText>Log Out</LogoutText>
          <LogoutIcon fontSize='16' />
        </LogoutPanel>

      </Content>
    </Modal>
  );
};

export default ErrorScreen;
