import React, { useEffect, useState } from "react";
import { ReactComponent as OptumLogoSvg } from "../assets/optum_logo.svg";
import TabletNavigationMenu from "./TabletNavigationMenu";
import { useSelector, useDispatch } from "react-redux";
import { saveProviderDetails } from "../features/uiSlice";
import styled from "styled-components";

const Root = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 48px;  
  z-index: 99;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 16px 0 8px;
  background-color: #FAF8F2;
`

const Section = styled.div`  
  width: 33.3%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &.left {
    justify-content: flex-start;
  }

  &.right {
    justify-content: flex-end;
  }
`

const Header = () => {
  const dispatch = useDispatch();
  const providerDetails = useSelector(
    (state) => state.userDetails.providerDetails
  );

  const currentScreen = useSelector((state) => state.userDetails.currentScreen);

  useEffect(() => {
    if (
      Object.keys(providerDetails).length === 0 &&
      JSON.parse(window.sessionStorage.getItem("UserOnehealthID") !== null)
    ) {
      dispatch(
        saveProviderDetails(
          JSON.parse(window.sessionStorage.getItem("providerDetails"))
        )
      );
    }

  }, [currentScreen]);
  
  return (
    <Root >
      <Section id="divNavigationMenuContent" className="left">
        <TabletNavigationMenu />
      </Section>

      <Section className="center">
        <OptumLogoSvg
          style={{
            justifySelf: 'center',
            backgroundColor: "#F16231s",
            fill: "#F16231",
            width: "110px",
            height: "32px",
            flexShrink: 0,
          }}
        />
      </Section>

      <Section className="right">
        <div id="divProfileMenu">
        </div>
      </Section>

    </Root>
  );
};

export default Header;
