
import React, { useContext, useEffect, useState } from 'react';
import Message from './Message';
import TypingAnimationChatBot from './TypingAnimationChatBot';
import './style.scss'
import './styles.scss'

const Messages = ({messages,loader,type}) => {

  // console.log("msgs",messages);
  return (
    <div className= { type === "AskAI" ?  "messages" : "messages2"} >
    {messages.map((m) => (
        <Message message={m} key={m.id} loader={loader} />
      ))}
      {loader && <TypingAnimationChatBot message={undefined} loader={undefined} />}

    </div>
  );
};

export default Messages;
