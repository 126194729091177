import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { createBrowserHistory } from "history";

const browserHistory = createBrowserHistory({ basename: "" });
const reactPlugin = new ReactPlugin();

const appInsights = (process.env.REACT_APP_Environment === 'offline')
  ?
  {
    loadAppInsights: () => { console.log("appInsight.loadAppInsights - not available offline.") },
    trackException: () => { console.log("appInsight.trackException - not available offline.") },
    trackEvent: () => { console.log("appInsight.trackEvent - not available offline.") },
    trackTrace: () => { console.log("appInsight.trackTrace - not available offline.") },
    setAuthenticatedUserContext: () => { console.log("appInsight.setAuthenticatioedUserContext - not available offline.") }
  }
  :
  new ApplicationInsights({
    config: {
      connectionString: process.env.REACT_APP_APPINSIGHT_CONNECTION_STRING,
      extensions: [reactPlugin],
      extensionConfig: {
        [reactPlugin.identifier]: { history: browserHistory },
      },
    },
  });

appInsights.loadAppInsights();
export { reactPlugin, appInsights };
