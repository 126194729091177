// utils/useWindowSize.js

import React, { useState } from "react";
import { useTheme, useMediaQuery, createTheme } from "@mui/material";

export default function useWindowSize() {
  // const theme = useTheme();
  // const onlySmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  // const onlyMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  // const betweenSM = useMediaQuery(theme.breakpoints.between("sm", "md"));
  // const onlyLargeScreen = useMediaQuery(theme.breakpoints.up("md"));
  const theme = createTheme({
    breakpoints: {
      values: {
        mobile: 0,
        minPlay: 320,
        mobileBreak: 486,
        mobileEnd: 1025,
        st: 600,
        tablet: 1025,
        te: 1025,
        laptop: 1025,
        desktop: 1200,
      },
    },
  });
  const minPlayScreen = useMediaQuery(theme.breakpoints.down("minPlay"));
  const onlySmallScreen = useMediaQuery(theme.breakpoints.down("mobileBreak"));
  // const onlyMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isMobile = useMediaQuery(theme.breakpoints.down("mobileEnd"));
  const isTablet = useMediaQuery(theme.breakpoints.between("mobileEnd", "te"));

  // const onlyLargeScreen = useMediaQuery(theme.breakpoints.up("md"));

  // const isSSR = typeof window !== "undefined";

  const [isTabletView, setIsTabletView] = useState({
    isTablet: isTablet,
    isMobile: isMobile,
    onlySmallScreen: onlySmallScreen,
    minPlayScreen: minPlayScreen,
  });
  // const [windowSize, setWindowSize] = React.useState({
  //   width: isSSR ? 1200 : window.innerWidth,
  //   height: isSSR ? 768 : window.innerHeight,
  // });

  function changeWindowSize() {
    setIsTabletView({
      isTablet: isTablet,
      isMobile: isMobile,
      onlySmallScreen: onlySmallScreen,
      minPlayScreen: minPlayScreen,
    });
  }

  React.useEffect(() => {
    window.addEventListener("resize", changeWindowSize);

    return () => {
      window.removeEventListener("resize", changeWindowSize);
    };
  }, [isTablet, isMobile, onlySmallScreen, minPlayScreen]);

  return isTabletView;
}
