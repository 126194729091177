import { ResponsiveBar } from "@nivo/bar";
import React from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";

import { Button } from "@mui/material";

import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import PauseIcon from "@mui/icons-material/Pause";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.

const data = [
  {
    country: "AD",
    "hot dog": 48,
    "hot dogColor": "hsl(149, 70%, 50%)",
    burger: 83,
    burgerColor: "hsl(24, 70%, 50%)",
    sandwich: 120,
    sandwichColor: "hsl(51, 70%, 50%)",
    kebab: 107,
    kebabColor: "hsl(278, 70%, 50%)",
    fries: 184,
    friesColor: "hsl(66, 70%, 50%)",
    donut: 62,
    donutColor: "hsl(81, 70%, 50%)",
  },
  {
    country: "AE",
    "hot dog": 146,
    "hot dogColor": "hsl(71, 70%, 50%)",
    burger: 152,
    burgerColor: "hsl(276, 70%, 50%)",
    sandwich: 68,
    sandwichColor: "hsl(248, 70%, 50%)",
    kebab: 57,
    kebabColor: "hsl(164, 70%, 50%)",
    fries: 95,
    friesColor: "hsl(229, 70%, 50%)",
    donut: 22,
    donutColor: "hsl(270, 70%, 50%)",
  },
  {
    country: "AF",
    "hot dog": 128,
    "hot dogColor": "hsl(318, 70%, 50%)",
    burger: 120,
    burgerColor: "hsl(211, 70%, 50%)",
    sandwich: 127,
    sandwichColor: "hsl(356, 70%, 50%)",
    kebab: 181,
    kebabColor: "hsl(254, 70%, 50%)",
    fries: 141,
    friesColor: "hsl(10, 70%, 50%)",
    donut: 146,
    donutColor: "hsl(138, 70%, 50%)",
  },
  {
    country: "AG",
    "hot dog": 99,
    "hot dogColor": "hsl(223, 70%, 50%)",
    burger: 116,
    burgerColor: "hsl(15, 70%, 50%)",
    sandwich: 151,
    sandwichColor: "hsl(85, 70%, 50%)",
    kebab: 103,
    kebabColor: "hsl(234, 70%, 50%)",
    fries: 90,
    friesColor: "hsl(138, 70%, 50%)",
    donut: 77,
    donutColor: "hsl(227, 70%, 50%)",
  },
  {
    country: "AI",
    "hot dog": 155,
    "hot dogColor": "hsl(213, 70%, 50%)",
    burger: 27,
    burgerColor: "hsl(84, 70%, 50%)",
    sandwich: 129,
    sandwichColor: "hsl(331, 70%, 50%)",
    kebab: 10,
    kebabColor: "hsl(52, 70%, 50%)",
    fries: 13,
    friesColor: "hsl(300, 70%, 50%)",
    donut: 41,
    donutColor: "hsl(167, 70%, 50%)",
  },
  {
    country: "AL",
    "hot dog": 139,
    "hot dogColor": "hsl(276, 70%, 50%)",
    burger: 149,
    burgerColor: "hsl(161, 70%, 50%)",
    sandwich: 161,
    sandwichColor: "hsl(198, 70%, 50%)",
    kebab: 155,
    kebabColor: "hsl(20, 70%, 50%)",
    fries: 115,
    friesColor: "hsl(344, 70%, 50%)",
    donut: 179,
    donutColor: "hsl(353, 70%, 50%)",
  },
  {
    country: "AM",
    "hot dog": 114,
    "hot dogColor": "hsl(141, 70%, 50%)",
    burger: 198,
    burgerColor: "hsl(241, 70%, 50%)",
    sandwich: 63,
    sandwichColor: "hsl(330, 70%, 50%)",
    kebab: 23,
    kebabColor: "hsl(326, 70%, 50%)",
    fries: 176,
    friesColor: "hsl(329, 70%, 50%)",
    donut: 86,
    donutColor: "hsl(291, 70%, 50%)",
  },
];

const dummayData = [
  {
    Env: "Dev",
    description: "This is a cors error, triggered from GetPatientData api",
    platform: "Backend Python",
    severity: "Critical",
    occurence: 20,
  },
  {
    Env: "Test",
    description: "This is a validation error, triggered from SubmitForm api",
    platform: "Backend Java",
    severity: "High",
    occurence: 15,
  },
  {
    Env: "Prod",
    description:
      "This is a database connection error, triggered from GetUserProfile api",
    platform: "Backend C#",
    severity: "Medium",
    occurence: 10,
  },
  // Add more objects as needed
];

const MyResponsiveBar = () => {
  const navigate = useNavigate();
  const providerDetails = useSelector(
    (state) => state.userDetails.providerDetails
  );
  const addNewUserModelOpen = useSelector(
    (state) => state.userDetails.addNewUserModelOpen
  );
  const dispatch = useDispatch();
  const [data, setData] = useState(dummayData);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const columns1 = [
    {
      label: "Environment",
      id: "Env",
      key: "Env",
      minWidth: "50px",
      // maxWidth: "10px",
      format: (Env) => (
        <div style={{ textAlign: "center" }}>
          <p>{Env}</p>
        </div>
      ),
    },
    {
      label: "Description",
      id: "description",
      key: "description",
      format: (description) => (
        <div
          style={{
            backgroundColor: "azure",
            padding: "5px",
            borderRadius: "10px",
            textAlign: "left",
            justifyContent: "left",
            alignItems: "center",
          }}
        >
          <p>{description}</p>
        </div>
      ),
      // minWidth: "80px",
      // format: (patientDetails) => <p>APC023</p>,
    },
    {
      label: "Platform",
      id: "platform",
      key: "platform",
      format: (platform) => (
        <div style={{ textAlign: "center" }}>
          <p>{platform}</p>
        </div>
      ),
      // minWidth: "80px",
      // format: (patientDetails) => <p>testuser@optum.com</p>,
    },

    {
      label: "Severity",
      id: "severity",
      key: "severity",
      format: (severity) => (
        <div
          style={{
            backgroundColor:
              severity === "Critical"
                ? "#ff00006e"
                : severity === "High"
                ? "#ffa5008a"
                : "#f4e494a1",
            padding: "2px",
            borderRadius: "10px",
            textAlign: "center",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <p>{severity}</p>
        </div>
      ),
    },

    {
      label: "Occurence",
      id: "occurence",
      key: "occurence",
      format: (occurence) => (
        <div style={{ textAlign: "center" }}>
          <p>{occurence}</p>
        </div>
      ),
      // format: (autoMatch) => <>{autoMatch ? "Yes" : "No"}</>,
    },

    // {
    //   label: "Edit User Detail ",
    //   id: "thisUserDetail",
    //   key: "thisUserDetail",
    //   format: (record) => {
    //     console.log("recordwed", record);
    //     return (
    //       <EditOutlined
    //         //  disabled={!record}
    //         onClick={() => {
    //           // console.log("record", record);
    //           setIsEdit(true);
    //           handleEditThisUser(record);
    //           dispatch(saveAddNewUserModelOpen(true));
    //           //   setCurrentFileID(record.fileId);
    //           //   handleOpenEditPage(record);
    //           //   setisVisible(true);
    //         }}
    //       />
    //     );
    //   },
    // },
  ];

  const handleChangePage = (event, newPage) => {
    console.log("event222", newPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    console.log("event22", event.target.value);

    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <div style={{ margin: "2px 30px" }}>
      <div>
        <div>
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer sx={{ maxHeight: 520 }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns1.map((column) => (
                      <TableCell
                        key={column.id}
                        style={{
                          width: 160,
                          // borderRight: "1px solid #CBCCCD",
                          textAlign: "center",
                          fontSize: "16px",
                          fontWeight: "700",
                          color: "#002677",
                          minWidth: column.minWidth,
                          maxWidth: column.minWidth,
                        }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row.code}
                        >
                          {columns1.map((column) => {
                            const value = row[column.id];
                            // console.log("value", value, column.id);

                            return (
                              <TableCell
                                style={{
                                  width: 360,
                                  // borderRight: "1px solid #CBCCCD",
                                  backgroundColor:
                                    column.id === "role" &&
                                    (value === "APC"
                                      ? "rgb(169 219 226 / 56%)"
                                      : value === "Super Admin"
                                      ? "#FEF9EA"
                                      : "#EFF6EF"),
                                }}
                                key={column.id}
                              >
                                {column.format ? column.format(value) : value}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[
                5,
                10,
                25,
                { label: "All", value: data.length },
              ]}
              component="div"
              count={data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </div>
      </div>
    </div>
  );
};

export default MyResponsiveBar;
