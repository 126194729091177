import './typingAnimation.css'

function TypingAnimation() {

    return (
       <div className="typing-animation">
          <span></span>
          <span></span>
          <span></span>
       </div>
    );
 };

export default TypingAnimation;
