import React, { useEffect, useState } from "react";
import axios from "../interceptors/interceptors";
import { patientsData, cancellationData } from '../api/MockData';

export const FetchCancelledAppointments = async (OHID, callback) => {
  callback = (callback instanceof Function) ? callback : () => { };

  if (process.env.REACT_APP_Environment === 'offline')
    return callback(cancellationData);

  axios.get(process.env.REACT_APP_getCancellationNotification + "oneHealthId=" + OHID,
    {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then(response => callback(response.data))
    .catch(() => callback(null))

};


