import { ResponsiveBar } from "@nivo/bar";

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.

const data = [
  {
    country: "AD",
    "hot dog": 48,
    "hot dogColor": "hsl(149, 70%, 50%)",
    burger: 83,
    burgerColor: "hsl(24, 70%, 50%)",
    sandwich: 120,
    sandwichColor: "hsl(51, 70%, 50%)",
    kebab: 107,
    kebabColor: "hsl(278, 70%, 50%)",
    fries: 184,
    friesColor: "hsl(66, 70%, 50%)",
    donut: 62,
    donutColor: "hsl(81, 70%, 50%)",
  },
  {
    country: "AE",
    "hot dog": 146,
    "hot dogColor": "hsl(71, 70%, 50%)",
    burger: 152,
    burgerColor: "hsl(276, 70%, 50%)",
    sandwich: 68,
    sandwichColor: "hsl(248, 70%, 50%)",
    kebab: 57,
    kebabColor: "hsl(164, 70%, 50%)",
    fries: 95,
    friesColor: "hsl(229, 70%, 50%)",
    donut: 22,
    donutColor: "hsl(270, 70%, 50%)",
  },
  {
    country: "AF",
    "hot dog": 128,
    "hot dogColor": "hsl(318, 70%, 50%)",
    burger: 120,
    burgerColor: "hsl(211, 70%, 50%)",
    sandwich: 127,
    sandwichColor: "hsl(356, 70%, 50%)",
    kebab: 181,
    kebabColor: "hsl(254, 70%, 50%)",
    fries: 141,
    friesColor: "hsl(10, 70%, 50%)",
    donut: 146,
    donutColor: "hsl(138, 70%, 50%)",
  },
  {
    country: "AG",
    "hot dog": 99,
    "hot dogColor": "hsl(223, 70%, 50%)",
    burger: 116,
    burgerColor: "hsl(15, 70%, 50%)",
    sandwich: 151,
    sandwichColor: "hsl(85, 70%, 50%)",
    kebab: 103,
    kebabColor: "hsl(234, 70%, 50%)",
    fries: 90,
    friesColor: "hsl(138, 70%, 50%)",
    donut: 77,
    donutColor: "hsl(227, 70%, 50%)",
  },
  {
    country: "AI",
    "hot dog": 155,
    "hot dogColor": "hsl(213, 70%, 50%)",
    burger: 27,
    burgerColor: "hsl(84, 70%, 50%)",
    sandwich: 129,
    sandwichColor: "hsl(331, 70%, 50%)",
    kebab: 10,
    kebabColor: "hsl(52, 70%, 50%)",
    fries: 13,
    friesColor: "hsl(300, 70%, 50%)",
    donut: 41,
    donutColor: "hsl(167, 70%, 50%)",
  },
  {
    country: "AL",
    "hot dog": 139,
    "hot dogColor": "hsl(276, 70%, 50%)",
    burger: 149,
    burgerColor: "hsl(161, 70%, 50%)",
    sandwich: 161,
    sandwichColor: "hsl(198, 70%, 50%)",
    kebab: 155,
    kebabColor: "hsl(20, 70%, 50%)",
    fries: 115,
    friesColor: "hsl(344, 70%, 50%)",
    donut: 179,
    donutColor: "hsl(353, 70%, 50%)",
  },
  {
    country: "AM",
    "hot dog": 114,
    "hot dogColor": "hsl(141, 70%, 50%)",
    burger: 198,
    burgerColor: "hsl(241, 70%, 50%)",
    sandwich: 63,
    sandwichColor: "hsl(330, 70%, 50%)",
    kebab: 23,
    kebabColor: "hsl(326, 70%, 50%)",
    fries: 176,
    friesColor: "hsl(329, 70%, 50%)",
    donut: 86,
    donutColor: "hsl(291, 70%, 50%)",
  },
];

const MyResponsiveBar = () => (
  <div style={{ width: "600px", height: "400px" }}>
    <ResponsiveBar
      data={data}
      keys={["hot dog", "burger", "sandwich", "kebab", "fries", "donut"]}
      indexBy="country"
      margin={{ top: 50, right: 130, bottom: 80, left: 60 }}
      padding={0.3}
      valueScale={{ type: "linear" }}
      indexScale={{ type: "band", round: true }}
      colors={{ scheme: "nivo" }}
      defs={[
        {
          id: "dots",
          type: "patternDots",
          background: "inherit",
          color: "#38bcb2",
          size: 4,
          padding: 1,
          stagger: true,
        },
        {
          id: "lines",
          type: "patternLines",
          background: "inherit",
          color: "#eed312",
          rotation: -45,
          lineWidth: 6,
          spacing: 10,
        },
      ]}
      fill={[
        {
          match: {
            id: "fries",
          },
          id: "dots",
        },
        {
          match: {
            id: "sandwich",
          },
          id: "lines",
        },
      ]}
      borderColor={{
        from: "color",
        modifiers: [["darker", 1.6]],
      }}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: "country",
        legendPosition: "middle",
        legendOffset: 32,
        truncateTickAt: 0,
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: "food",
        legendPosition: "middle",
        legendOffset: -40,
        truncateTickAt: 0,
      }}
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor={{
        from: "color",
        modifiers: [["darker", 1.6]],
      }}
      legends={[
        {
          dataFrom: "keys",
          anchor: "bottom-right",
          direction: "column",
          justify: false,
          translateX: 120,
          translateY: 0,
          itemsSpacing: 2,
          itemWidth: 100,
          itemHeight: 20,
          itemDirection: "left-to-right",
          itemOpacity: 0.85,
          symbolSize: 20,
          effects: [
            {
              on: "hover",
              style: {
                itemOpacity: 1,
              },
            },
          ],
        },
      ]}
      role="application"
      ariaLabel="Nivo bar chart demo"
      barAriaLabel={(e) =>
        e.id + ": " + e.formattedValue + " in country: " + e.indexValue
      }
    />
  </div>
);

export default MyResponsiveBar;
