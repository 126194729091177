import { Stack, Box, Tooltip } from "@mui/material";  
  
const StatusBars = ({ data }) => {  
  const total =  
    data[0] + data[1] + data[2] + data[3] + data[4];  
  
  const colors = [  
    "#ffff99",  
    "#ffdb4d",  
    "#ffad33",  
    "#00E396",  
    "#FF4560",  
  ];  
  
  const labels = [  
    "Scheduled",  
    "Appointment Scheduled",  
    "Reschedule Member Called",  
    "Assessment Sent to CIP",  
    "Cancelled",  
  ];  
  
  return (  
    <Stack direction="row" spacing={0.1}>  
      {data.map((value, index) => (  
        <Tooltip title={`${labels[index]}: ${value}`} key={index}>  
          <Box  
            sx={{  
              width: `${(value / total) * 100}%`,  
              height: 10,  
              backgroundColor: colors[index],  
              borderRadius: 0.5,
              marginRight: 1,
            }}  
          />  
        </Tooltip>  
      ))}  
    </Stack>  
  );  
};  
  
export default StatusBars;  
