import React, { useEffect, useState } from "react";
import axios from "../interceptors/interceptors";

export const LogoutHandler = async (providerDetails, callback) => {
  callback = callback instanceof Function ? callback : () => {};

  // console.log("inside logout handler");

  const token = JSON.parse(window.localStorage.getItem("id_token"));
  const logoutUrl =
  process.env.REACT_APP_OHID_Logout_URL + "?id_token_hint=" +
    token +
    "&post_logout_redirect_uri=" +
    process.env.REACT_APP_OHID_LogoutURL;
  window.sessionStorage.removeItem("SignedToken"); // Remove signed token on logout
  window.sessionStorage.removeItem("Bearer"); // Remove Bearer/MSFT token on logout
  localStorage.clear();
  if(token === null){
    window.location.replace(process.env.REACT_APP_OHID_LogoutURL);
  }
 else window.location.replace(logoutUrl);
  // console.log("return callback");
  return callback(true);
};
