import React from "react";
import { useEffect, useState, useRef } from "react";
import AudioPlayerToast from "./AudioPlayerToast";
import audioFile from "../../assets/audio.wav";
import { ReactComponent as PlayArrowIcon } from "../../assets/play-icon.svg";
import { ReactComponent as PauseIcon } from "../../assets/pause-icon.svg";
import { ReactComponent as ReplayIcon } from "../../assets/replay-icon.svg";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import LinearProgress from '@mui/material/LinearProgress';
import styled from "styled-components";
import "./style.css";

const AudioBar = styled.div`
  color:  #FFF;
  font-family: "Optum Sans", Helvetica, Arial;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; 
  `

const NameAge = styled.div`
  color: #FFF;
  font-family: "Optum Sans", Helvetica, Arial;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px; 
  text-transform: capitalize;
  `
const Description = styled.div`
  color: #FFF;
  opacity: .85;
  font-family: "Optum Sans", Helvetica, Arial;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  text-transform: none;
  `

const ProgressBar = styled.div`
  position: absolute;
  left: 12px;
  right: 12px;
  bottom: 0px;
  `

const PlayBar = styled.div`
  display: flex;
  gap: 12px;
  `

const AudioPlayerFooter = ({ screen, openSnackbar, age, name, initials, src, transcript, autoplay }) => {
  const [progress, setProgress] = useState(0);
  const [isToastOpen, setIsToastOpen] = useState(false);
  const [audioSrc, setAudioSrc] = useState(null);
  const [showTitle, setShowTitle] = useState(true);
  const audioPlayerRef = useRef();

  if (src.startsWith("PLAY_OFFLINE"))
    src = audioFile;

  useEffect(() => {
    if (!audioPlayerRef.current) return;

    setAudioSrc(src);
    audioPlayerRef.current.autoplay = autoplay;
    audioPlayerRef.current.currentTime = 0;
  }, [src, name])

  useEffect(() => {
    if (!screen?.toUpperCase()?.startsWith('SUMMARY'))
      return setShowTitle(false);

    setShowTitle(audioPlayerRef?.current?.paused);
  }, [screen])

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    audioPlayerRef.current?.pause();
  };

  const handlePlayPauseClick = (e) => {
    audioPlayerRef.current?.paused
      ? audioPlayerRef.current?.play()
      : audioPlayerRef.current?.pause()

    setShowTitle(false);
    e.stopPropagation();
  }

  const action = (
    <PlayBar>
      {
        showTitle ||
        <IconButton
          id="PatinetSummarySnackbarPlayerReplayButton"
          size="small"
          aria-label="close"
          color="inherit"
          style={{ padding: 0 }}
          onClick={(e) => {
            e.stopPropagation();
            audioPlayerRef.current.currentTime = 0;
            audioPlayerRef.current?.play();
          }}
        >
          <ReplayIcon />
        </IconButton>
      }

      <IconButton
        id="PatinetSummarySnackbarPlayerPlayActionButton"
        size="small"
        aria-label="close"
        color="inherit"
        style={{ padding: 0 }}
        onClick={handlePlayPauseClick}
      >
        {
          audioPlayerRef.current?.paused
            ? <PlayArrowIcon id="PatinetSummarySnackbarPlayerPlayPlayIcon" />
            : <PauseIcon id="PatinetSummarySnackbarPlayerPlayPauseIcon" />

        }
      </IconButton>
    </PlayBar>
  );

  const handlePlayerClick = () => {
    setIsToastOpen(true);
  }

  return (
    <>
      <Snackbar
        id="PatientSummaryAudioSnackbar"
        onClick={handlePlayerClick}
        open={openSnackbar}
        autoHideDuration={null}
        onClose={handleClose}
        message={
          <AudioBar>
            {
              showTitle
                ?
                <>Listen to patient summary</>
                :
                <NameAge>
                  {name?.trim() + (age ? ", " + age : "")}
                  <Description>Patient summary</Description>
                </NameAge>
            }
            <audio
              ref={audioPlayerRef}
              id="myAudio"
              style={{ display: "none" }}
              controls
              src={audioSrc}
              className="audio-player"
              onTimeUpdate={(e) => {
                const progressPercentage = (audioPlayerRef.current?.currentTime / audioPlayerRef.current?.duration) * 100;
                setProgress(isNaN(progressPercentage) ? 0 : progressPercentage);
              }}
            >

              <source src={audioSrc} />
              <a href={audioSrc}> Download audio </a>
              Your browser does not support the audio element.

            </audio>

            <ProgressBar>
              {
                <LinearProgress
                  variant="determinate"
                  value={progress}
                />
              }
            </ProgressBar>
          </AudioBar>
        }
        action={action}
      />

      <AudioPlayerToast
        open={isToastOpen}
        onClose={() => { setIsToastOpen(false) }}
        patient={{ name: name, age: age, initials: initials }}
        transcript={transcript}
        audioPlayerRef={audioPlayerRef}
      />
    </>
  );
};

export default AudioPlayerFooter;
