import React, { useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { Box, Grid } from "@mui/material";
import Header from "./Main/Header";
import TabletHeader from "./Main/TabletHeader";
import MobileHeader from "./Main/MobileHeader";
import SideHeader from "./Main/SideHeader";
import LoginPage from "./Main/LoginPage";
import MobileLoginPage from "./Main/UserLoginPage/MobileLoginPage";
import {AppLayout, activeTracking} from "./Main/PatientList/AppLayout";
import MusicPlayer from "./Main/PatientList/MusicPlayer";
import useWindow from "../src/CustomHooks/useWindow";
import ManageUserAccess from "../src/Main/ManageUserAccess/ManageUserAccess";
import UserLogs from "../src/Main/UserLogs/UserLogs";
import APCApp from "../src/Main/APCApp/APCApp";
import { useSelector, useDispatch } from "react-redux";
import { appInsights } from "./Main/Logger/appinsights";
import AnalyticsDashboard from "./Main/AnalyticsDashboard_AIOPS/Screen";
import Feedback from "./Main/FeedbackScreen/Feedback";
import { LogoutHandler } from "./api/Logout";
import { clearUserDetails } from "./features/uiSlice";

function App() {
  const { isTablet, isMobile } = useWindow();
  const dispatch = useDispatch();
  const location = useLocation();
  const providerDetails = useSelector(
    (state) => state.userDetails.providerDetails
  );

  const currentScreen = useSelector((state) => state.userDetails.currentScreen);
  const [audioClient, setAudioClient] = useState("");
  const [transcript, setTranscript] = useState("");
  // const [transcript, setTranscript] = useState("");

  let timer;

  const resetTimer = () => {
    clearTimeout(timer);

    timer = setTimeout(handleLogout, 5 * 60 * 1000); // 5 minutes in milliseconds
  };

  useEffect(() => {
    if (location.pathname !== "/") {
      resetTimer();

      // Event listener to reset the timer on user activity
      const events = [
        "load",
        "mousemove",
        "mousedown",
        "mouseup",
        "click",
        "mouseover",
        "scroll",
        "keypress",
      ];

      const resetTimerOnEvent = () => {
        resetTimer();
      };

      events.forEach((event) => {
        window.addEventListener(event, resetTimerOnEvent);
      });

      return () => {
        // Cleanup the event listeners when the component unmounts
        events.forEach((event) => {
          window.removeEventListener(event, resetTimerOnEvent);
        });
        clearTimeout(timer);
      };
    }
  }, [location, timer, resetTimer]);

  const handleLogout = (event) => {
    if (window.location.hostname.includes("localhost")) return;
    
    activeTracking(providerDetails.oneHealthId,providerDetails.role,providerDetails.apcId)
    logoutTracking();
    
    if (location.pathname !== "/") {
      LogoutHandler(providerDetails, (response) => {
        window.sessionStorage.clear();
        dispatch(clearUserDetails());
      })
    }
  };
  const logoutTracking = () => {
    appInsights.trackEvent({
      name: "customLogs",
      properties: {
        userid: providerDetails.oneHealthId,
        type: "logout",
        file: "AppLayout",
        role: providerDetails.role,
        apcId: providerDetails.apcId,
      },
    });
  };

  return (
    <div
      id="RootLevelDiv"
      className="App-header"
      style={{
        height: currentScreen === "loginPage" && "100vh",
        backgroundColor: currentScreen === "loginPage" && 'rgb(250 248 242)',
        width:
          (providerDetails.role === "APC" ||
            providerDetails.role === "Demo User") &&
          !isMobile &&
          !isTablet &&
          "60%",
        margin:
          (providerDetails.role === "APC" ||
            providerDetails.role === "Demo User") &&
          !isMobile &&
          !isTablet &&
          "auto",
      }}
    >

      <Box id="RootLevelBoxHeader">
        {currentScreen !== "loginPage" && (
          <Grid id="RootLevelGridHeader">
            {
              isMobile
                ? <MobileHeader />
                : isTablet
                  ? <TabletHeader />
                  : providerDetails.role === "APC" || providerDetails.role === "Demo User"
                    ? <TabletHeader />
                    : <Header />
            }
          </Grid>
        )}
      </Box>
      <div id="RootLevelDivContainer" style={{ display: "flex" }}>
        {!isTablet &&
          !isMobile &&
          currentScreen !== "loginPage" &&
          providerDetails.role !== "APC" &&
          providerDetails.role !== "Demo User" && (
            <div
              id="RootLevelSideHeader"
              style={{ position: "fixed", height: "100vh", width: "10%" }}
            >
              <SideHeader />
            </div>
          )}
        <div
          id="RootLevelScreens"
          style={{
            width: isTablet || isMobile ? "100%" : "90%",
            marginLeft: isTablet || isMobile ? "0px" : "42px",
            marginTop: "67px"
          }}
        >
          <Routes>
            <Route
              path="/"
              exact
              element={
                isMobile || isTablet ? <MobileLoginPage /> : <LoginPage />
              }
            />
            <Route
              path="/Summary/*"
              exact
              element={
                <AppLayout
                  setAudioClient={setAudioClient}
                />
              }
            />
            <Route
              path="/ManageUserAccess/*"
              exact
              element={
                Object.keys(providerDetails).length !== 0 && (
                  <ManageUserAccess />
                )
              }
            />
            <Route
              path="/UserLogs/*"
              exact
              element={
                Object.keys(providerDetails).length !== 0 && <UserLogs />
              }
            />
            <Route
              path="/APCApp/*"
              exact
              element={Object.keys(providerDetails).length !== 0 && <APCApp />}
            />
            <Route
              path="/Dashboard/*"
              exact
              element={
                Object.keys(providerDetails).length !== 0 && (
                  <AnalyticsDashboard />
                )
              }
            />
            {/* This route is deprecated */}
            <Route
              path="/SummaryPodcast/*"
              exact
              element={
                Object.keys(providerDetails).length !== 0 && (
                  <MusicPlayer
                    audioClient={audioClient}
                    transcript={transcript}
                    setTranscript={setTranscript}
                  />
                )
              }
            />
            <Route
              path="/User-Feedback/*"
              exact
              element={
                Object.keys(providerDetails).length !== 0 && <Feedback />
              }
            />
          </Routes>
        </div>
      </div>
    </div>
  );
}

export default App;