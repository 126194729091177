import React, { useEffect, useState } from "react";
import { ReactComponent as OptumLogoSvg } from "../assets/optum_logo.svg";
import { ReactComponent as SearchIconSvg } from "../assets/search.svg";
import ProfileMenu from "./ProfileMenu";
import { useSelector, useDispatch } from "react-redux";
import {
  saveAddNewUserModelOpen,
  saveProviderDetails,
  saveSearchInput,
} from "../features/uiSlice";
import {
  Button,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  OutlinedInputProps,
} from "@mui/material";

const Header = () => {
  const styles = {
    root: {},
    labelArea: {
      fontSize: "16px",
      fontWeight: 700,
    },
    controlArea: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "center",
      width: "100%",
    },
    formControl: {
      width: "100%",

      maxWidth: "800px",
    },
    searchInput: {
      width: "100%",

      "&.MuiOutlinedInput-root": {
        backgroundColor: "#ffffff",
        borderRadius: "999px",

        "& fieldset": {
          "& legend": {},
          "&.MuiOutlinedInput-notchedOutline": {
            borderRadius: "999px",
          },
        },
      },
    },
    searchIcon: {},
  };
  const dispatch = useDispatch();
  const providerDetails = useSelector(
    (state) => state.userDetails.providerDetails
  );
  const currentScreen = useSelector((state) => state.userDetails.currentScreen);
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    // console.log(
    //   "providerDetails",
    //   providerDetails,
    //   currentScreen,
    //   JSON.parse(window.sessionStorage.getItem("UserOnehealthID"))
    // );
    if (
      Object.keys(providerDetails).length === 0 &&
      JSON.parse(window.sessionStorage.getItem("UserOnehealthID") !== null)
    ) {
      dispatch(
        saveProviderDetails(
          JSON.parse(window.sessionStorage.getItem("providerDetails"))
        )
      );
    } else if (
      JSON.parse(
        window.sessionStorage.getItem("UserOnehealthID") === null &&
          (currentScreen === "AppLayout" ||
            currentScreen === "Manage_user_Access")
      )
    ) {
      console.log(
        "Session Storage",
        window.sessionStorage.getItem("UserOnehealthID")
      );
    }
  }, [currentScreen]);

  const renderedEndAdornment = <SearchIconSvg /> ? (
    <InputAdornment sx={styles.searchIcon} position="end">
      <SearchIconSvg />
    </InputAdornment>
  ) : undefined;

  return (
    <div>
      <div
        style={{
          width: "100vw",
          height: "144px",
          backgroundColor: "#FAF8F2",
          position: "fixed",
          display: "flex",
          zIndex: 1000,
        }}
      >
        <div style={{ marginTop: "40px", marginLeft: "40px" }}>
          <div>
            {" "}
            <OptumLogoSvg
              style={{
                marginTop: "10px",
                backgroundColor: "#F16231s",
                fill: "#F16231",
                flexShrink: 0,
              }}
            />
          </div>

          <label
            style={{
              color: "#002677",
              fontSize: "18px",
              fontWeight: 700,
            }}
          >
            House Calls
          </label>
        </div>
        <div
          style={{
            marginTop: "40px",
            marginLeft: "300px",
            width: "578px",
            height: "64px",
          }}
        >
          <Grid item sx={styles.controlArea}>
            <FormControl size="small" sx={styles.formControl}>
              {Object.keys(providerDetails).length !== 0 && (
                <OutlinedInput
                  id="1"
                  sx={styles.searchInput}
                  type="text"
                  size="medium"
                  fullWidth
                  color="primary"
                  multiline={false}
                  label={null}
                  notched={false}
                  placeholder="What are you looking for?"
                  value={inputValue}
                  endAdornment={renderedEndAdornment}
                  onChange={(e) => {
                    console.log("Change");
                    setInputValue(e.target.value);
                    dispatch(saveSearchInput(e.target.value));
                  }}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                    }
                  }}
                />
              )}
            </FormControl>
          </Grid>
        </div>
        <div
          style={{
            marginTop: "40px",
            marginLeft: "300px",
            width: "578px",
            height: "64px",
          }}
        >
          <ProfileMenu />
        </div>
        {currentScreen === "Manage_user_Access" && (
          <div
            style={{
              marginTop: "100px",
              left: "80%",
              width: "180px",
              height: "64px",
              position: "absolute",
            }}
          >
            <Button
              style={{
                borderRadius: "999px",
              }}
              disabled = {providerDetails.role === "Client Manager"}
              variant="outlined"
              onClick={() => {
                dispatch(saveAddNewUserModelOpen(true));
              }}
            >
              Add New User
            </Button>{" "}
          </div>
        )}
      </div>

      <div style={{ display: "flex" }}>
        <div
          style={{
            width: "85vw",
            marginTop: "10px",
            marginLeft: "30px",
            height: "30px",
          }}
        ></div>
        <div
          style={{
            marginTop: "10px",
            marginRight: "30px",
            height: "30px",
          }}
        ></div>
      </div>
    </div>
  );
};

export default Header;
