import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useEffect, useState, useRef } from "react";

import { useDispatch, useSelector } from "react-redux";
import { saveCurrentScreen } from "../../features/uiSlice";
import "./style.css";

import useWindowSize from "../../CustomHooks/useWindow";
import { Button } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import profilePic from "../../assets/bgimage4.jpg";
import audioFile from "../../assets/audio.wav";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import MessageIcon from "@mui/icons-material/Message";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  bottom: "50%",
  transform: "translate(-50%, -50%)",
  width: 625,
  height: 600,
  bgcolor: "background.paper",
  overflow: "scroll",
  // border: "1px solid #000",
  // boxShadow: 24,
  p: 4,
};
// const wavesurfer = WaveSurfer.create({
//   container: "#waveform",
//   waveColor: "#4F4A85",
//   progressColor: "#383351",
//   url: "/audio.mp3",
// });

const MusicPlayer = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { name, age,nameForProfilePic } = location.state;
  const { isTablet } = useWindowSize();
  dispatch(saveCurrentScreen("summaryPlayer"));
  // props.setTranscript(
  //   "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
  // );
  // console.log("props", props.transcript);

  const text =    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum";

  const transcriptArray = props.transcript.split(" ");
  const [patientData, setPatinetData] = useState();
  const [currentWordIndex, setCurrentWordIndex] = useState(0);

  useEffect(() => {
    dispatch(saveCurrentScreen("summaryPlayer"));
    if (location.state) {
      const { data } = location.state;
      setPatinetData(data);
      console.log("data", data);
    }
  }, [location.state]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    // setOpen(false);
  };



  const action = (
    <React.Fragment>
      <Button color="secondary" size="small" onClick={handleClose}>
        UNDO
      </Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <div
    id = "MusicPlayerScreen"
      style={{
        marginTop: "80px",
        backgroundColor: "#001D5B",
        height: "calc(100vh - 80px)",
      }}
    >
      <div id = "MusicPlayerScreenInnerDiv" style={{ marginLeft: "2%", marginRight: "2%" }}>
        <div id = "MusicPlayerScreenHeaderLabelDiv" style={{ display: "flex" }}>
          <div style={{ width: "100%", textAlign: "center" }}>
            <label
            id = "MusicPlayerScreenHeaderLabel"
              style={{
                fontSize: 14,
                fontWeight: 700,
                color: "#ffff",
                lineHeight: "26px",
              }}
            >
              Now Playing
            </label>
          </div>
        </div>
        <div
        id = "MusicPlayerScreenImageContainer"
          style={{
            height: "100%",
            width: "100%",
            marginTop: "60px",
            position: "relative",
          }}
        >
          <div
          id = "MusicPlayerScreenImageDiv"
            style={{
              position: "relative",
              width: "100%",
              height: "100%",
              borderRadius: "2%",
              textAlign: "center",
            }}
          >
            <img
              style={{ height: "160px", width: "160px", borderRadius: "100%" }}
              src={profilePic}
              alt=""
            />
            <div
            id = "MusicPlayerScreenNameDiv"
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: "100%",
              }}
            >
              <h1 id = "MusicPlayerScreenImageH1Tag" style={{ color: "#683fdad1" }}>
                {nameForProfilePic.charAt(0) + nameForProfilePic.split(" ")[1].charAt(0)}
              </h1>
            </div>
          </div>
        </div>
        <div id = "MusicPlayerScreenPatientInfoDiv">
          <p id = "MusicPlayerScreenPatientName" style={{ color: "white", fontSize: 16, fontWeight: 700 }}>
            {name},{age}
          </p>
          <p id = "MusicPlayerScreenPatientSummaryLabel" style={{ color: "white", fontSize: 14, fontWeight: 400 }}>
            Patient Summary
          </p>
        </div>
        {/* {console.log("fetchedAudio_", props.audioClient)} */}
        <AudioPlayer
          autoPlay={false}
          src={props.audioClient}
          onPlay={(e) => console.log("onPlay")}
          // onListen={handleListen}
          // other props here
        />

        <div
        id = "MusicPlayerScreenTranscriptDiv"
          style={{
            marginTop: "100px",
            width: "100%",
            height: "250px",
            backgroundColor: "#D9F6FA",
            overflowY: "scroll",
            borderRadius: "12px",
          }}
        >
          <p id = "MusicPlayerScreenTranscriptTitle" style={{ padding: "5px", fontWeight: 600, fontSize: 16 }}>
            Audio Transcript
          </p>
          <p id = "MusicPlayerScreenTranscriptText" style={{ padding: "10px", fontWeight: 500, fontSize: 14 }}>
            {props.transcript}
          </p>
          {/* <p style={{ padding: "10px", fontWeight: 500, fontSize: 14 }}>
            {transcriptArray.map((word, index) => (
              <span
                key={index}
                style={{
                  color:
                    index <= currentWordIndex ? "black" : "rgb(0 0 0 / 63%)",
                }}
              >
                {word}{" "}
              </span>
            ))}
          </p> */}
        </div>
      </div>
      {/* <div style={{ marginTop: "50px" }}>
        <AudioPlayerFooter openSnackbar={true} name={name} />
      </div> */}
    </div>
  );
};

export default MusicPlayer;
