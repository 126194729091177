import React, { useEffect, useState } from "react";
import axios from "../interceptors/interceptors";
import { patientsData, cancellationData } from '../api/MockData';

export const SignedTokenGenerator = async (atoken, callback) => {
  callback = (callback instanceof Function) ? callback : () => { };
  const tokenEndpoint = process.env.REACT_APP_CreateSignedToken;

  if (process.env.REACT_APP_Environment === 'offline')
    return callback(null);
  
  console.log("TOKEN: ", atoken);

  return axios
    .post(tokenEndpoint, {
      "OHIDCode": atoken
    })
    .then((response) => {
      // Handle the response
      let success = false;
      if (response?.data) {
        success = true;
      } else if (response?.data === "") {
        success = +response.status === 200;
      }
      // In the code snippet +response.status === 200, the response.status is typically a string 
      // representing the HTTP status code (e.g., '200'). By applying the + operator, 
      // JavaScript attempts to convert the string to a number.

      return callback(success);
    })
    .catch((error) => {
      // Handle the error
      console.log(error);
      return false;
    });
};


