import * as React from "react";
import Box from "@mui/material/Box";
import { useEffect, useState, useRef } from "react";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import { PropaneSharp } from "@mui/icons-material";
import "./style.css";
import profilePic from "../../assets/bgimage4.jpg";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import axios from "../../interceptors/interceptors";
import AudioPlayerFooter from "./AudioPlayerFooter";
import { Alert } from "@mui/material";
import { useDispatch } from "react-redux";
import { saveShouldRefresh } from "../../features/uiSlice";
export default function TemporaryDrawer({
  setShowSummary,
  showSummary,
  currentSummary,
  age,
  name,
  nameForProfilePic,
  generatedPodcastFileName,
  //   setAudioClient,
}) {
  const dispatch = useDispatch();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const [audioData, setAudioData] = React.useState("");
  const [anchor, setAnchor] = React.useState("right");

  useEffect(() => {
    fetchAudioFile(generatedPodcastFileName);
  }, []);

  const fetchAudioFile = async (generatedPodcastFileName) => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_getSasUrl + "fileName=" + generatedPodcastFileName
      );
      // console.log("fetchedAudio", response.data);

      setAudioData(generatedPodcastFileName === undefined ? "" : response.data);
      //   setAudioClient(
      //     generatedPodcastFileName === undefined ? "" : response.data
      //   );
    } catch (e) {
      console.log(e);
    }
  };

  const toggleDrawer = (anchor, open) => (event) => {
    dispatch(saveShouldRefresh(true));
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setShowSummary({ ...showSummary, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{
        width: anchor === "top" || anchor === "bottom" ? "auto" : 450,
        backgroundColor: "rgb(213 178 79 / 7%)",
      }}
      role="presentation"
    >
      <div
        id="SummaryRootDiv"
        style={{
          marginTop: "150px",
        }}
      >
        <div>
          <div
            id="SummaryPageLayout"
            style={{ marginLeft: "2%", marginRight: "2%" }}
          >
            <div id="SummaryPage_NameContainer" style={{ display: "flex" }}>
              <div style={{ width: "80%", paddingTop: "10px" }}>
                <label
                  id="SummaryPage_NameLabel"
                  style={{
                    fontSize: 20,
                    fontWeight: 700,
                    color: "#001D5B",
                    lineHeight: "26px",
                  }}
                >
                  {name},{age}
                </label>
              </div>

              <div id="SummaryPage_ProfilePicContainer" className="image-container">
                <img
                  id="SummaryPage_ProfilePic"
                  className="profile-pic2"
                  src={profilePic}
                  alt="Profile Pic"
                />
                <div id="SummaryPage_ProfileIconDiv" className="text-overlay">
                  <h2 id="SummaryPage_ProfileIconText" className="name-initials">
                    {nameForProfilePic.charAt(0) + nameForProfilePic.split(" ")[1].charAt(0)}
                  </h2>
                </div>
              </div>
            </div>
            <ReactMarkdown
              rehypePlugins={[rehypeRaw]}
              children={currentSummary}
            />
          </div>
          {/* <div>
            <div style={{ height: "50px" }}>
              <Alert severity="info">
         
                This is an info alert — <strong>check it out!</strong>
              </Alert>
            </div>
          </div> */}
        </div>
        <Divider />

        {/* <AudioPlayerFooter
          setOpen={true}
          openSnackbar={true}
          name={name}
          age={age}
          src={audioData}
        /> */}
        {/* </div> */}
        {/* </div> */}
        {/* </div> */}
      </div>

      {/* <List>
        {["All mail", "Trash", "Spam"].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton>
              <ListItemIcon>
                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List> */}
    </Box>
  );

  return (
    <div>
      {/* {["left", "right", "top", "bottom"].map((anchor) => ( */}
      <React.Fragment key={anchor}>
        {/* <Button onClick={toggleDrawer(anchor, true)}>{anchor}</Button> */}
        <Drawer
        style={{zIndex:'999'}}
          anchor={anchor}
          open={showSummary[anchor]}
          onClose={toggleDrawer(anchor, false)}
        >
          {list(anchor)}
        </Drawer>
      </React.Fragment>
    </div>
  );
}
