import { useNavigate } from "react-router-dom";
import {  useDispatch } from "react-redux";
import styled from "styled-components";
import { ReactComponent as OptumLogo } from "../../assets/Optum.svg";

import { saveCurrentScreen } from "../../../src/features/uiSlice";

const Root = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 400px;
`;

const LoginPanel = styled.div`
  width: 348px;
  box-shadow: 0px 2px 12px rgba(131, 144, 175, 0.3);
  border-radius: 8px;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  display: inline-flex;
  padding-top: 32px;
  padding-bottom: 32px;
  padding-left: 16px;
  padding-right: 16px;
  font-weight: 400;
  background-color: white;
`;

const LoginButton = styled.button`
  display: flex;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 999px;
  border: 1px solid var(--primary-110, #001D5B);
  background: var(--primary-110, #001D5B);
  color: var(--neutral-0, #FFF);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 150% */
  margin-bottom: 32px;
  font-family: Optum Sans;
`;

const HeaderTitle = styled.div`
  margin-top: 32px;
  color: var(--primary-110, var(--Primary-110, #001D5B));
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 44.8px */
  margin-bottom: 12px;
  font-family: Optum Sans;
`

const BodyText = styled.div`
  color: var(--neutral-80, #4B4D4F);
  text-align: center;
  /* desktop/body/2/a */
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 125% */
  margin-bottom: 40px;
  font-family: "Optum Sans", Helvetica, Arial;
`

const FooterText = styled.div`
  color: var(--neutral-80, #4B4D4F);
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  display: flex;
  flex-direction: row;

  /* desktop/body/3/a */
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  font-family: "Optum Sans", Helvetica, Arial;
  line-height: 18px; /* 128.571% */
`

const LinkText = styled.text`
  color: var(--primary-70, #0C55B8);
  font-feature-settings: 'clig' off, 'liga' off;

  /* desktop/body/3/c-u */
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  font-family: "Optum Sans", Helvetica, Arial;
  text-decoration-line: underline;
  cursor: pointer;
  margin-left: 4px;
`

const LoginPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  dispatch(saveCurrentScreen("loginPage"));

  const handleRedirectForLogin = () => {
    if (process.env.REACT_APP_Environment === 'offline')
      return navigate("/Summary");

    window.location.href =
      "https://" +
      process.env.REACT_APP_OHID_HOSTNAME +
      "/oidc/authorize?pfidpadapterid=OptumIdOIDCStage&client_id=" +
      process.env.REACT_APP_OHID_CLIENTID +
      "&response_type=code&scope=openid+profile+email&redirect_uri=" +
      process.env.REACT_APP_OHID_REDIRECTURL;
  };

  const handleRegistration = () => {
    window.location.href = process.env.REACT_APP_OHID_RegistrationLink;
  };

  const handleTestingRedirect = () => {
    navigate("/Summary");
  };

  return (
    <div id="LoginPageDiv">
      <Root style={{ padding: "16px" }} id="LoginPageRoot">
        <LoginPanel id="LoginPagePanel">
          <OptumLogo id="LoginPageOptumLogo" />
          <HeaderTitle id="LoginPageHouseCallsLogo">House Calls</HeaderTitle>
          <BodyText id="LoginPageAboutApp">Login to view your upcoming appointments</BodyText>

          <LoginButton
            id="LoginPageButtonOHID"
            onClick={() => handleRedirectForLogin()}
          >
            Log in with One Healthcare ID
          </LoginButton>

          <FooterText id="LoginPageOHIDRegister">Don't have a One Healthcare ID?<LinkText id="LoginPageOHIDRegisterSpan" onClick={handleRegistration}>Register here</LinkText></FooterText>

        </LoginPanel>
      </Root>
    </div>
  );
};

export default LoginPage;
