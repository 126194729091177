import React, { useEffect, useState } from "react";
import { ReactComponent as OptumLogoSvg } from "../assets/optum_logo.svg";
import TabletNavigationMenu from "./TabletNavigationMenu";
import TabletProfileMenu from "./ProfileMenuFolder/TabletProfileMenu";
import useWindow from "../../src/CustomHooks/useWindow";
import { useSelector, useDispatch } from "react-redux";
import {
  saveAddNewUserModelOpen,
  saveProviderDetails,
} from "../features/uiSlice";

const Header = () => {
  const { isTablet, isMobile } = useWindow();
  const dispatch = useDispatch();
  const providerDetails = useSelector(
    (state) => state.userDetails.providerDetails
  );
  const currentScreen = useSelector((state) => state.userDetails.currentScreen);
  const [currentPage, setCurrentPage] = useState(window.location.href);
  useEffect(() => {
    console.log(
      "providerDetails",
      providerDetails,
      currentScreen,
      JSON.parse(window.sessionStorage.getItem("UserOnehealthID"))
    );
    if (
      Object.keys(providerDetails).length === 0 &&
      JSON.parse(window.sessionStorage.getItem("UserOnehealthID") !== null)
    ) {
      dispatch(
        saveProviderDetails(
          JSON.parse(window.sessionStorage.getItem("providerDetails"))
        )
      );
    }
    //  else if (
    //   JSON.parse(
    //     window.sessionStorage.getItem("UserOnehealthID") === null &&
    //       (currentScreen === "AppLayout" ||
    //         currentScreen === "Manage_user_Access")
    //   )
    // ) {
    //   window.location.replace("/");
    // }
  }, [currentScreen]);

  return (
    <div
      style={{
        marginLeft:
          (providerDetails.role === "APC" ||
            providerDetails.role === "Demo User") &&
          !isTablet &&
          "43px",
      }}
    >
      <div
        style={{
          width:
            (providerDetails.role === "APC" ||
              providerDetails.role === "Demo User") &&
            !isTablet
              ? "54%"
              : "100vw",

          height: "80px",
          // backgroundColor: "rgb(213 178 79 / 7%)",
          position: "absolute",
          display: "flex",
          zIndex: 1,
          textAlign: "center",
          fontSize: "12px",
          fontWeight: "700",
          lineHeight: "16px",
          paddingBottom: "14px",
          paddingTop: "14px",
          fontFamily: "Optum Sans",
          paddingLeft: "24px",
        }}
      >
        <div style={{marginTop: "18px" }}>
          <TabletNavigationMenu />
        </div>
        <div style={{           
          position: 'absolute', 
          left: '50%', 
          top: '50%',
          transform: 'translate(-50%, -50%)'
        }}>
          <div>
            {" "}
            <OptumLogoSvg
              style={{
                backgroundColor: "#F16231s",
                fill: "#F16231",
                width: "110px",
                height: "32px",
                flexShrink: 0,
              }}
            />
          </div>
        </div>
        <div style={{ marginTop: "20px", marginLeft: "20%" }}>
          {/* <TabletProfileMenu /> */}
        </div>
      </div>
    </div>
  );
};

export default Header;
