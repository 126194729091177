import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { saveNotificationArray } from "../../features/uiSlice";
import { ReactComponent as CloseIcon } from "../../assets/close.svg";
import { ReactComponent as ErrorIcon } from "../../assets/error.svg";
import { SaveCancelledAppointments } from "../../api/SaveCancellationNotification";
import { styled, keyframes } from "styled-components";
import "./style.css";

const fadeIn = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
  `

const Root = styled.div`
  padding: 16px;
  margin: 9px;
  animation: ${fadeIn} 1s;
  `

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 2px;
  `

const LeftHead = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;

  color: #4B4D4F;
  font-family: "Optum Sans", Helvetica, Arial;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  `

const Body = styled.div`
  padding-left: 28px;
  font-family: 'optum Sans';
  font-size: 14px;
  line-height: 18px;
  color: #4B4D4F;

  & span  {
    text-transform: capitalize;
  }
  `

const CancelledNotification = (props) => {
  const dispatch = useDispatch();

  const rootElement = useRef();

  const notificationArray = useSelector(
    (state) => state.userDetails.notificationArray
  );

  const providerDetails = useSelector(
    (state) => state.userDetails.providerDetails
  );

  const removeNotification = async (data) => {
    try {
      SaveCancelledAppointments(providerDetails.oneHealthId, data.appointmentId, (response) => {
        console.log("Notification Closed", response);
      });
      dispatch(
        saveNotificationArray(
          notificationArray.filter((obj) => obj.appointmentId !== data.appointmentId)
        )
      );
    }
    catch (e) {
      console.log(e);
    }
  };

  const handleClose = (data) => {
    rootElement.current.style.transition = 'opacity 1s';
    rootElement.current.style.opacity = 0;

    setTimeout(() => {
      removeNotification(data);
    }, 500);
  }

  return (
    <Root ref={rootElement} className="Cancelled_AppointmentToastDiv" key={props.keys}>
      <Header>
        <LeftHead>
          <ErrorIcon />
          Appointment canceled
        </LeftHead>

        <CloseIcon
          onClick={(e) => {
            handleClose(props.data);
          }}
          style={{ marginLeft: "" }}
        />
      </Header>

      <Body>
        Your appointment {props.data.date !== "today" && "on"} {" "}
        <strong>{props.data.date}</strong> at
        <strong> {props.data.time} </strong>with{" "}
        <span>{props.data.name}</span> has been canceled.
      </Body>
    </Root>
  );
};

export default CancelledNotification;
