import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  saveCurrentScreen,
  saveNotificationArray,
  saveIsAudioFooterOpen,
  saveShouldRefresh,
} from "../../features/uiSlice";
import Summary from "./Summary";
import AudioPlayerFooter from "./AudioPlayerFooter";
import MenuDrawer from "../ProfileMenuFolder/MenuDrawer";
import FilterComponent from "../ProfileMenuFolder/FilterDrawer";
import rehypeRaw from "rehype-raw";
import ReactMarkdown from "react-markdown";
import { ReactComponent as Filter_list } from "../../assets/filter_list.svg";
import { ReactComponent as AccessTimeIcon } from "../../assets/schedule.svg";
import { ReactComponent as ErrorOutlineIcon } from "../../assets/error-outline.svg";
import { ReactComponent as LaunchIcon } from "../../assets/link_icon.svg";
import { ReactComponent as ArrowIcon } from "../../assets/arrow-icon.svg"
import { ReactComponent as PlayIcon } from "../../assets/Icon-right-24.svg"
import { ReactComponent as FlagIcon } from "../../assets/flag.svg";
import Footer from "./Footer";
import CancelledNotification from "./CancelledNotification";
import { GetAppointmentsList } from "../../api/GetAppointmentsList";
import { FetchCancelledAppointments } from "../../api/FetchCancelledAppointments";
import { FetchAudioURL } from "../../api/FetchAudioURL";
import { OpenNativeMaps } from "../../api/OpenNativeMaps";
import styled from "styled-components";
import "./style.css";


const SummaryPage = styled.div`
  display: ${props => props.display};
  `

const PatientListPage = styled.div`
  display: ${props => props.display};
  `


const CancelOverlay = styled.div`
  position: fixed;
  top: 64px;
  left: 0;
  right: 0;
  z-index: 9999999;
  `

const AppointmentLabel = styled.div`
  font-size: 26px;
  line-height: 32px;
  font-weight: 700;
  color: #001d5b;
  margin-bottom: 8px;
  `

const ApptCountLabel = styled.div`
  font-size: 16px;
  line-height: 20px; 
  font-weight: 400; 
  color: #4B4D4F;
  `

const HeaderRow1 = styled.div`

  `

const HeaderRow2 = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 24px;

  & span:last-child {
    display: flex;
    align-items: center;
    font-size: 16px;
    line-height: 20px; 
    font-weight: 700;
    color: #001d5b;
    cursor: pointer;
  }

  & svg {
    margin: 0 8px;
  }
  `

const DividerLine = styled.div`
  height: 0px;
  margin: 32px 0; 
  border-top: solid 1px #B1B2B4;

  &:first-child {
    margin-top: 24px;
  }
  `

const AppointmentList = styled.div`

  `
const AppointmentPanel = styled.div`
  // display: flex;
  // flex-direction: column;
  // align-items: flex-start;
  // gap: 24px;
  // align-self: stretch;
  `

const AppointmentTag = styled.div`
  display: inline-flex;
  padding: 2px 8px;
  align-items: center;
  gap: 4px;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: "Optum Sans", Helvetica, Arial;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  border-radius: 4px;
  margin-bottom: 8px;

  &.cancelled {
    border: 1px solid #900;
    background: #FCF0F0;
    color: #900;
    line-height: 16px;
  }

  &.today_past:not(.cancelled) {
    opacity: 0.5;
    background: #D9F6FA;
    color: #001D5B;
  }

  &.past:not(.cancelled) {
    opacity: 0.5;
    background: #D9F6FA;
    color: #001D5B;
  }

  &.today:not(.cancelled) {    
    background: #D9F6FA;
    color: #001D5B;
  }

  &.future:not(.cancelled) {
    background: #E5E5E6;
    color: #4B4D4F;
  }
  `

const PatientName = styled.div`
  color: #001D5B;
  font-family: "Optum Sans", Helvetica, Arial;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  text-transform: capitalize;

  &.cancelled {
    color: #7D7F81;
    text-decoration: line-through;
  }
  `

const PatientAddress = styled.div`
  display: inline-flex;
  flex-direction: row;
  color: #0C55B8;
  font-family: "Optum Sans", Helvetica, Arial;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px; 
  text-decoration-line: underline;
  margin-top: 12px;
  gap: 4px;  

  & div {
    text-transform: capitalize;
  }

  & svg {
    min-width: 18px;
    min-height: 18px;
  }

  &.cancelled {
    margin-bottom: 8px;
    color: #7D7F81;
    text-decoration: line-through;

    & svg {
      display: none;
    }
  }
  `

const PatientText = styled.div`
  color: #4B4D4F;
  font-family: "Optum Sans", Helvetica, Arial;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;


  & a {
    color: #0C55B8;
  }
  `
  const FlagMessage = styled.div`
  color: #4B4D4F;
  font-family: "Optum Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  padding-left: 6px;

  `
  const FlagContainer = styled.div`

  margin-top: 16px;
  display: flex
  

  `

const ButtonBar = styled.div`
  margin: 8px 0 24px 0;
  `

const Button = styled.button`
  display: inline-flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border: solid 1px #001D5B;
  border-radius: 999px;
  background: #001D5B;
  color: #FFF;
  text-align: center;
  font-family: "Optum Sans", Helvetica, Arial;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  margin: 16px 16px 0px 0;
  white-space: nowrap;
  height: 34px;
  &:last-child {
    color: #001D5B;
    background: #fff;
  }
  `

const PatientList = (props) => {
  const currentScreen = useSelector((state) => state.userDetails.currentScreen);
  const providerDetails = useSelector((state) => state.userDetails.providerDetails);
  const notificationArray = useSelector((state) => state.userDetails.notificationArray);
  const isAudioFooterOpen = useSelector((state) => state.userDetails.isAudioFooterOpen);
  const refresh = useSelector((state) => state.userDetails.refresh);
  const dispatch = useDispatch();

  const hasMounted = useRef(false); // This ref will keep track of whether the component has mounted 

  const [data, setData] = useState([]);
  const [transcript, setTranscript] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [currentName, setCurrentName] = useState("");
  const [currentAddress, setCurrentAddress] = useState("");
  const [currentDate, setCurrentDate] = useState("");
  const [currentSummary, setCurrentSummary] = useState("");
  const [currentAudioFile, setCurrentAudioFile] = useState("");
  const [audioData, setAudioData] = useState("");
  const [autoPlay, setAutoPlay] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [dateFilters, setDateFilters] = useState([]);
  const [appointmentID, setAppointmentID] = useState("");
  const [apcID, setApcID] = useState("");
  const [guid, setGuid] = useState("");
  const [age, setAge] = useState("");
  const [initials, setInitials] = useState("");
  const [scrollTop, setScrollTop] = useState(0);

  const dateAbbr = ['Jan. ', 'Feb. ', 'Mar. ', 'Apr. ', 'May ', 'Jun. ', 'Jul. ', 'Aug. ', 'Sep. ', 'Oct. ', 'Nov. ', 'Dec. '];

  useEffect(() => {
    if (currentScreen?.toUpperCase() === 'SUMMARY') return;

    window.scrollTo(0, scrollTop);
  }, [currentScreen]);

  useEffect(() => {
    const telEls = [...document.querySelectorAll('a[href^="tel:"]')];

    telEls.map(telEl => {
      const number = telEl.innerHTML;

      telEl.innerHTML = ((number?.length === 10) && (Number.isInteger(number) !== NaN))
        ? number.substring(0, 3) + '-' + number.substring(3, 6) + '-' + number.substring(6, 10)
        : number
    })
  })

  useEffect(() => {
    let filters = data.map(datum => {
      return {
        selected: true,
        text: datum.dateFilterText
      }
    });

    // remove duplicates
    filters = filters.filter((item, pos) => {
      return filters.findIndex(f => f.text === item.text) == pos;
    })

    setDateFilters(filters);
    ApplyFilter(filters);
  }, [data]);

  useEffect(() => {
    // If the component has mounted before, only fetch data if `refresh` is true  
    if (hasMounted.current) {
      if (refresh) FetchData();
    } else {
      // If the component hasn't mounted before, fetch data without checking `refresh`  
      FetchData();
      hasMounted.current = true; // Set the ref to true after fetching data on mount  
    }

  }, [refresh, dispatch]);

  const FetchData = () => {
    FetchCancelledAppointments(JSON.parse(window.sessionStorage.getItem("UserOnehealthID")), (value) => {
      fetchAppointmentsList(value);
    });
  };

  const fetchAppointmentsList = (cancelledAppointments) => {
    const apcID_forAPI = (providerDetails.role === "Demo User" || providerDetails.role === "APC")
      ? providerDetails.apcId
      : "";
    GetAppointmentsList(providerDetails.role,apcID_forAPI, cancelledAppointments?.appointmentIds, (value, cancelledAppointments) => {
      getPatientData(value, cancelledAppointments);
    })
  };

  const getDateFilterText = (dbDate) => {
    const today = new Date();
    const todayText = dateAbbr[today.getMonth()] + today.getDate();

    const schedDate = dbDate
      ? new Date(dbDate)
      : today
    const filterText = dateAbbr[schedDate.getMonth()] + schedDate.getDate();

    return (filterText === todayText) ? 'Today' : filterText;
  }

  const ApplyFilter = (filters) => {
    const fData = data.filter(datum =>
      filters.findIndex(filter => filter.selected && (filter.text === datum.dateFilterText)) > -1
    )

    setFilteredData(fData);
  };

  const handleAddressClick = (item) => {
    if (!item || item?.status?.toLowerCase() === 'cancelled') return;

    OpenNativeMaps(item?.address, (value) => { });
  };

  const convertToCST = (val, type) => {
    let scheduledDateTime = new Date(val);
    let currentDate = new Date();
    const ScheduledTime = val;

    const localCurrentTime = currentDate.toLocaleString([], {
      dateStyle: "short",
      timeStyle: "short"
    });

    const appointmentLocalTime = scheduledDateTime.toLocaleString([], {
      dateStyle: "short",
      timeStyle: "short"
    });

    const dateToCompare = appointmentLocalTime.split(", ")[0];
    const dateAndTime = ScheduledTime.split(" ");
    let date = dateAndTime[0];
    const time =
      dateAndTime[1].split(":")[0] +
      ":" +
      dateAndTime[1].split(":")[1] +
      " " +
      dateAndTime[2];

    const formattedDate = dateAbbr[scheduledDateTime.getMonth()] + scheduledDateTime.getDate();

    let appointmentType = "FUTURE";
    const scheduledDate = new Date(scheduledDateTime).setHours(0, 0, 0, 0);
    const today = new Date().setHours(0, 0, 0, 0);

    if (scheduledDate < today)
      appointmentType = 'PAST'

    if (scheduledDate === today)
      appointmentType = (currentDate.getTime() > scheduledDateTime.getTime()) ? "Today_Past" : "TODAY";

    const formattedTime = time.replace("AM", "a.m.").replace("PM", "p.m.");
    let [day, month, year] = date.split("/");

    // Checking if the day and month have a single digit  
    if (day.length === 1)
      day = "0" + day;

    if (month.length === 1)
      month = "0" + month;

    // Reconstructing the date in "dd/mm/yyyy" format  
    date = `${day}/${month}/${year}`;

    if (type === "date") return date;
    else if (type === "type") return appointmentType;
    else if (type === "formatDate") return formattedDate;

    return formattedTime.startsWith('0') ? formattedTime.substring(1) : formattedTime;
  };

  function calculateAge(dob) {
    const today = new Date();
    const birthDate = new Date(dob);

    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();

    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }

    return age;
  }

  const fixAddress = (address) => {
    if (!address) return;

    let zip = '';
    address = address.split(',');
    address = address.map(scsz => {
      if ((scsz?.trim().length === 5) && !isNaN(scsz)) {
        zip = scsz;
        scsz = '';
      }
      return scsz.trim();
    })

    address = address.filter((scsz, i) => scsz);
    return (address + ' ' + zip).replaceAll(',', ', ');
  }

  const getPatientData = async (appointmentData, cancelledAppointments) => {
    try {
      let cancelledArray = [];
      const regex = /\*\*Demographic Information:\*\*(.*?)\*\*/s;

      const tempStore = appointmentData.hcdata.map((item) => {
        item = {
          name: item?.customMetaData?.member?.fname?.toLowerCase() + " " +
            (item?.customMetaData?.member?.mname && item.customMetaData?.member?.mname + " ").toLowerCase() +
            item?.customMetaData?.member?.lname?.toLowerCase() + " ",

          nameForProfilePic: item.customMetaData.member.fname + " " + item.customMetaData.member.lname,
          summary: item.summarizedData.match(regex)[1].trim(),
          apcID: item.APCID,
          guid: item.guid,
          dob: item.customMetaData.member.dob,
          age: calculateAge(item.customMetaData.member.dob),
          appointmentDate: item.customMetaData.member.ScheduledDate,
          dateFilterText: getDateFilterText(item.customMetaData.member.ScheduledDate),
          address: fixAddress(item.customMetaData.member.VisitAddress),
          status: item.status,
          appointmentType: convertToCST(
            item.customMetaData.member.ScheduledDate,
            "type"
          ),
          date: convertToCST(
            item.customMetaData.member.ScheduledDate,
            "date"
          ),
          time: convertToCST(
            item.customMetaData.member.ScheduledDate,
            "time"
          ),
          formattedDate: convertToCST(
            item.customMetaData.member.ScheduledDate,
            "formatDate"
          ),
          appointmentId: item.appointmentID,
          podcastTranscript: item.podcastTranscript,
          completeSummary: item.summarizedData,
          audioFile: item.generatedPodcastFileName,
          scores: item.scores
        };
        return item;
      });
      tempStore.sort((a, b) => {
        const dateA = new Date(a.appointmentDate);
        const dateB = new Date(b.appointmentDate);
        return dateA - dateB;
      });

      const removedNotificationAppointmentIDs = cancelledAppointments;

      tempStore.forEach((item) => {
        if (item.status == "Cancelled") {
          if (removedNotificationAppointmentIDs !== null) {
            if (!removedNotificationAppointmentIDs.includes(item?.appointmentId)) {
              cancelledArray.push({
                name: item.name,

                guid: item.guid,
                appointmentId: item.appointmentId,
                date: (item.appointmentType === "TODAY" || item.appointmentType === "Today_Past") ? "today" : item.formattedDate,
                time: item.time,
              });
            }
          } else {
            cancelledArray.push({
              name: item.name,
              guid: item.guid,
              appointmentId: item.appointmentId,
              date: (item.appointmentType === "TODAY" || item.appointmentType === "Today_Past") ? "today" : item.formattedDate,
              time: item.time,
            });
          }
        }
      });

      dispatch(saveNotificationArray(cancelledArray));
      setData((data) => [...tempStore]);
    } catch (e) {
      console.log(e);
    }
  };

  const handleSummaryNavigate = (data) => {
    setScrollTop(window.scrollY);
    setCurrentName(data.name);
    setAppointmentID(data.appointmentId);
    setApcID(data.apcID);
    setGuid(data.guid);
    setAge(data.age);
    setInitials((data?.nameForProfilePic?.charAt(0) || '') + (data?.nameForProfilePic.split(" ")[1]?.charAt(0) || ''));
    setCurrentAddress(data.address);
    setCurrentDate(data.date);
    setCurrentSummary(data.completeSummary === undefined ? "" : data.completeSummary);
    setCurrentAudioFile(data.audioFile);
    setTranscript(data.podcastTranscript);
    fetchAudioFile(data?.audioFile);
    setAutoPlay(false);
    dispatch(saveShouldRefresh(false));
    dispatch(saveCurrentScreen("summary"));
    dispatch(saveIsAudioFooterOpen(true));
  };

  const handleListenSummaryClick = (data) => {
    setCurrentName(data?.name);
    setCurrentAddress(data?.address);
    setCurrentDate(data?.date);
    setCurrentSummary(data?.summary);
    setCurrentAudioFile(data?.audioFile);
    dispatch(saveIsAudioFooterOpen(true));
    setAge(data?.age);
    setInitials((data?.nameForProfilePic?.charAt(0) || '') + (data?.nameForProfilePic.split(" ")[1]?.charAt(0) || ''));
    fetchAudioFile(data?.audioFile);
    setTranscript(data.podcastTranscript);
    setAutoPlay(true);
  };

  const fetchAudioFile = (generatedPodcastFileName) => {
    FetchAudioURL(generatedPodcastFileName,
      value => {
        if (generatedPodcastFileName && value) {
          setAudioData(value);
          props.setAudioClient(value);
        }
      })
  };

  return <>
    <PatientListPage
      id="patientListPageContainer"
      className="container"
      display={['summary'].includes(currentScreen) ? 'none' : 'block'}
    >
      <CancelOverlay>
        {notificationArray.map((item) => {
          return <CancelledNotification key={"CNOTE" + item?.appointmentId} data={item} />;
        })}
      </CancelOverlay>

      <div
        id="patientListPageDiv"
        style={{ marginTop: "40px", padding: "0 16px" }}
      >
        <MenuDrawer />

        <div id="patientListDiv"  >
          <HeaderRow1 id="patientListDivLabel">
            <AppointmentLabel id="patientListLabel">
              Appointments
            </AppointmentLabel>
          </HeaderRow1>

          <HeaderRow2 id="appointmentsCountAndFilterDiv">
            <span id="appointmentsCountLabelDiv">
              <ApptCountLabel id="appointmentsCountLabel">
                {data.length} appointments
              </ApptCountLabel>
            </span>

            <span id="filterButton" onClick={(e) => { setIsFilterOpen((val) => !val); }}  >
              <Filter_list />
              filter
            </span>
          </HeaderRow2>

          <FilterComponent
            ApplyFilter={ApplyFilter}
            isFilterOpen={isFilterOpen}
            setIsFilterOpen={setIsFilterOpen}
            filters={dateFilters}
            setFilters={setDateFilters}
          />

          <AppointmentList>
            {
              filteredData.map((item, index) => {
                return <div key={"APPT" + index}>
                  <DividerLine />
                  <AppointmentPanel
                    id={`appointmentListContainerDiv${index}`}
                    data_id={item.guid}
                  >
                    <AppointmentTag
                      id="appointmentTimeDiv"
                      className={item?.status.toLowerCase() + ' ' + item?.appointmentType.toLowerCase()}
                    >
                      {(item.status == "Cancelled")
                        ? <ErrorOutlineIcon />
                        : (item?.appointmentType?.toLowerCase().includes("today")) && <AccessTimeIcon />
                      }
                      {
                        item.appointmentType === "TODAY" || item.appointmentType === "Today_Past"
                          ?
                          <div id="TodayAppointmentTimeValue">
                            {item.status === "Cancelled" && "Appointment canceled: "}
                            Today at {item.time}
                          </div>
                          :
                          <div id="AppointmentDateValue">
                            {item.status === "Cancelled" && "Appointment canceled: "}
                            {item.formattedDate} at {item.time}
                          </div>
                      }
                    </AppointmentTag>

                    <PatientName
                      id="patientNameDivLabel"
                      className={item?.status.toLowerCase()}
                    >
                      {item.name}
                    </PatientName>

                    <PatientAddress
                      id="patientAddressDiv"
                      className={item?.status.toLowerCase()}
                      onClick={() => { handleAddressClick(item); }}
                    >
                      <div>
                        {item.address}
                      </div>
                      <LaunchIcon />
                    </PatientAddress>

                    {item.status !== "Cancelled" && (
                      <>
                        <PatientText id="patientDetailsSummaryDiv">
                          <ReactMarkdown
                            rehypePlugins={[rehypeRaw]}
                            children={item.summary}
                            className="my-custom-class"  
                          />
                        </PatientText>

                       {
                            
                           (item.scores === undefined || Object.keys(item.scores).length === 0 || item.scores.overall_score*100 < process.env.REACT_APP_MLRBQualityThreshold) ?
                           <FlagContainer > <FlagIcon style={{paddingTop:'5px',minHeight:'16px',maxHeight:'16px',minWidth:'16px',maxWidth:'16px', color:'#4B4D4F'}} />
                              <FlagMessage >
                              The complete summary and audio are currently unavailable, please check back later. We apologize for the inconvenience. 
                              </FlagMessage>
                           </FlagContainer>
                           :
                            <ButtonBar id="patientDetailsButtonsDiv">
                            <Button
                              id="patientDetailsSummaryButton"
                              onClick={() => { handleSummaryNavigate(item); }}
                            >
                              View summary
                              <ArrowIcon id="patientDetailsSummaryButtonIcon" />
                            </Button>
                            <Button
                              id="patientDetailsPlayButton"
                              onClick={() => { handleListenSummaryClick(item); }}
                            >
                              Listen to summary
                              <PlayIcon  id="patientDetailsPlayButtonIcon" />
                            </Button>
                          </ButtonBar>
                }
                      </>
                    )}

                  </AppointmentPanel>
                </div>
              })}
          </AppointmentList>
        </div>
      </div>
      <Footer />
    </PatientListPage>

    <SummaryPage display={['summary'].includes(currentScreen) ? 'block' : 'none'}>
      < Summary
        name={currentName}
        initials={initials}
        currentSummary={currentSummary}
        generatedPodcastFileName={currentAudioFile}
        setAudioClient={props.setAudioClient}
        setTranscript={setTranscript}
        transcript={transcript}
        guid={guid}
        apcID={apcID}
        age={age}
        appointmentID={appointmentID}
        openFooter={true}
      />
    </SummaryPage>

    {
      isAudioFooterOpen &&
      <AudioPlayerFooter
        screen={currentScreen}
        openSnackbar={isAudioFooterOpen}
        age={age}
        name={currentName}
        intials={initials}
        src={audioData}
        transcript={transcript}
        autoplay={autoPlay}
      />
    }
  </>
};

export default PatientList;
