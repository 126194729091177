import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "../../interceptors/interceptors";
import { useEffect, useState, useRef } from "react";
import profilePic from "../../assets/bgimage4.jpg";
import { ReactComponent as ThumbUpIcon } from "../../assets/thumb_up.svg";
import { ReactComponent as ThumbDownIcon } from "../../assets/thumb_down.svg";
import { ReactComponent as ThumbUpFilledIcon } from "../../assets/thumb_up_filled.svg";
import { ReactComponent as ThumbDownFilledIcon } from "../../assets/thumb_down_filled.svg";
import styled from "styled-components";
import "./style.css";

const Root = styled.div`
  font-family: 'optum sans';
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  padding: 16px;
  background: #E5E5E6;
  border-radius: 10px;
  overflow: hidden;

  transition: height .5s linear;
  `

const ThumbQuestionPanel = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  color: #4B4D4F;
  `

const ThumbPanel = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 16px;


  & svg.unselected {
    path {
      fill: #6D6F70;
    }
  }
  `

const FeedbackPanel = styled.div`
  display: flex;
  padding: 24px 0px 8px 0px;
  flex-direction: column;
  align-items: flex-end;
  gap: 8px;
  align-self: stretch;
  color: #4B4D4F;

  & textarea {
    display: flex;
    padding: 8px 12px 16px 12px;
    align-items: flex-start;
    align-self: stretch;
    height: 100px;
    
    color: #4B4D4F;
    font-family: "Optum Sans", Helvetica, Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; 

    border-color: #6D6F70;
    resize: none;

    &:focus {
      outline-color: #6D6F70;
    }

    &::placeholder {
      font-size: 16px;
      line-height: 20px;
      font-weight: 400; 
      color: #6D6F70;
    }
  }
  `

const Question = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
  color: #4B4D4F;

  & sup {
    color: #C40000;
    top: -2px;
    position: relative;
  }
  `

const Answer = styled.div`
  color: #4B4D4F;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: "Optum Sans", Helvetica, Arial;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  `

const Button = styled.button`
  display: flex;
  min-width: 30px;
  max-width: 328px;
  min-height: 30px;
  padding: 5px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin-top: 8px;
  color: #FFF;
  text-align: center;

  font-family: "Optum Sans", Helvetica, Arial;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  border-radius: 50px;
  background: var(--primary-110, #001D5B);
  border: none;

  &:disabled {
    color:  #6D6F70;
    background: #CBCCCD;
  }
  `

const SummaryFeedback = ({ guid, apcID, appointmentID }) => {
  // console.log("INSIDEFEEDBACK",guid,appointmentID,age,apcID);
  const textareaRef = useRef(null);
  const [likeButtonClick, setLikeButtonClick] = useState(false);
  const [dislikeButtonClick, setDislikeButtonClick] = useState(false);
  const [feedBackValue, setFeedBackValue] = useState("");
  const [isFeedbackSubmitted, setIsFeedbackSubmitted] = useState(false);
  const [placeholderTextLike, setPlaceholderTextLike] = useState("Give as much detail as possible. Do not include any sensitive or patient specific information. This question is optional.");
  const [placeholderTextDislike, setPlaceholderTextDislike] = useState("Give as much detail as possible. Do not include any sensitive or patient specific information.");
  const [feedbackClicked, setFeedbackClicked] = useState(false);

  const setHeightForAnimation = (value) => {
    const feedbackPanel = document.getElementById('PatientSummaryFeedbackDiv');
    const height = (likeButtonClick || dislikeButtonClick)
      ? value || (document.getElementById('BothPanels')?.offsetHeight + 'px')
      : value || (document.getElementById('TopPanelOnly')?.offsetHeight + 'px')

    if (!height) return;

    feedbackPanel.style.height = height;
  }

  useEffect(() => {
    setHeightForAnimation('auto');

    if (isFeedbackSubmitted) {
      const timer = setTimeout(() => {
        setIsFeedbackSubmitted(false);
        setFeedbackClicked(false);
        setLikeButtonClick(false);
        setDislikeButtonClick(false);
        setFeedBackValue("");
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [isFeedbackSubmitted]);

  useEffect(() => {
    setHeightForAnimation();

    if (!likeButtonClick && !dislikeButtonClick) return;

    setTimeout(() => {
      window.scrollTo({
        top: document.body.scrollHeight,
        left: 0,
        behavior: 'smooth'
      });
    }, 500)

  }, [likeButtonClick, dislikeButtonClick])

  useEffect(() => {
    setHeightForAnimation();
  })

  const submitFeedback = async () => {
    setFeedbackClicked(true);
    try {
      const response = await axios.post(
        process.env.REACT_APP_appointmentFeedback,
        {
          feedbacktype: "appointment",
          appointmentId: appointmentID,
          guid: guid,
          feedback: feedBackValue,
          action: likeButtonClick ? "like" : "dislike",
          apcid: apcID,
        }
      );
      setIsFeedbackSubmitted(true);

      setHeightForAnimation();

    } catch (e) {
      console.log(e);
    }
  };

  const handleThumbClick = (like, dislike) => {
    setLikeButtonClick(like);
    setDislikeButtonClick(dislike);
  }

  return (
    <Root id="PatientSummaryFeedbackDiv">
      <div id='BothPanels'>
        <ThumbQuestionPanel id='TopPanelOnly'>
          <div>
            Was this summary accurate?
          </div>

          <ThumbPanel>
            {
              likeButtonClick
                ? <ThumbUpFilledIcon
                  id="PatientSummaryFeedbackThumbsUpClicked"
                  onClick={() => { handleThumbClick(false, false) }}
                />
                : <ThumbUpIcon
                  id="PatientSummaryFeedbackThumbsUpNotClicked"
                  className={dislikeButtonClick ? "unselected" : ''}
                  onClick={() => { handleThumbClick(true, false) }}
                />
            }
            {
              dislikeButtonClick
                ? <ThumbDownFilledIcon
                  id="PatientSummaryFeedbackThumbsDownClicked"
                  onClick={() => { handleThumbClick(false, false) }}
                />
                : <ThumbDownIcon
                  id="PatientSummaryFeedbackThumbsDownNotClicked"
                  className={likeButtonClick ? "unselected" : ''}
                  onClick={() => { handleThumbClick(false, true) }}
                />
            }
          </ThumbPanel>
        </ThumbQuestionPanel>

        <FeedbackPanel>
          {
            (!isFeedbackSubmitted && likeButtonClick) &&
            <Question>Is there anything else you’d like to see in this summary?</Question>
          }
          {
            (!isFeedbackSubmitted && dislikeButtonClick)
            && <Question>Please explain why it was not accurate<sup>*</sup></Question>
          }
          {
            isFeedbackSubmitted
              ?
              <Answer>Feedback submitted. Thank you for sharing your thoughts! </Answer>
              :
              <>
                <textarea
                  placeholder={dislikeButtonClick ? placeholderTextDislike : placeholderTextLike}
                  id="PatientSummaryFeedbackTextArea"
                  value={feedBackValue}
                  onChange={(e) => {
                    setFeedbackClicked(false);
                    setFeedBackValue(e.target.value);
                  }}
                />
                <Button
                  id="PatientSummaryFeedbackSubmitButton"
                  disabled={dislikeButtonClick && feedBackValue === "" ? true : false}
                  onClick={(e) => { submitFeedback(); }}
                >
                  Submit
                </Button>
              </>
          }
        </FeedbackPanel>

      </div>
    </Root>
  )
}

export default SummaryFeedback;