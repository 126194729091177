import React from "react";
import {  useNavigate } from "react-router-dom";
import axios from "../../interceptors/interceptors";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  saveCurrentScreen,
  saveIsSummaryOpen,
  saveAddNewUserModelOpen,
} from "../../features/uiSlice";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import "../PatientList/style.css";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import useWindowSize from "../../CustomHooks/useWindow";
import { Button } from "@mui/material";

import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import PauseIcon from "@mui/icons-material/Pause";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Modal from "@mui/material/Modal";
import { BlobServiceClient } from "@azure/storage-blob";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import { EditOutlined } from "@ant-design/icons";
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";
import { SetMeal } from "@mui/icons-material";
import LinearProgress from "@mui/material/LinearProgress";
import CircularProgress from "@mui/material/CircularProgress";
import ErrorIcon from '@mui/icons-material/Error';


const ManageUserAccess = (props) => {

  const addNewUserModelOpen = useSelector(
    (state) => state.userDetails.addNewUserModelOpen
  );
  const providerDetails = useSelector(
    (state) => state.userDetails.providerDetails
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [filteredData, setFilteredData] = useState([]);
  const searchInput = useSelector((state) => state.userDetails.searchInput);
  const [data, setData] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [oneHealthId, setoneHealthId] = useState("");
  const [email, setEmail] = useState("");
  const [apcID, setApcID] = useState("");
  const [cosmosId, setCosmosId] = useState("");
  const [userRole, setUserRole] = useState("");
  const [loadDate, setLoadDate] = useState("");
  const [loadingAddUser, setLoadingAddUser] = useState(false);
  const [saveClicked, setSaveClicked] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [errorAlert, setErrorAlert] = useState(false);

  useEffect(() => {
  
    if(providerDetails.role === "Client Manager"){
      navigate('/Summary');
    }
    dispatch(saveCurrentScreen("Manage_user_Access"));
    getProfileInfo();
  }, []);

  useEffect(() => {
    filterData(searchInput);
  }, [searchInput]);

  const filterData = (searchInput) => {
    if (!searchInput) {
      setFilteredData(data);
    } else {
      const lowerSearchInput = searchInput.toLowerCase();
      const filtered = data.filter(
        (item) =>
          item.fullName.toLowerCase().includes(lowerSearchInput) ||
          item.useremail.toLowerCase().includes(lowerSearchInput) ||
          item.apcId.toLowerCase().includes(lowerSearchInput) ||
          item.oneHealthId.toLowerCase().includes(lowerSearchInput) ||
          item.role.toLowerCase().includes(lowerSearchInput)
      );
      setFilteredData(filtered);
    }
  };

  const handleChangePage = (event, newPage) => {
    console.log("event222", newPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    console.log("event22", event.target.value);

    setRowsPerPage(+event.target.value);
    setPage(0);
  };



  const validateInput = () => {
    if (
      firstName !== "" &&
      lastName !== "" &&
      email !== "" &&
      userRole !== "" &&
      oneHealthId !== "" &&
      apcID !== ""
    ) {
      return true;
    }
    return false;
  };

  const handleCloseModal = () => {
    setUserRole("");
    setFirstName("");
    setLastName("");
    setEmail("");
    setoneHealthId("");
    setApcID("");
    setErrorAlert(false);
    setSaveClicked(false);
    setIsEdit(false);
    dispatch(saveAddNewUserModelOpen(false));
  };


  const handleSave = async () => {
    try {
      setSaveClicked(true);
      var validate = validateInput();
      if (validate) {
        setLoadingAddUser(true);

        const date = new Date();
        const cstTime = date
          .toLocaleString("en-US", {
            timeZone: "America/Chicago",
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          })
          .replace(/\//g, "-");
        console.log("2wedate", cstTime);

        const response = await axios.post(
          process.env.REACT_APP_saveProfileInfo,
          {
            firstName: firstName,
            lastName: lastName,
            ...(!isEdit ? {} : { id: cosmosId }), 
            oneHealthId: oneHealthId,
            apcId: apcID,
            useremail: email,
            role: userRole,
            datasecurity: "example",
            organization: "optum",
            loadDate: isEdit ? loadDate : cstTime,
          }
        );
        setLoadingAddUser(false);
          setIsEdit(false);
          handleCloseModal();
          getProfileInfo();
        
      }
    } catch (e) {
      setLoadingAddUser(false);
      if(e.response.data.code === 400 && e.response.data.message === "OneHealthId already exists"){
        console.log("inside error");
        setErrorAlert(true);
      }
      console.log(e);
    }
  };

  const handleEditThisUser = (record) => {
    setEmail(record.useremail);
    setApcID(record.apcId);
    setUserRole(record.role);
    setFirstName(record.firstName);
    setLastName(record.lastName);
    setLoadDate(record.loadDate);
    setCosmosId(record.id);
    setoneHealthId(record.oneHealthId);
  };

  const getProfileInfo = async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_getProfileInfo);

      const tempStore = response.data.map((item) => {
        item = {
          firstName: item.firstName,
          lastName: item.lastName,
          fullName: item.firstName + " " + item.lastName,
          oneHealthId: item.oneHealthId,
          apcId: item.apcId,
          useremail: item.useremail,
          role: item.role,
          id: item.id,
          organization: item.organization,
          loadDate: item.loadDate,
          thisUserDetail: item,
        };
        return item;
      });
      // console.log("dummyData", tempStore);
      setData(tempStore);
      setFilteredData(tempStore);
    } catch (e) {
      console.log(e);
    }
  };

  const columns1 = [
    {
      label: "User Name",
      id: "fullName",
      key: "fullName",
      // format: (fullName) => <p>Test User</p>,
    },
    {
      label: "APC ID",
      id: "apcId",
      key: "apcId",
      minWidth: "80px",
      // format: (patientDetails) => <p>APC023</p>,
    },
    {
      label: "Email",
      id: "useremail",
      key: "useremail",
      minWidth: "80px",
      // format: (patientDetails) => <p>testuser@optum.com</p>,
    },

    {
      label: "User Role",
      id: "role",
      key: "role",
      format: (role) => (
        <>
          <span
            style={{
              fontSize: "16px",
              fontWeight: "700",

            }}
          >
            {role}
          </span>
        </>
      ),
    },

    {
      label: "OHID",
      id: "oneHealthId",
      key: "oneHealthId",

    },

    {
      label: "Edit User Detail ",
      id: "thisUserDetail",
      key: "thisUserDetail",
      format: (record) => {
        return (
          <EditOutlined

            onClick={() => {

              setIsEdit(true);
              handleEditThisUser(record);
              dispatch(saveAddNewUserModelOpen(true));

            }}
          />
        );
      },
    },
  ];

  const handleClose = (event, reason) => {
    // console.log("reason", event, reason);

    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    setIsPaused(false);
  };
  const action = (
    <React.Fragment>
      <Button color="warning" size="small" onClick={handleClose}>
        Close
      </Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={() => {
          setIsPaused((currentValue) => !currentValue);
        }}
      >
        {isPaused ? <PauseIcon /> : <PlayArrowIcon fontSize="small" />}
      </IconButton>
    </React.Fragment>
  );


  return (
    <div style={{ marginTop: "120px", marginLeft: "60px" }}>
      <div style={{ zIndex: -1, position: "absolute" }}>
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer sx={{ maxHeight: "60%" }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns1.map((column) => (
                    <TableCell
                      key={column.id}
                      style={{
                        width: 360,
                        borderRight: "1px solid #CBCCCD",
                        fontSize: "16px",
                        fontWeight: "700",
                        color: "#002677",
                        minWidth: column.minWidth,
                      }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredData
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.code}
                      >
                        {columns1.map((column) => {
                          const value = row[column.id];
                          // console.log("value", value, column.id);

                          return (
                            <TableCell
                              style={{
                                width: 360,
                                borderRight: "1px solid #CBCCCD",
                                backgroundColor:
                                  column.id === "role" &&
                                  (value === "APC"
                                    ? "rgb(169 219 226 / 56%)"
                                    : "#EFF6EF"),
                              }}
                              key={column.id}
                            >
                              {column.format ? column.format(value) : value}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[
              5,
              10,
              25,
              { label: "All", value: data.length },
            ]}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>

        <Modal
          open={addNewUserModelOpen}
          onClose={handleCloseModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          style={{ width: "800px", display: "flex", margin: "auto", }}
        >
          <div
            style={{
              backgroundColor: "white",
              width: "900px",
              height: "600px",
              margin: "auto",
              padding: "20px",
             
            }}
          >
            <Stack sx={{ width: "100%" }} spacing={2}>
              <Alert severity="info">
                Adding a New User to the Application!
              </Alert>
            </Stack>
            <Box
              component="form"
              sx={{
                "& > :not(style)": { m: 1, width: "25ch" },
              }}
              noValidate
              autoComplete="off"
            >
              <div style={{ display: "flex" }}>
                <div
                  style={{
                    padding: "5px",
                    marginRight: "50px",
                    marginLeft: "30px",
                  }}
                >
                  <label
                    style={{
                      fontWeight: 700,
                      fontSize: 16,
                    }}
                  >
                    First Name:<span style={{ color: "red" }}>*</span>
                  </label>
                  <TextField
                    style={{
                      width: "300px",
                    }}
                    value={firstName}
                    onChange={(e) => {
                      setFirstName(e.target.value);
                    }}
                    id="outlined-basic"
                    // placeholder="First Name"
                    variant="outlined"
                  />
                  {saveClicked && firstName === "" && (
                    <label style={{ color: "red", fontWeight: 500 }}>
                      This field cannot be empty*
                    </label>
                  )}
                </div>
                <div style={{ padding: "5px" }}>
                  <label
                    style={{
                      fontWeight: 700,
                      fontSize: 16,
                    }}
                  >
                    Last Name:<span style={{ color: "red" }}>*</span>
                  </label>
                  <TextField
                    style={{
                      width: "300px",
                    }}
                    value={lastName}
                    onChange={(e) => {
                      setLastName(e.target.value);
                    }}
                    id="outlined-basic"
                    // placeholder="Last Name"
                    variant="outlined"
                  />
                  {saveClicked && lastName === "" && (
                    <label style={{ color: "red", fontWeight: 500 }}>
                      This field cannot be empty*
                    </label>
                  )}
                </div>
              </div>
              <div style={{ display: "flex" }}>
                <div
                  style={{
                    padding: "5px",
                    marginRight: "50px",
                    marginLeft: "30px",
                  }}
                >
                  <label
                    style={{
                      fontWeight: 700,
                      fontSize: 16,
                    }}
                  >
                    One Health ID:<span style={{ color: "red" }}>*</span>
                  </label>
                  <TextField
                    style={{
                      width: "300px",
                    }}
                    value={oneHealthId}
                    onChange={(e) => {
                      setoneHealthId(e.target.value);
                    }}
                    id="outlined-basic"
                    // placeholder="One Health ID"
                    variant="outlined"
                  />
                  {saveClicked && oneHealthId === "" && (
                    <label style={{ color: "red", fontWeight: 500 }}>
                      This field cannot be empty*
                    </label>
                  )}
                </div>
                <div style={{ padding: "5px" }}>
                  <label
                    style={{
                      fontWeight: 700,
                      fontSize: 16,
                    }}
                  >
                    Email:<span style={{ color: "red" }}>*</span>
                  </label>
                  <TextField
                    style={{
                      width: "300px",
                    }}
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    id="outlined-basic"
                    // placeholder="Email"
                    variant="outlined"
                  />
                  {saveClicked && email === "" && (
                    <label style={{ color: "red", fontWeight: 500 }}>
                      This field cannot be empty*
                    </label>
                  )}
                </div>
              </div>
              <div style={{ display: "flex", marginTop: "20px" }}>
                <div
                  style={{
                    padding: "5px",
                    marginRight: "50px",
                    marginLeft: "30px",
                  }}
                >
                  <label
                    style={{
                      fontWeight: 700,
                      fontSize: 16,
                      marginBottom: "20px",
                    }}
                  >
                    APC ID:<span style={{ color: "red" }}>*</span>
                  </label>
                  <TextField
                    style={{
                      width: "300px",
                    }}
                    value={apcID}
                    onChange={(e) => {
                      setApcID(e.target.value);
                    }}
                    id="outlined-basic"
                    // placeholder="APC ID"
                    variant="outlined"
                  />
                  {saveClicked && apcID === "" && (
                    <label style={{ color: "red", fontWeight: 500 }}>
                      This field cannot be empty*
                    </label>
                  )}
                </div>
                <div style={{ padding: "5px" }}>
                  <label
                    style={{
                      fontWeight: 700,
                      fontSize: 16,
                      marginBottom: "20px",
                    }}
                  >
                    User Role:<span style={{ color: "red" }}>*</span>
                  </label>
                  <FormControl fullWidth>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={userRole}
                      //  label="Age"
                      style={{
                        width: "300px",
                      }}
                      onChange={(e) => {
                        setUserRole(e.target.value);
                      }}
                    >
                      <MenuItem value="Devops Admin">Devops Admin</MenuItem>
                      <MenuItem value="APC">APC</MenuItem>
                      <MenuItem value="Demo User">Demo User</MenuItem>
                      <MenuItem value="Client Manager">Client Manager</MenuItem>
                    </Select>
                    {saveClicked && userRole === "" && (
                      <label style={{ color: "red", fontWeight: 500 }}>
                        This field cannot be empty*
                      </label>
                    )}
                  </FormControl>
                </div>
              </div>
            </Box>
            <div
              style={{
                marginTop: "50px",
                width: "100%",
              }}
            >
              <Box sx={{ width: "100%" }}>
                {loadingAddUser ? (
                  <div>
                    <LinearProgress />{" "}
                    <Stack sx={{ width: "100%" }} spacing={2}>
                      <Alert
                        icon={
                          <CircularProgress
                            color="secondary"
                            style={{ width: "25px", height: "25px" }}
                          />
                        }
                        severity="success"
                      >
                        Updating Data!
                      </Alert>
                    </Stack>
                  </div>
                ): errorAlert && (
                  <div>
                   
                    <Stack sx={{ width: "100%" }} spacing={2}>
                      <Alert
                        icon={
                          <ErrorIcon
                            color="red"
                            style={{ width: "25px", height: "25px" }}
                          />
                        }
                        severity="error"
                      >
                        OneHealthId already exists!
                      </Alert>
                    </Stack>
                  </div>
                )}
              </Box>
            </div>
            <div
              style={{
                marginTop: (loadingAddUser || errorAlert)  ? "100px" : "150px",
                width: "100%",
                textAlign: "end",
              }}
            >
              <Button
                style={{ backgroundColor: "#f5f5f5eb", marginRight: "30px" }}
                startIcon={<CloseIcon style={{ color: "red" }} />}
                variant="outlined"
                onClick={handleCloseModal}
              >
                Close
              </Button>
              <Button
                style={{ backgroundColor: "#f5f5f5eb" }}
                startIcon={<SaveIcon />}
                variant="outlined"
                onClick={handleSave}
              >
                Save
              </Button>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default ManageUserAccess;
