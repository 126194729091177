import { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";
import TextsmsIcon from "@mui/icons-material/Textsms";
import { ReactComponent as MenuSvg } from "./icons/menu.svg";
import { useSelector, useDispatch } from "react-redux";
import { saveIsMenuOpen, saveCurrentScreen, saveIsAudioFooterOpen } from "../../src/features/uiSlice";
import DescriptionIcon from "@mui/icons-material/Description";
import { ReactComponent as SettingsSvg } from "./icons/settings.svg";
import { ReactComponent as ArrowBackIcon } from "../assets/back_arrow_icon.svg";
import { ReactComponent as WordBackIcon } from "../assets/back_word_icon.svg";
import { ReactComponent as WordMenuIcon } from "../assets/menu_word_icon.svg";
import FeaturedPlayListIcon from "@mui/icons-material/FeaturedPlayList";
import { appColors, appStyles } from "./Theme/AppTheme";
import Popover from "@mui/material/Popover";
import styled from "styled-components";

const Icons = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;  
  width: 48px;
  height: 48px;
  padding: 8px 10px;
  box-sizing: border-box;
  `

const styles = {
  root: {
    ...appStyles.fontBold,
    fontSize: "18px",
    color: appColors.accent1,
    textAlign: "left",

    "& .MuiListItemIcon-root svg .st0": {
      fill: appColors.accent1,
    },
  },
  linkItem: {
    position: "relative",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "stretch",
    alignSelf: "center",
    flex: "1 0 auto",
    margin: 0,
    padding: 0,
    width: "100%",
    height: "100%",

    "& .ns-navlink": {
      ...appStyles.navLink,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "flex-start",
      width: "100%",
      height: "100%",
      minHeight: `55px`,
      backgroundColor: "transparent",
      borderRadius: "8px",
      textDecoration: "none",

      "&.active": {
        backgroundColor: appColors.accent1A01,
        textDecoration: "none",
      },
      "&.active:hover,&:hover": {
        backgroundColor: appColors.blackA01,
        textDecoration: "none",
      },
    },
  },
  icon: {
    margin: "0 15px",

    "& svg .st0": {
      color: appColors.accent1,
      fill: appColors.accent1,
    },
  },
  label: {
    margin: "0 5px",
    color: appColors.accent1,
  },
};

const NavSidebar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentScreen = useSelector((state) => state.userDetails.currentScreen);
  const isMenuOpen = useSelector((state) => state.userDetails.isMenuOpen);
  const [openSubmenu, setOpenSubmenu] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const menuItems = [
    {
      label: "Appointment Tab",
      link: "/Summary",
      icon: <FeaturedPlayListIcon style={{ color: "#0C55B8" }} />,
    },
    {
      label: "Feedback (User Admin)",
      link: "/Summary1",
      icon: <TextsmsIcon style={{ color: "#0C55B8" }} />,
    },
    {
      label: "Client Reporting",
      link: "/Summary1",
      icon: <DescriptionIcon style={{ color: "#0C55B8" }} />,
    },
    {
      label: "Settings",
      link: "/Summary1",
      icon: <SettingsSvg />,
    },
  ];

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuClick = (event) => {
    dispatch(saveIsMenuOpen(!isMenuOpen));
  };

  const handleBackClick = (link) => {
    if (currentScreen === 'summaryPlayer') {
      navigate('/summary');
      return;
    }

    dispatch(saveCurrentScreen("patientList"));
    dispatch(saveIsAudioFooterOpen(false));
  };

  return (
    <div>
      {
        ['loginPage', 'summary', 'summaryPlayer'].includes(currentScreen)
          ?
          <Icons onClick={handleBackClick}>
            <ArrowBackIcon />
            <WordBackIcon />
          </Icons>
          :
          <Icons onClick={handleMenuClick}>
            <MenuSvg />
            <WordMenuIcon />
          </Icons>
      }

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          sx={styles.root}
        >
          {menuItems.map((item, idx) => (
            <Grid
              item
              container
              key={idx}
              sx={styles.linkItem}
              style={{ display: "contents" }}
            >
              {item.submenuItems ? (
                <>
                  {/* Render main menu item */}

                  {/* Render submenu items if the submenu is open */}
                  {openSubmenu &&
                    item.submenuItems.map((subItem, subIdx) => <></>)}
                </>
              ) : (
                // Render regular menu items without submenu
                <NavLink
                  to={item.link}
                  className="ns-navlink"
                  style={{ paddingLeft: "20px", paddingRight: "20px" }}
                  onClick={() => handleMenuClick(item.link)}
                >
                  <Grid container direction="row" sx={appStyles.fillxy}>
                    <Grid item sx={styles.icon}>
                      {item.icon}
                    </Grid>
                    <Grid item sx={styles.label}>
                      {item.label}
                    </Grid>
                  </Grid>
                </NavLink>
              )}
            </Grid>
          ))}
        </Grid>
      </Popover>
    </div>
  );
};

export default NavSidebar;
