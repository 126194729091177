import NavigationMenu from "../Main/NavigationMenu";
import { useSelector, useDispatch } from "react-redux";

const SideHeader = () => {
  const currentScreen = useSelector((state) => state.userDetails.currentScreen);
  return (
    <div
      style={{
        height: "100%",
        width: "70px",
        backgroundColor: "#FAF8F2",
        marginTop: "100px",
      }}
    >
      <div>
        <NavigationMenu />
      </div>
    </div>
  );
};

export default SideHeader;
