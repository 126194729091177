import React, { useEffect, useState } from "react";
import axios from "../interceptors/interceptors";
import { cancellationData } from '../api/MockData';

export const SaveCancelledAppointments = async (OHID, appointmentID, callback) => {
  callback = (callback instanceof Function) ? callback : () => { };

  if (process.env.REACT_APP_Environment === 'offline')
    return callback(cancellationData);

  console.log("inside save cancelled appointments");
  axios.post(process.env.REACT_APP_saveCancellationNotification,
    {
      oneHealthId: OHID,
      appointmentId: appointmentID

    },

    {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then(response => callback(response.data))
    .catch(() => callback(null))

};


