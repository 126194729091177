import { useEffect, useState, useRef } from "react";
import profilePic from "../../assets/bgimage4.jpg";
import AudioPlayer from "./AudioPlayer";
import CloseToastIcon from "../../assets/close_toast.svg";
import OpenToastIcon from "../../assets/open_toast.svg";
// import TranscriptionToast from "./AudioTranscriptToast";
import styled from "styled-components";

const Root = styled.div`
  position: fixed;
  top: 99%;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  z-index: 999999;
  background: #001D5B;
  transition: top .5s;
  scrollbar-width: none;

  overflow: hidden;
  overflow-y: scroll;
  `

const ScrollablePanel = styled.div`   
    padding: 62px 16px 55px 16px;
    `

const Header = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    position: relative;
    top: 0;
    left: 0;
    height: 28px;

    color: #FFF;
    font-family: "Optum Sans", Helvetica, Arial;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px; /* 128.571% */
    `

const CloseButton = styled.button`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: none;
    background: transparent;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 28px;
    height: 28px;

    background: url(${CloseToastIcon});
    background-position: center center;
    `

const AvatarPanel = styled.div`
    padding-top: 48px;
    padding-bottom: 76px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    `

const Avatar = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    width: 240px;
    height: 240px;
    border-radius: 50%;
    overflow: hidden;

    color: #001D5B;
    font-family: "Optum Sans", Helvetica, Arial;
    font-size: 80px;
    font-style: normal;
    font-weight: 700;

    background-image: url(${profilePic});
    background-size: 100% 100%;

    display: none;
    `

const NamePanel = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;

    color: #FFF;
    font-family: "Optum Sans", Helvetica, Arial;
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px; 
    text-transform: capitalize;

    margin-bottom: 48px;
    
    & div:last-child {
        color: #E5E5E6;
        font-size: 18px;
        font-weight: 400;
        line-height: 24px;
        margin-top: 8px;
    }
    `

const TranscriptPanel = styled.div`
    display: flex;
    padding: 16px;

    flex-direction: column;
    align-items: flex-start;
    gap: 24px;

    align-self: stretch;
    border-radius: 12px;
    background: #D9F6FA;

    color: #001D5B;
    font-family: "Optum Sans", Helvetica, Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px; 

    margin: 40px 0;
    `

const TransHead = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;

    color:  #001D5B;
    font-family: "Optum Sans", Helvetica, Arial;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    `

const TranscriptButton = styled.button`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: none;
    background: transparent;
    width: 18px;
    height: 18px;

    background: url(${OpenToastIcon});
    background-position: center center;
    `

const TransBody = styled.div`
    
    `

const AudioPlayerToast = ({ open, onClose, patient, transcript, audioPlayerRef }) => {
    const [isTranscriptOpen, setIsTranscriptOpen] = useState(false);

    const rootElement = useRef();
    const avatarPanelRef = useRef();

    if (!(onClose instanceof Function))
        onClose = () => { };

    const handleOpenTranscriptClick = () => {
        console.log("OT")
        setIsTranscriptOpen(true);
    }

    const handleTranscriptClose = () => {
        setIsTranscriptOpen(false);
    }

    useEffect(() => {
        rootElement.current.style.top = open ? 0 : '100%'

        avatarPanelRef.current.style.height = (rootElement.current.offsetHeight - 540) + 'px';
    }, [open]);

    return (
        <Root ref={rootElement}>
            <ScrollablePanel>
                <Header>
                    <CloseButton type="button" onClick={onClose} />
                    Now Playing
                </Header>

                <AvatarPanel ref={avatarPanelRef}>
                    <Avatar>
                        {patient?.initials || ''}
                    </Avatar>
                </AvatarPanel>
                <NamePanel>
                    <div>
                        {
                            (patient?.name?.trim() || "[Name unknown]") +
                            (patient?.age ? ", " + patient.age : "")
                        }
                    </div>
                    <div>
                        Patient Summary
                    </div>
                </NamePanel>

                <AudioPlayer audioPlayerRef={audioPlayerRef} />

                <TranscriptPanel>
                    <TransHead>
                        <span>Audio transcript</span>
                        {/* <TranscriptButton onClick={handleOpenTranscriptClick} /> */}
                    </TransHead>
                    <TransBody>
                        {transcript || 'No transcript available'}
                    </TransBody>
                </TranscriptPanel>
            </ScrollablePanel>
            {/* <TranscriptionToast open={isTranscriptOpen} onClose={handleTranscriptClose} /> */}
        </Root>
    );
};

export default AudioPlayerToast;
