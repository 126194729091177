import { createSlice } from "@reduxjs/toolkit";

export const UserInfoSlice = createSlice({
  name: "userInfo",
  initialState: {
    providerDetails: {},
    userOHID: "",
    currentScreen: "",
    isSummaryOpen: false,
    addNewUserModelOpen: false,
    isMenuOpen: false,
    dynamicPrompt: [],
    authcode:"",
    notificationArray: [],
    searchInput: "",
    isAudioFooterOpen: false,
    refresh: true,
  },
  reducers: {
    saveProviderDetails: (state, action) => {
      state.providerDetails = action.payload;
      state.userOHID = action.payload.onehealthid;
    },
    saveCurrentScreen: (state, action) => {
      state.currentScreen = action.payload;
    },
    saveIsSummaryOpen: (state, action) => {
      state.isSummaryOpen = action.payload;
    },
    saveAddNewUserModelOpen: (state, action) => {
      state.addNewUserModelOpen = action.payload;
    },
    saveIsMenuOpen: (state, action) => {
      state.isMenuOpen = action.payload;
    },
    saveDynamicPrompt: (state, action) => {
      state.dynamicPrompt = action.payload;
    },
    saveAuthcode: (state, action) => {
      state.authcode = action.payload;
    },
    saveNotificationArray: (state, action) => {
      state.notificationArray = action.payload;
    },
    saveSearchInput: (state, action) => {
      state.searchInput = action.payload;
    },
    saveIsAudioFooterOpen: (state, action) => {
      state.isAudioFooterOpen = action.payload;
    },
    saveShouldRefresh: (state, action) => {
      state.refresh = action.payload;
    },
    //clearing user details before logout
    clearUserDetails: (state) => {
      state = {
        providerDetails: {},
        userOHID: "",
        currentScreen: "",
        isSummaryOpen: false,
        addNewUserModelOpen: false,
        isMenuOpen: false,
        dynamicPrompt: [],
        authcode:"",
        notificationArray: [],
      };
    },
  },
});

export const {
  saveProviderDetails,
  clearUserDetails,
  saveCurrentScreen,
  saveIsSummaryOpen,
  saveAddNewUserModelOpen,
  saveIsMenuOpen,
  saveDynamicPrompt,
  saveAuthcode,
  saveNotificationArray,
  saveSearchInput,
  saveIsAudioFooterOpen,
  saveShouldRefresh,
} = UserInfoSlice.actions;

export default UserInfoSlice.reducer;
