import * as React from "react";
import Button from "@mui/material/Button";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Stack from "@mui/material/Stack";
import ContactsIcon from "@mui/icons-material/Contacts";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import LogoutIcon from "@mui/icons-material/Logout";
import { clearUserDetails } from "../features/uiSlice";
import { useSelector, useDispatch } from "react-redux";
import { LogoutHandler } from "../api/Logout";
import { appInsights } from "./Logger/appinsights";

export default function MenuListComposition() {
  const dispatch = useDispatch();
  const providerDetails = useSelector(
    (state) => state.userDetails.providerDetails
  );
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleLogout = (event) => {
    logoutTracking();
    setOpen(false);
      LogoutHandler(providerDetails, (response)=>{
        window.sessionStorage.clear();
        dispatch(clearUserDetails());
      })

    
  };
  const logoutTracking = () => {
    console.log("log timer");
    appInsights.trackEvent({
      name: "customLogs",
      properties: {
        userid: providerDetails.oneHealthId,
        type: "logout",
        file: "AppLayout",
        role: providerDetails.role,
        apcId: providerDetails.apcId,
      },
    });
  };
  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }

  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <Stack direction="row" spacing={2}>
      <div>
        <Button
          ref={anchorRef}
          id="composition-button"
          aria-controls={open ? "composition-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
        >
          <ContactsIcon />
          {providerDetails.id}
        </Button>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          placement="bottom-start"
          transition
          disablePortal
          style={{ zIndex: 1000 }}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom-start" ? "left top" : "left bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id="composition-menu"
                    aria-labelledby="composition-button"
                    onKeyDown={handleListKeyDown}
                  >
                    <MenuItem onClick={handleClose}>
                      <AdminPanelSettingsIcon style={{ color: "blue" }} />
                      APC ID: {providerDetails.apcId}
                    </MenuItem>
                    <MenuItem onClick={handleClose}>
                      <ManageAccountsIcon style={{ color: "blue" }} />
                      User Role: {providerDetails.role}
                    </MenuItem>
                    <MenuItem onClick={handleLogout}>
                      <LogoutIcon style={{ color: "red" }} />
                      Logout
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </Stack>
  );
}
