import React, { useContext } from 'react';
// import Cam from "./img/cam.png";
// import Add from "./img/add.png";
// import More from "./img/more.png";
import Messages from "./Messages";
// import Input from "./Input";

import './style.scss'
import './styles.scss'

const Chat = ({messages,loader,type}) => {
  

  return (
    <div >
      
      <Messages messages = {messages} loader ={loader} type={type}/>
    
    </div>
  );
};

export default Chat;
