import React from "react";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  saveCurrentScreen,
  saveIsSummaryOpen,
  saveIsMenuOpen,
  clearUserDetails,
} from "../../features/uiSlice";
import useWindowSize from "../../CustomHooks/useWindow";
import HttpsIcon from "@mui/icons-material/Https";
// import { isMobile, isTablet } from "react-device-detect";

const Footer = (props) => {
  //   const { isTablet, onlySmallScreen } = useWindowSize();
  // const navigate = useNavigate();
  const { isTablet, isMobile } = useWindowSize();
  const currentScreen = useSelector((state) => state.userDetails.currentScreen);
  const isSummaryOpen = useSelector((state) => state.userDetails.isSummaryOpen);
  const providerDetails = useSelector(
    (state) => state.userDetails.providerDetails
  );
  const isMenuOpen = useSelector((state) => state.userDetails.isMenuOpen);
  const dispatch = useDispatch();

  return (
    <>
      {" "}
    </>
  );
};

export default Footer;
