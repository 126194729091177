import React from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const BarDetailsModal = ({ open, onClose, barSection, data }) => {
    const handleClose = () => {
        onClose();
    };

    console.log("Bar Section Data:", barSection);

    const filteredData = data
        .map((item) => {
            let score;
            switch (barSection.data.mlrbScore) {
                case "Demographic":
                    score = item.demographic_score;
                    break;
                case "Labs":
                    score = item.labs_score;
                    break;
                case "Social History":
                    score = item.social_history_score;
                    break;
                case "Family History":
                    score = item.family_history_score;
                    break;
                case "Visit Objective":
                    score = item.visit_objective_score;
                    break;
                case "Medications":
                    score = item.medications_score;
                    break;
                case "Conditions":
                    score = item.conditions_score;
                    break;
                default:
                    break;
            }

            const range = barSection.id.split("-");
            let conditionFulfilled = false;

            if (range.length === 1) {
                const condition = range[0] === "<0.5" ? "lt" : "gt";
                const limit = parseFloat(range[0].slice(1));
                conditionFulfilled = condition === "lt" ? score < limit : score > limit;
            } else {
                const minValue = parseFloat(range[0]);
                const maxValue = parseFloat(range[1]);
                conditionFulfilled = score >= minValue && score < maxValue;
            }

            if (conditionFulfilled) {
                console.log("Matched item:", item);
                return { ...item, score };
            } else {
                return null;
            }
        })
        .filter((item) => item !== null);

    console.log("Filtered Data:", filteredData);

    const sortedData = filteredData.sort(
        (a, b) => new Date(b.appointmentDate) - new Date(a.appointmentDate)
    );

    console.log("Sorted Data:", sortedData);

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="bar-details-modal-title"
            aria-describedby="bar-details-modal-description"
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '30%',
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 2,
                    maxHeight: '50%',
                    overflowY: 'auto',
                    borderRadius: 2,
                }}
            >
                <Typography id="bar-details-modal-title" variant="h6" component="h2">
                    <b>More Details:</b>
                </Typography>
                {sortedData.map((item, index) => (
                    <Box key={index} sx={{ borderBottom: "1px solid #e0e0e0", py: 2 }}>
                        <Typography variant="body2"><b>GUID</b>: {item.guid}</Typography>
                        <Typography variant="body2"><b>Score</b>: {item.score}</Typography>
                        <Typography variant="body2"><b>File Name</b>: {item.origFileName}</Typography>
                        <Typography variant="body2"><b>Date and Time</b>: {new Date(item.appointmentDate).toLocaleDateString()}, {new Date(item.appointmentDate).toLocaleTimeString()}</Typography>
                    </Box>
                ))}
            </Box>
        </Modal>
    );
};

export default BarDetailsModal;