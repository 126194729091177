import React, { useEffect, useState } from "react";
import axios from "../interceptors/interceptors";

export const FetchAudioURL = async (generatedPodcastFileName, callback) => {
  callback = (callback instanceof Function) ? callback : () => { };

  if (process.env.REACT_APP_Environment === 'offline')
    return callback("PLAY_OFFLINE");

  const url = process.env.REACT_APP_getSasUrl + "fileName=" + generatedPodcastFileName;

  const response = await axios.get(url)
    .then(response => callback(process.env.REACT_APP_URLForPodcastAPI + response.data))
    .catch(() => callback(null))
};


