import React, { useEffect, useState } from "react";
import axios from "../interceptors/interceptors";
import { isMobile } from "react-device-detect";


const openFallbackMaps = (address) => {  
  const url = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(address)}`;  
  window.open(url, "_blank");  
};

export const OpenNativeMaps = async (address, callback) => {
  callback = callback instanceof Function ? callback : () => {};

  const isMacOS = navigator.userAgent.includes('Mac');  

  if (isMobile) {
    if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {  
      const url = `https://maps.apple.com/?q=${encodeURIComponent(address)}`;  
      window.open(url, "_blank");  
    } else if (/Android/i.test(navigator.userAgent)) {  
      openFallbackMaps(address);  
    } else {  
      openFallbackMaps(address);  
    }  
  } else if (isMacOS) {  
    const url = `https://maps.apple.com/?q=${encodeURIComponent(address)}`;  
    window.open(url, "_blank");  
  } else {  
    openFallbackMaps(address);  
  }  

  return callback(true);
};