import { ResponsiveRadialBar } from "@nivo/radial-bar";

const mergeData = (dataArrays) => {
    const mergedData = {};

    dataArrays.forEach((dataArray) => {
        dataArray.forEach((item) => {
            if (!mergedData[item.label]) {
                mergedData[item.label] = item.value;
            } else {
                mergedData[item.label] += item.value;
            }
        });
    });

    return Object.entries(mergedData).map(([label, value]) => ({ label, value }));
};

const MyResponsiveRadialBar = ({ genderData, raceData, ethnicityData }) => {
    const mergedData = mergeData([genderData, raceData, ethnicityData]);

    const transformData = (dataArray, category) => {
        return dataArray.map((item) => ({ x: item.label, y: item.value, category }));
    };

    const combinedData = [
        {
            id: "Gender",
            data: transformData(genderData, "Gender"),
        },
        {
            id: "Race",
            data: transformData(raceData, "Race"),
        },
        {
            id: "Ethnicity",
            data: transformData(ethnicityData, "Ethnicity"),
        },
    ];


    return (
        <ResponsiveRadialBar
            data={combinedData}
            valueFormat=">-.2f"
            padding={0.4}
            colors={{ scheme: 'nivo' }}
            cornerRadius={2}
            margin={{ top: 40, right: 120, bottom: 60, left: -200 }}
            radialAxisStart={{ tickSize: 5, tickPadding: 5, tickRotation: 0 }}
            circularAxisOuter={{ tickSize: 5, tickPadding: 12, tickRotation: 0 }}
            legends={[
                {
                    anchor: "right",
                    direction: "column",
                    justify: false,
                    translateX: -100,
                    translateY: 0,
                    itemsSpacing: 6,
                    itemDirection: "left-to-right",
                    itemWidth: 100,
                    itemHeight: 18,
                    itemTextColor: "#999",
                    symbolSize: 18,
                    symbolShape: "square",
                    effects: [
                        {
                            on: "hover",
                            style: {
                                itemTextColor: "#000",
                            },
                        },
                    ],
                },
            ]}
        />
    );
};

export default MyResponsiveRadialBar;  
