import React from "react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from "../../interceptors/interceptors";
import MenuDrawer from "../ProfileMenuFolder/MenuDrawer";
import { ReactComponent as Toast } from "../../assets/Toast.svg";
import FavoriteIcon from "@mui/icons-material/Favorite";
import WarningIcon from "@mui/icons-material/Warning";
import ForumIcon from "@mui/icons-material/Forum";
import { styled, keyframes } from "styled-components";

const Root = styled.div`
    background: transparent;
    scrollbar-width: none;
    position: fixed;
    top: 64px;
    bottom: 0;
    left: 16px;
    right: 16px;
    font-family: Optum Sans, Helvetica, Arial;
    overflow: hidden;
  `

const Header = styled.div`
  margin: 40px 0 32px 0;
  color: #001D5B;

  font-family: "Optum Sans", Helvetica, Arial;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px; 
  `

const MenuButton = styled.button`
  display: flex;
  padding: 8px 16px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  width: 100%;

  border-radius: 24px;
  border: solid 1px #001D5B;
  background: #FFF;

  color: #001D5B;
  text-align: center;
  font-family: "Optum Sans", Helvetica, Arial;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; 

  &.selected {
    background: #001D5B;
    color: var(--Optum-Primary-White, #FFF);
  }
  `
const ClosedView = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

const OpenView = styled.div`
    display: flex;
    flex-direction: column;
  `

const Question = styled.div`
  color: #4B4D4F;
  font-family: "Optum Sans", Helvetica, Arial;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; 

  margin-top: 24px;
  margin-bottom: 4px;

  & svg {
      position: relative;
      top: -4px;
      left: 4px;
  }
  `

const UserResponse = styled.textarea`
  border-radius: 4px;
  border: 1px solid var(--neutral-70, #6D6F70);
  background: var(--neutral-0, #FFF);

  color: #4B4D4F;
  font-family: "Optum Sans", Helvetica, Arial;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; 

  padding: 8px 16px 0px 16px;
  height: 171px;

  &:focus {
    outline-color: #6D6F70;
  }
  `

const ButtonPanel = styled.div`
  position: absolute;
  bottom: 0px;
  left: 0;
  right: 0;

  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 16px;
  margin: 32px 0px;
  `

const SubmitButton = styled.button`
  display: flex;
  padding: 14px 24px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border-radius: 50px;
  background: #001D5B;
  width: 100%;
  max-width: 360px; 
  margin: auto;

  color: #FFF;
  text-align: center;
  font-family: "Optum Sans", Helvetica, Arial;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; 

  &:disabled {
    color: #7D7F81;
    background: #E5E5E6;
    border: transparent;
  }
  `

const BackButton = styled.button`
  display: flex;
  height: 30px;
  padding: 14px 24px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  color: #0C55B8;
  background: transparent;
  border: none;

  font: "Optum Sans";
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
  color #0C55B8; 
  `

const slideIn = keyframes`
  0% { opacity: 0; top: 104px, animation-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1) }
  13% { opacity: 1; top: 64px }

  76% { opacity: 1; top: 64px }
  77% { opacity: 1; top: 64px, animation-timing-function: ease-out; }
  100% { opacity: 0; top: 104px }
 `

const Message = styled.div`
  position: fixed;
  top: 104px;
  left: 16px;
  right: 16px;  
  display: flex;
  justify-content: center;
  background: transparent;

  &.show {
    animation-name: ${slideIn};
    animation-duration: 4s;
  }

  & svg {
    max-width: 600px;
  }
  `

const Feedback = () => {
  const isMenuOpen = useSelector((state) => state.userDetails.isMenuOpen);
  const [openFeedbackForm, setOpenFeedbackForm] = useState("");
  const [feedbackValue, setFeedbackValue] = useState("");
  const [isFeedbackSubmitted, setIsFeedbackSubmitted] = useState(false);
  const providerDetails = useSelector((state) => state.userDetails.providerDetails);

  useEffect(() => {
    if (isFeedbackSubmitted) {
      setTimeout(() => {
        setIsFeedbackSubmitted(false);
      }, 4000);
    }
  }, [isFeedbackSubmitted]);

  const handleClick = () => {
    saveFeedback();
  };

  const saveFeedback = async () => {
    const feedbackType = openFeedbackForm === "Give a compliment" ? "compliment" : openFeedbackForm === "Report a problem" ? "problem" : "suggestion";

    setOpenFeedbackForm("");
    setFeedbackValue("");
    setIsFeedbackSubmitted(true);

    try {
      const response = await axios.post(
        process.env.REACT_APP_appointmentFeedback,
        {
          feedbacktype: feedbackType,
          feedback: feedbackValue,
          apcid: providerDetails.apcId,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
    }
    catch (e) {
      console.log(e);
    }
  }

  return (
    <Root id="FeedbackPage_RootDiv">
      <MenuDrawer />
      <Header id="FeedbackPageLabel">
        Leave us feedback
      </Header>
      {
        openFeedbackForm === ""
          ?
          <ClosedView>
            <MenuButton
              id="FeedbackPageComplimentDiv"
              onClick={(e) => { setOpenFeedbackForm("Give a compliment"); }}
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M12 20.3254C11.7667 20.3254 11.5292 20.2837 11.2875 20.2004C11.0458 20.1171 10.8333 19.9837 10.65 19.8004L8.925 18.2254C7.15833 16.6087 5.5625 15.0046 4.1375 13.4129C2.7125 11.8212 2 10.0671 2 8.15039C2 6.58372 2.525 5.27539 3.575 4.22539C4.625 3.17539 5.93333 2.65039 7.5 2.65039C8.38333 2.65039 9.21667 2.83789 10 3.21289C10.7833 3.58789 11.45 4.10039 12 4.75039C12.55 4.10039 13.2167 3.58789 14 3.21289C14.7833 2.83789 15.6167 2.65039 16.5 2.65039C18.0667 2.65039 19.375 3.17539 20.425 4.22539C21.475 5.27539 22 6.58372 22 8.15039C22 10.0671 21.2917 11.8254 19.875 13.4254C18.4583 15.0254 16.85 16.6337 15.05 18.2504L13.35 19.8004C13.1667 19.9837 12.9542 20.1171 12.7125 20.2004C12.4708 20.2837 12.2333 20.3254 12 20.3254Z" fill="#001D5B" />
              </svg>
              Give a compliment
            </MenuButton>

            <MenuButton
              id="FeedbackPageReportDiv"
              onClick={(e) => { setOpenFeedbackForm("Report a problem"); }}
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M2.72479 21C2.54146 21 2.37479 20.9542 2.22479 20.8625C2.07479 20.7708 1.95812 20.65 1.87479 20.5C1.79146 20.35 1.74562 20.1875 1.73729 20.0125C1.72896 19.8375 1.77479 19.6667 1.87479 19.5L11.1248 3.5C11.2248 3.33333 11.354 3.20833 11.5123 3.125C11.6706 3.04167 11.8331 3 11.9998 3C12.1665 3 12.329 3.04167 12.4873 3.125C12.6456 3.20833 12.7748 3.33333 12.8748 3.5L22.1248 19.5C22.2248 19.6667 22.2706 19.8375 22.2623 20.0125C22.254 20.1875 22.2081 20.35 22.1248 20.5C22.0415 20.65 21.9248 20.7708 21.7748 20.8625C21.6248 20.9542 21.4581 21 21.2748 21H2.72479ZM11.9998 18C12.2831 18 12.5206 17.9042 12.7123 17.7125C12.904 17.5208 12.9998 17.2833 12.9998 17C12.9998 16.7167 12.904 16.4792 12.7123 16.2875C12.5206 16.0958 12.2831 16 11.9998 16C11.7165 16 11.479 16.0958 11.2873 16.2875C11.0956 16.4792 10.9998 16.7167 10.9998 17C10.9998 17.2833 11.0956 17.5208 11.2873 17.7125C11.479 17.9042 11.7165 18 11.9998 18ZM11.9998 15C12.2831 15 12.5206 14.9042 12.7123 14.7125C12.904 14.5208 12.9998 14.2833 12.9998 14V11C12.9998 10.7167 12.904 10.4792 12.7123 10.2875C12.5206 10.0958 12.2831 10 11.9998 10C11.7165 10 11.479 10.0958 11.2873 10.2875C11.0956 10.4792 10.9998 10.7167 10.9998 11V14C10.9998 14.2833 11.0956 14.5208 11.2873 14.7125C11.479 14.9042 11.7165 15 11.9998 15Z" fill="#001D5B" />
              </svg>
              Report a problem
            </MenuButton>

            <MenuButton
              id="FeedbackPageSuggestionDiv"
              onClick={(e) => { setOpenFeedbackForm("Make a suggestion"); }}
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M7 18C6.71667 18 6.47917 17.9042 6.2875 17.7125C6.09583 17.5208 6 17.2833 6 17V15H19V6H21C21.2833 6 21.5208 6.09583 21.7125 6.2875C21.9042 6.47917 22 6.71667 22 7V19.575C22 20.025 21.7958 20.3375 21.3875 20.5125C20.9792 20.6875 20.6167 20.6167 20.3 20.3L18 18H7ZM6 13L3.7 15.3C3.38333 15.6167 3.02083 15.6875 2.6125 15.5125C2.20417 15.3375 2 15.025 2 14.575V3C2 2.71667 2.09583 2.47917 2.2875 2.2875C2.47917 2.09583 2.71667 2 3 2H16C16.2833 2 16.5208 2.09583 16.7125 2.2875C16.9042 2.47917 17 2.71667 17 3V12C17 12.2833 16.9042 12.5208 16.7125 12.7125C16.5208 12.9042 16.2833 13 16 13H6Z" fill="#001D5B" />
              </svg>
              Make a suggestion
            </MenuButton>
          </ClosedView>
          :
          <OpenView id="FeedbackPageTextAreaDiv">
            <MenuButton id="FeedbackPageTextAreaDivFlex" className="selected">
              {
                openFeedbackForm === "Give a compliment"
                  ?
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M12 20.3254C11.7667 20.3254 11.5292 20.2837 11.2875 20.2004C11.0458 20.1171 10.8333 19.9837 10.65 19.8004L8.925 18.2254C7.15833 16.6087 5.5625 15.0046 4.1375 13.4129C2.7125 11.8212 2 10.0671 2 8.15039C2 6.58372 2.525 5.27539 3.575 4.22539C4.625 3.17539 5.93333 2.65039 7.5 2.65039C8.38333 2.65039 9.21667 2.83789 10 3.21289C10.7833 3.58789 11.45 4.10039 12 4.75039C12.55 4.10039 13.2167 3.58789 14 3.21289C14.7833 2.83789 15.6167 2.65039 16.5 2.65039C18.0667 2.65039 19.375 3.17539 20.425 4.22539C21.475 5.27539 22 6.58372 22 8.15039C22 10.0671 21.2917 11.8254 19.875 13.4254C18.4583 15.0254 16.85 16.6337 15.05 18.2504L13.35 19.8004C13.1667 19.9837 12.9542 20.1171 12.7125 20.2004C12.4708 20.2837 12.2333 20.3254 12 20.3254Z" fill="white" />
                  </svg>
                  :
                  openFeedbackForm === "Report a problem"
                    ?
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path d="M2.72479 21C2.54146 21 2.37479 20.9542 2.22479 20.8625C2.07479 20.7708 1.95812 20.65 1.87479 20.5C1.79146 20.35 1.74562 20.1875 1.73729 20.0125C1.72896 19.8375 1.77479 19.6667 1.87479 19.5L11.1248 3.5C11.2248 3.33333 11.354 3.20833 11.5123 3.125C11.6706 3.04167 11.8331 3 11.9998 3C12.1665 3 12.329 3.04167 12.4873 3.125C12.6456 3.20833 12.7748 3.33333 12.8748 3.5L22.1248 19.5C22.2248 19.6667 22.2706 19.8375 22.2623 20.0125C22.254 20.1875 22.2081 20.35 22.1248 20.5C22.0415 20.65 21.9248 20.7708 21.7748 20.8625C21.6248 20.9542 21.4581 21 21.2748 21H2.72479ZM11.9998 18C12.2831 18 12.5206 17.9042 12.7123 17.7125C12.904 17.5208 12.9998 17.2833 12.9998 17C12.9998 16.7167 12.904 16.4792 12.7123 16.2875C12.5206 16.0958 12.2831 16 11.9998 16C11.7165 16 11.479 16.0958 11.2873 16.2875C11.0956 16.4792 10.9998 16.7167 10.9998 17C10.9998 17.2833 11.0956 17.5208 11.2873 17.7125C11.479 17.9042 11.7165 18 11.9998 18ZM11.9998 15C12.2831 15 12.5206 14.9042 12.7123 14.7125C12.904 14.5208 12.9998 14.2833 12.9998 14V11C12.9998 10.7167 12.904 10.4792 12.7123 10.2875C12.5206 10.0958 12.2831 10 11.9998 10C11.7165 10 11.479 10.0958 11.2873 10.2875C11.0956 10.4792 10.9998 10.7167 10.9998 11V14C10.9998 14.2833 11.0956 14.5208 11.2873 14.7125C11.479 14.9042 11.7165 15 11.9998 15Z" fill="white" />
                    </svg>
                    :
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path d="M7 18C6.71667 18 6.47917 17.9042 6.2875 17.7125C6.09583 17.5208 6 17.2833 6 17V15H19V6H21C21.2833 6 21.5208 6.09583 21.7125 6.2875C21.9042 6.47917 22 6.71667 22 7V19.575C22 20.025 21.7958 20.3375 21.3875 20.5125C20.9792 20.6875 20.6167 20.6167 20.3 20.3L18 18H7ZM6 13L3.7 15.3C3.38333 15.6167 3.02083 15.6875 2.6125 15.5125C2.20417 15.3375 2 15.025 2 14.575V3C2 2.71667 2.09583 2.47917 2.2875 2.2875C2.47917 2.09583 2.71667 2 3 2H16C16.2833 2 16.5208 2.09583 16.7125 2.2875C16.9042 2.47917 17 2.71667 17 3V12C17 12.2833 16.9042 12.5208 16.7125 12.7125C16.5208 12.9042 16.2833 13 16 13H6Z" fill="white" />
                    </svg>
              }
              {openFeedbackForm}
            </MenuButton>

            <Question id="FeedbackPageFeedbackLabelDiv">
              {
                openFeedbackForm === "Give a compliment"
                  ? `What did you like?`
                  : openFeedbackForm === "Report a problem"
                    ? `What went wrong?`
                    : `What can we do better? `
              }
              <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                <path d="M5.69974 10.3002C5.59141 10.3002 5.50182 10.2648 5.43099 10.1939C5.36016 10.1231 5.32474 10.0335 5.32474 9.9252V7.16269L2.93724 8.5502C2.84557 8.6002 2.74974 8.6127 2.64974 8.5877C2.54974 8.5627 2.47474 8.50436 2.42474 8.4127L2.12474 7.9002C2.06641 7.80853 2.05182 7.7127 2.08099 7.6127C2.11016 7.5127 2.17057 7.4377 2.26224 7.3877L4.62474 6.0002L2.26224 4.6377C2.17057 4.5877 2.11016 4.5127 2.08099 4.4127C2.05182 4.3127 2.06641 4.21686 2.12474 4.1252L2.42474 3.6127C2.47474 3.52103 2.54974 3.4627 2.64974 3.4377C2.74974 3.4127 2.84557 3.4252 2.93724 3.4752L5.32474 4.8377V2.0752C5.32474 1.96686 5.36016 1.87728 5.43099 1.80645C5.50182 1.73561 5.59141 1.7002 5.69974 1.7002H6.29974C6.40807 1.7002 6.49766 1.73561 6.56849 1.80645C6.63932 1.87728 6.67474 1.96686 6.67474 2.0752V4.8377L9.06224 3.4752C9.15391 3.4252 9.24974 3.4127 9.34974 3.4377C9.44974 3.4627 9.52474 3.52103 9.57474 3.6127L9.87474 4.1252C9.93307 4.21686 9.94766 4.3127 9.91849 4.4127C9.88932 4.5127 9.82891 4.5877 9.73724 4.6377L7.37474 6.0002L9.73724 7.3877C9.82891 7.4377 9.88932 7.5127 9.91849 7.6127C9.94766 7.7127 9.93307 7.80853 9.87474 7.9002L9.57474 8.4127C9.52474 8.50436 9.44974 8.5627 9.34974 8.5877C9.24974 8.6127 9.15391 8.6002 9.06224 8.5502L6.67474 7.16269V9.9252C6.67474 10.0335 6.63932 10.1231 6.56849 10.1939C6.49766 10.2648 6.40807 10.3002 6.29974 10.3002H5.69974Z" fill="#C40000" />
              </svg>
            </Question>

            <UserResponse
              id="FeedbackPageFeedbackTextArea"
              placeholder="Give as much detail as possible. Do not include any sensitive or patient specific information."
              value={feedbackValue}
              onChange={(e) => {
                setFeedbackValue(e.target.value);
              }}
            />
          </OpenView>
      }

      {
        openFeedbackForm !== "" &&
        <ButtonPanel id="FeedbackPageFeedbackSubmitButtonDiv"        >
          <SubmitButton
            id="FeedbackPageFeedbackSubmitButton"
            disabled={feedbackValue === ""}
            variant="contained"
            onClick={handleClick}
          >
            Submit
          </SubmitButton>

          <BackButton
            id="FeedbackPageFeedbackBackButtonDiv"
            onClick={() => {
              setOpenFeedbackForm("");
              setFeedbackValue("");
            }}
          >
            Back
          </BackButton>
        </ButtonPanel>
      }

      {
        isFeedbackSubmitted &&
        <Message id="FeedbackPageFeedbackSubmitToastMessage" className="show">
          <Toast />
        </Message>
      }

    </Root>
  );
};

export default Feedback;
