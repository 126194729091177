import React from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { App } from "antd";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import AddchartIcon from "@mui/icons-material/Addchart";
import ErrorLogs from "../Charts/ErrorLogs";

const bull = (
  <Box
    component="span"
    sx={{ display: "inline-block", mx: "2px", transform: "scale(0.8)" }}
  >
    •
  </Box>
);

const AgeChart = () => {
  return (
    <>
      <Card
        sx={{
          minWidth: "56%",
          height: "60%",
          maxWidth: "100%",
          backgroundColor: "rgba(255, 255, 255, 0.3)",
          border: "2px solid white",
        }}
      >
        <CardContent></CardContent>
        <ErrorLogs />
        {/* <CardActions></CardActions> */}
      </Card>
    </>
  );
};

export default AgeChart;
