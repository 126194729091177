import { useEffect, useState } from "react";
import { ReactComponent as MenuBar } from "../../assets/MenuBar.svg";
import CloseIcon from "@mui/icons-material/Close";
import "./styles.css";
import { Button } from "@mui/material";
import styled from "styled-components";

const Header = styled.div`
  font-family: 'optum sans';
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
  color: #001d5b;
  padding: 0 16px;
  `

const HeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  `

const GrabBar = styled.div`
  margin-top: 8px;
  margin-bottom: 16px;
  display: flex;
  justify-content: center;
  `

const HorzLine = styled.div`
  margin: 16px 0px;
  border-top: solid 1px #CBCCCD;
  `

const ContentPanel = styled.div`
  margin: 24px 16px;
  font-family: 'optum Sans';
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
  color: #4B4D4F;
  `

const FilterButtonPanel = styled.div`
  margin: 8px 8px;
  display: block;
  `

const FilterButton = styled.div`
  display: inline-flex;
  margin: 8px 8px 8px 0;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 100px;
  background: #F3F3F3;
  color: transparent;
  font-family: "Optum Sans", Helvetica, Arial;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  border: 2px solid transparent;

  &.Filter_selected {
    border: 2px solid #0C55B8;
    background: #EEF4FF;

    &  span {
      color: #001D5B;
      font-weight: 700;
      position: absolute;
    }
  }

  &  span {
    position: absolute;
    font-weight: 400;
    color: #4B4D4F;
  }
  `

const ActionButtonPanel = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 21px;
  margin: 32px 0 24px;
  padding: 0 24px;
  `

const applyButtonStyles = {
  borderRadius: "999px",
  width: "100%",
  backgroundColor: "#001D5B",
  maxWidth: "343px",
  textTransform: "none",
  fontFamily: "optum Sans",
  fontSize: "16px",
  lineHeight: "20px",
  fontWeight: 700,
  color: "#FFF",
  padding: "14px 24px",
  boxShadow: "none"
}

const resetButtonStyles = {
  width: "100%",
  fontWeight: 700,
  color: "#0C55B8",
  textTransform: "none",
  padding: "4px",

  fontFamily: "optum Sans",
  fontSize: "16px",
  lineHeight: "20px",
  fontWeight: 700,
  color: "#0C55B8",
  boxShadow: "none",
}

const Filter = ({ ApplyFilter, isFilterOpen, setIsFilterOpen, filters, setFilters, wasSwipedClose, setWasSwipedClose }) => {
  const [resetValues, setResetValues] = useState();

  filters = Array.isArray(filters)
    ? filters
    : []

  setFilters = (setFilters instanceof Function)
    ? setFilters
    : () => { };

  useEffect(() => {
    if (isFilterOpen) {
      setResetValues(filters.map(filter => ({ ...filter })));
      setWasSwipedClose(false);
    }
  }, [isFilterOpen])

  useEffect(() => {
    if (wasSwipedClose) {
      setIsFilterOpen(false);
      handleResetFilter();
    }
  }, [wasSwipedClose])

  const handleApplyFilter = () => {
    setIsFilterOpen(false);
    ApplyFilter(filters);
  };

  const handleResetFilter = () => {
    setFilters(resetValues.map(filter => ({ ...filter })));
  }

  const handleClose = () => {
    setIsFilterOpen(false);
    handleResetFilter();
  }

  const handleGrabBarClick = (e) => {
    if (e.target.id !== 'GrabBarSVG') return;

    handleClose();
  }

  const handleGrabBarDrag = (e) => {
    if (e.target.id === 'GrabBarSVG') return;

    e.stopPropagation();
  }

  const handleFilterClick = (index) => {
    filters[index].selected = !filters[index].selected;

    setFilters([...filters]);
  }

  return (
    <div
      id="FilterDivHead"
      onClick={handleGrabBarClick}
      onTouchStart={handleGrabBarDrag}
    >
      <Header id="FilterDivTopBar">
        <GrabBar id="GrabBarDiv">
          <MenuBar />
        </GrabBar>
        <HeaderContent id="filterTilteDiv">
          <div id="divFilterTitle">Filter</div>
          <CloseIcon
            id="iconClose"
            style={{ cursor: "pointer" }}
            onClick={handleClose}
          />
        </HeaderContent>
      </Header>

      <HorzLine />

      <ContentPanel id="filterDivContentContainer">
        <label id="filterByDateLabel">
          Filter by date:
        </label>

        <FilterButtonPanel id="filterDivContent2">
          {
            filters.map((filter, i) => {
              return (
                <FilterButton
                  key={'FilterButton' + i}
                  id={'FilterButton' + i}
                  onClick={() => handleFilterClick(i)}
                  className={filter.selected ? "Filter_selected" : "Filter_unselected"}
                >
                  {filter.text}
                  <span>{filter.text}</span>
                </FilterButton>
              )
            })
          }
        </FilterButtonPanel>

        <ActionButtonPanel>
          <Button
            variant="contained"
            style={applyButtonStyles}
            onClick={handleApplyFilter}
          >
            Apply
          </Button>

          <Button
            style={resetButtonStyles}
            disableRipple
            onClick={handleResetFilter}
          >
            Reset
          </Button>
        </ActionButtonPanel>
      </ContentPanel>
    </div >
  );
};

export default Filter;
