import * as React from "react";
import Drawer from "@mui/material/SwipeableDrawer";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import useWindowSize from "../../CustomHooks/useWindow";
import { saveIsMenuOpen } from "../../features/uiSlice";
import "./styles.css";
import Menu from "./Menu";

export default function TemporaryDrawer() {
  const dispatch = useDispatch();
  const { isTablet, isMobile } = useWindowSize();
  const providerDetails = useSelector((state) => state.userDetails.providerDetails);
  const isMenuOpen = useSelector((state) => state.userDetails.isMenuOpen);
  const currentScreen = useSelector((state) => state.userDetails.currentScreen);
  const [selectedMenu, setSelectedMenu] = useState("Appointments");
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    dispatch(saveIsMenuOpen(false));

    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  return (
    <div id="divBoxDrawer">
      <Drawer
        anchor="bottom"
        id="Drawer"
        open={isMenuOpen}
        onOpen={() => dispatch(saveIsMenuOpen(true))}
        onClose={() => dispatch(saveIsMenuOpen(false))}
        PaperProps={{
          sx: {
            width:
              (providerDetails.role === "APC" ||
                providerDetails.role === "Demo User") &&
                !isTablet &&
                !isMobile
                ? "52%"
                : "100%",
            marginLeft:
              (providerDetails.role === "APC" ||
                providerDetails.role === "Demo User") &&
              !isTablet &&
              !isMobile &&
              "23.5%",
            borderRadius: " 20px 20px 0px 0px !important",
            backgroundColor: "#fff",
            color: "rgba(0, 0, 0, 0.87)",
            boxShadow: "0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12)",
            overflowY: "auto",
            display: "flex",
            height: "auto",
            flex: "1 0 auto",
            Zindex: "1200",
            position: "fixed",
            top: "auto",
            outline: 0,

            maxHeight: "100%",
          },
        }}
      >
        <Menu closeMenu={ () => dispatch(saveIsMenuOpen(false)) }/>
      </Drawer>
    </div >
  );
}
