import { ResponsiveBar } from '@nivo/bar'

const MlrbResponsiveBar = (props) => (
    <ResponsiveBar
        data={props.data}
        keys={[">0.9", "0.7-0.9", "0.5-0.7", "<0.5"]}
        indexBy="mlrbScore"
        margin={{ top: 30, right: 130, bottom: 65, left: 60 }}
        padding={0.5}
        borderRadius={4}
        valueScale={{ type: 'linear' }}
        indexScale={{ type: 'band', round: true }}
        theme={{
            labels: {
                text: {
                    fontWeight: 'bold',
                    fontSize: '14px',
                    color: 'black'
                },
            },
        }}
        onClick={(bar) => {
            if (props.onBarClick) {
                props.onBarClick(bar);
            }
        }}
        colors={(d) => {
            switch (d.id) {
                case "<0.5":
                    return "#E4780C";
                case "0.5-0.7":
                    return "#8061BC";
                case "0.7-0.9":
                    return "#C72887";
                case ">0.9":
                    return "#15A796";
                default:
                    return "grey";
            }
        }}
        defs={[
            {
                id: 'dots',
                type: 'patternDots',
                background: 'inherit',
                color: '#38bcb2',
                size: 3,
                padding: 1,
                stagger: true
            },
            {
                id: 'lines',
                type: 'patternLines',
                background: 'inherit',
                color: '#cc99ff',
                rotation: -45,
                lineWidth: 1,
                spacing: 10
            }
        ]}
        fill={[
            {
                match: { id: '0.5-0.7' },
                id: 'lines'
            },
            {
                match: { id: '>0.9' },
                id: 'dots'
            }
        ]}
        borderColor={{
            from: 'color',
            modifiers: [
                [
                    'darker',
                    1.6
                ]
            ],
        }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'MLRB Score Type',
            legendPosition: 'middle',
            legendOffset: 32,
            truncateTickAt: 0
        }}
        axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'Count',
            legendPosition: 'middle',
            legendOffset: -40,
            truncateTickAt: 0
        }}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={{
            from: 'color',
            modifiers: [
                [
                    'darker',
                    1.6
                ]
            ]
        }}
        legends={[
            {
                dataFrom: 'keys',
                anchor: 'bottom-right',
                direction: 'column',
                justify: false,
                translateX: 120,
                translateY: 0,
                itemsSpacing: 2,
                itemWidth: 100,
                itemHeight: 20,
                itemDirection: 'left-to-right',
                itemOpacity: 0.85,
                symbolSize: 20,
                effects: [
                    {
                        on: 'hover',
                        style: {
                            itemOpacity: 1
                        }
                    }
                ]
            }
        ]}
        role="application"
        ariaLabel="Nivo bar chart demo"
        barAriaLabel={e => e.id + ": " + e.formattedValue + " in mlrbScore: " + e.indexValue}
    />
)
export default MlrbResponsiveBar;