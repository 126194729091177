import React from "react";
import axios from "../../interceptors/interceptors";
import { useEffect, useState,useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  saveCurrentScreen,
  saveIsSummaryOpen,
  saveNotificationArray,
  saveIsAudioFooterOpen,
  saveShouldRefresh,
} from "../../features/uiSlice";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import "./style.css";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import useWindowSize from "../../CustomHooks/useWindow";
import { Button } from "@mui/material";
import Summary from "./Summary";
import AudioPlayerFooter from "./AudioPlayerFooter";
import { isMobile } from "react-device-detect";
import rehypeRaw from "rehype-raw";
import { ReactComponent as Filter_list } from "../../assets/filter_list.svg";
import MenuDrawer from "../ProfileMenuFolder/MenuDrawer";
import ReactMarkdown from "react-markdown";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import LaunchIcon from "@mui/icons-material/Launch";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import FilterComponent from "../ProfileMenuFolder/FilterDrawer";
import Footer from "./Footer";
import CancelledNotification from "./CancelledNotification";
import {GetAppointmentsList} from "../../api/GetAppointmentsList";
import { FetchCancelledAppointments } from "../../api/FetchCancelledAppointments";
import {FetchAudioURL} from "../../api/FetchAudioURL";
import {OpenNativeMaps} from "../../api/OpenNativeMaps";
import { ReactComponent as FlagIcon } from "../../assets/flag.svg";
import styled from "styled-components";

const FlagMessage = styled.div`
color: #4B4D4F;
font-family: "Optum Sans";
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 20px;
padding-left: 6px;

`
const FlagContainer = styled.div`
margin-top: 16px;
display: flex

`


const PatientList = (props) => {
  const {  minPlayScreen } = useWindowSize();

  const isSummaryOpen = useSelector((state) => state.userDetails.isSummaryOpen);
  const providerDetails = useSelector(
    (state) => state.userDetails.providerDetails
  );
  const dispatch = useDispatch();
  const hasMounted = useRef(false); // This ref will keep track of whether the component has mounted 

  const notificationArray = useSelector(
    (state) => state.userDetails.notificationArray
  );
  const isAudioFooterOpen = useSelector(
    (state) => state.userDetails.isAudioFooterOpen
  );
  const refresh = useSelector((state) => state.userDetails.refresh);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [currentName, setCurrentName] = useState("");
  const [currentNameForProfilePic, setCurrentNameForProfilePic] = useState("");
  const [currentAddress, setCurrentAddress] = useState("");
  const [currentDate, setCurrentDate] = useState("");
  const [currentSummary, setCurrentSummary] = useState("");
  const [currentAudioFile, setCurrentAudioFile] = useState("");
  const [audioData, setAudioData] = useState("");
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [todayFilter, setTodayFilter] = useState(false);
  const [yesterdayFilter, setYesterdayFilter] = useState(false);
  const [tomorrowFilter, setTomorrowFilter] = useState(false);
  const [appointmentID, setAppointmentID] = useState("");
  const [apcID, setApcID] = useState("");
  const [guid, setGuid] = useState("");
  const [age, setAge] = useState(null);


  useEffect(() => {
    dispatch(saveCurrentScreen("patientList"));
        // If the component has mounted before, only fetch data if `refresh` is true  
        if (hasMounted.current) {  
          if (refresh) FetchData();  
        } else {  
          // If the component hasn't mounted before, fetch data without checking `refresh`  
          FetchData();  
          hasMounted.current = true; // Set the ref to true after fetching data on mount  
        }
    
  }, [refresh,dispatch]);

  const FetchData = () => {
  FetchCancelledAppointments(JSON.parse(window.sessionStorage.getItem("UserOnehealthID")),(value)=>{
  
    fetchAppointmentsList(value);

});
};
  const fetchAppointmentsList = (cancelledAppointments)=>{

    const apcID_forAPI =  (providerDetails.role === "Demo User" ||
    providerDetails.role === "APC" || providerDetails.role === "Client Manager" ) ? providerDetails.apcId : "";
    GetAppointmentsList(providerDetails.role,apcID_forAPI,cancelledAppointments?.appointmentIds,(value,cancelledAppointments)=>{
      
      getPatientData(value,cancelledAppointments);
    })
  };

  const convertDateForFilter = (val) => {
    const date = new Date(val);
    let month = date.getMonth() + 1;
    let day = date.getDate();
    const year = date.getFullYear();
    // Checking if the day and month have a single digit  
day = String(day);  
month = String(month); 

if (day.length === 1) {  
  day = "0" + day;  
}  
if (month.length === 1) {  
  month = "0" + month;  
} 

    const convertedDate = `${month}/${day}/${year}`;
    return convertedDate;
  };

  const ApplyFilter = (val) => {
    
    const currentTime = new Date(); // Get current date and time
    let yesterday = new Date(currentTime);
    yesterday.setDate(yesterday.getDate() - 1);
    let tomorrow = new Date(currentTime);
    tomorrow.setDate(tomorrow.getDate() + 1);
    const currDate = convertDateForFilter(currentTime);
    const yesDate = convertDateForFilter(yesterday);
    const tomDate = convertDateForFilter(tomorrow);
    let CstDateFilterData = val.map((item) => {
      return convertDateForFilter(item);
    });

    if (yesterdayFilter) {
      if (!CstDateFilterData.includes(yesDate)) {
        CstDateFilterData.push(yesDate);
      }
    }
    if (todayFilter) {
      if (!CstDateFilterData.includes(currDate)) {
        CstDateFilterData.push(currDate);
      }
    }
    if (tomorrowFilter) {
      if (!CstDateFilterData.includes(tomDate)) {
        CstDateFilterData.push(tomDate);
      }
    }

    const filteredResult = filteredData.filter((obj) =>
      CstDateFilterData.includes(obj.date)
    );

    if (CstDateFilterData.length !== 0) {
      setData(filteredResult);
    } else setData(filteredData);
  };

  const handleAddressClick = (address) => {  
    OpenNativeMaps(address,(value)=>{
      // console.log("OpenNativeMaps",value);
    
    });  
  };

  const convertToCST = (val, type) => {
    const appointmentTime = new Date(val);
    const currentTime = new Date();
    const ScheduledTime = val; // Get current date and time

    const localCurrentTime = currentTime.toLocaleString([], {
      dateStyle: "short",
      timeStyle: "short",
    });

    const appointmentLocalTime = appointmentTime.toLocaleString([], {
      dateStyle: "short",
      timeStyle: "short",
    });
    const dateToCompare = appointmentLocalTime.split(", ")[0];
    const dateAndTime = ScheduledTime.split(" ");
    let date = dateAndTime[0];
    const time =
      dateAndTime[1].split(":")[0] +
      ":" +
      dateAndTime[1].split(":")[1] +
      " " +
      dateAndTime[2];

    const formatDate = new Date(date);

    const options = { month: "short", day: "numeric" };
    const formattedDate = formatDate.toLocaleDateString("en-US", options);

    const dateAndTimeCurrent = localCurrentTime.split(", ");
    const currentDate = dateAndTimeCurrent[0];
    let appointmentType = "";

    // Compare date and time with current date and time
    if (appointmentTime < currentTime) {
      appointmentType = dateToCompare === currentDate ? "Today_Past" : "PAST";
    } else if (
      dateToCompare === currentDate // Compare only the date part
    ) {
      appointmentType = "TODAY";
    } else {
      appointmentType = "FUTURE";
    }

    const formattedTime = time.replace("AM", "a.m.").replace("PM", "p.m.");
// Splitting the date string into day, month, and year  
let [day, month, year] = date.split("/");  
  
// Checking if the day and month have a single digit  
if (day.length === 1) {  
  day = "0" + day;  
}  
if (month.length === 1) {  
  month = "0" + month;  
}  
  
// Reconstructing the date in "dd/mm/yyyy" format  
 date = `${day}/${month}/${year}`;  
    if (type === "date") return date;
    else if (type === "type") return appointmentType;
    else if (type === "formatDate") return formattedDate;
    return formattedTime;
  };


  
  function calculateAge(dob) {
    const today = new Date();
    const birthDate = new Date(dob);

    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();

    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }

    return age;
  }

  const getPatientData = async (appointmentData,cancelledAppointments) => {
    try {
      
      let cancelledArray = [];
        const regex = /\*\*Demographic Information:\*\*(.*?)\*\*/s;

        const tempStore = appointmentData.hcdata.map((item) => {
          item = {
            name:
              item.customMetaData.member.mname !== ""
                ? item.customMetaData.member.fname +
                  " " +
                  item.customMetaData.member.mname +
                  " " +
                  item.customMetaData.member.lname +
                  " "
                : item.customMetaData.member.fname +
                  " " +
                  item.customMetaData.member.lname +
                  " ",
            nameForProfilePic: item.customMetaData.member.fname + " " + item.customMetaData.member.lname,
            summary: item.summarizedData.match(regex)[1].trim(),
            apcID: item.APCID,
            guid: item.guid,
            dob: item.customMetaData.member.dob,
            age: calculateAge(item.customMetaData.member.dob),
            appointmentDate:
              item.customMetaData.member.ScheduledDate,
            address: item.customMetaData.member.VisitAddress,
            status: item.status,

            appointmentType: convertToCST(
              item.customMetaData.member.ScheduledDate,
              "type"
            ),
            date: convertToCST(
              item.customMetaData.member.ScheduledDate,
              "date"
            ),
            time: convertToCST(
              item.customMetaData.member.ScheduledDate,
              "time"
            ),
            formattedDate: convertToCST(
              item.customMetaData.member.ScheduledDate,
              "formatDate"
            ),
            appointmentId: item.appointmentID,
            podcastTranscript: item.podcastTranscript,
            completeSummary: item.summarizedData,
            audioFile: item.generatedPodcastFileName,
            scores: item.scores
          };
          return item;
        });
        tempStore.sort((a, b) => {
          const dateA = new Date(a.appointmentDate);
          const dateB = new Date(b.appointmentDate);
          return dateA - dateB;
        });
        const removedNotificationAppointmentIDs = cancelledAppointments;
        tempStore.forEach((item) => {
          if (item.status === "Cancelled") {
            if (removedNotificationAppointmentIDs !== null) {
              if (!removedNotificationAppointmentIDs.includes(item.appointmentId)) {
                cancelledArray.push({
                  name: item.name,
                  guid: item.guid,
                  appointmentId: item.appointmentId,
                  date: (item.appointmentType === "TODAY" || item.appointmentType === "Today_Past") ? "today" :  item.formattedDate,
                  time: item.time,
                });
              }
            } else {
              cancelledArray.push({
                name: item.name,
                guid: item.guid,
                appointmentId: item.appointmentId,
                date: (item.appointmentType === "TODAY" || item.appointmentType === "Today_Past") ? "today" :  item.formattedDate,
                time: item.time,
              });
            }
          }
        });
      
        dispatch(saveNotificationArray(cancelledArray));
        setData((data) => [...tempStore]);
        setFilteredData((data) => [...tempStore]);
      
    } catch (e) {
      console.log(e);
    }
  };

  const handleSummaryNavigate = (data) => {
    setCurrentName(data.name);
    setCurrentNameForProfilePic(data.nameForProfilePic);
    setAppointmentID(data.appointmentId);
    setApcID(data.apcID);
    setGuid(data.guid);
    setAge(data.age);
    setCurrentAddress(data.address);
    setCurrentDate(data.date);
    setCurrentSummary(
      data.completeSummary === undefined ? "" : data.completeSummary
    );
    setCurrentAudioFile(data.audioFile);
    dispatch(saveIsSummaryOpen(true));
    props.setTranscript(data.podcastTranscript);
    dispatch(saveShouldRefresh(false));
  };

  const handleListenSummaryClick = (data) => {
    fetchAudioFile(data.audioFile);
    setCurrentName(data.name);
    setCurrentNameForProfilePic(data.nameForProfilePic);
    setCurrentAddress(data.address);
    setAge(data.age);
    setCurrentDate(data.date);
    setCurrentSummary(data.summary);
    setCurrentAudioFile(data.audioFile);
    props.setTranscript(data.podcastTranscript);
    dispatch(saveIsAudioFooterOpen(true));
  };

  const fetchAudioFile = (generatedPodcastFileName) => {
   

    FetchAudioURL(generatedPodcastFileName,(value)=>{
      setAudioData(generatedPodcastFileName === undefined ? "" : value);
      props.setAudioClient(
        generatedPodcastFileName === undefined ? "" : value
      );
    });

};

  return isSummaryOpen ? (
    <Summary
      name={currentName}
      nameForProfilePic = {currentNameForProfilePic}
      currentSummary={currentSummary}
      generatedPodcastFileName={currentAudioFile}
      setAudioClient={props.setAudioClient}
      setTranscript={props.setTranscript}
      guid={guid}
      apcID={apcID}
      age={age}
      appointmentID={appointmentID}
      openFooter = {true}
    />
  ) : (
    <div id="patientListPageContainer" className="container">
      <div
        id="patientListPageDiv"
        style={{
          marginTop: "80px",
          paddingLeft: "24px",
          paddingRight: "24px"
        }}
      >
        <MenuDrawer />
        <div style={{}}>
          <div style={{ width: "100%" }}>
            {notificationArray.map((item) => {
              return <CancelledNotification data={item} />;
            })}
          </div>
        </div>
        
        <div
          id="patientListDiv"
          style={{}}
        >
          <div id="patientListDivLabel">
            <label
              id="patientListLabel"
              style={{
                fontSize: 20,
                fontWeight: 700,
                color: "#001D5B",
                lineHeight: "26px",
              }}
            >
              Appointments
            </label>
          </div>
          <div id="appointmentsCountAndFilterDiv" style={{ display: "flex" }}>
            <div id="appointmentsCountLabelDiv" style={{ width: "50%" }}>
              <label
                id="appointmentsCountLabel"
                style={{
                  fontSize: 13,
                  fontWeight: 400,
                  color: "#001D5B",
                }}
              >
                {data.length} appointments
              </label>
            </div>
            <div
              id="filterButton"
              style={{ width: "40%", textAlign: "end", cursor: "pointer" }}
              onClick={(e) => {
                setIsFilterOpen((val) => !val);
              }}
            >
              <Filter_list />

              <label
                id="filterLabel"
                style={{ fontWeight: 700, color: "#001D5B" }}
              >
                filter
              </label>
            </div>
          </div>

          <FilterComponent
            ApplyFilter={ApplyFilter}
            isFilterOpen={isFilterOpen}
            setIsFilterOpen={setIsFilterOpen}
            todayFilter={todayFilter}
            tomorrowFilter={tomorrowFilter}
            yesterdayFilter={yesterdayFilter}
            setTodayFilter={setTodayFilter}
            setYesterdayFilter={setYesterdayFilter}
            setTomorrowFilter={setTomorrowFilter}
          />
          <hr></hr>
          {data.map((item, index) => (
            <div
              id={`appointmentListContainerDiv${index}`}
              data_id={item.guid}
              style={{ width: "95%", marginTop: "20px" }}
            >
              <div
                id="appointmentTimeDiv"
                className={
                  item.status === "Cancelled"
                    ? "cancelled_appointment"
                    : item.appointmentType === "PAST" ||
                      item.appointmentType === "Today_Past"
                    ? "past_appointment"
                    : item.appointmentType === "FUTURE"
                    ? "future_appointment"
                    : "todays_appointment"
                }
                style={{
                  marginBottom: "10px",
                  padding: "2px 8px",

                  display: "flex",
                  alignItems: "center",
                  width: "fit-content",
                }}
              >
                <div id="appointmentTimeIcon" style={{ marginRight: "5px" }}>
                  {item.status === "Cancelled" ? (
                    <ErrorOutlineIcon
                      style={{
                        height: "16px",
                        width: "16px",
                        color: "red",
                        paddingTop: "2px",
                      }}
                    />
                  ) : (
                    item.appointmentType === "TODAY" && (
                      <AccessTimeIcon
                        style={{
                          height: "16px",
                          width: "16px",
                          color: "#868686",
                          paddingTop: "3px",
                        }}
                      />
                    )
                  )}
                </div>
                <>
                  {item.appointmentType === "TODAY" ||
                  item.appointmentType === "Today_Past" ? (
                    <div
                      id="TodayAppointmentTimeValue"
                      style={{ marginBottom: "2px" }}
                    >
                      {item.status === "Cancelled" && "Appointment canceled:"}{" "}
                      Today at {item.time}
                    </div>
                  ) : (
                    <div
                      id="AppointmentDateValue"
                      style={{ marginBottom: "2px" }}
                    >
                      {item.status === "Cancelled" && "Appointment canceled:"}{" "}
                      {item.formattedDate} at {item.time}
                    </div>
                  )}
                </>
              </div>
              <div id="patientNameDivFlex" style={{ display: "flex" }}>
                <div id="patientNameDiv" style={{ width: "80%" }}>
                  <label
                    style={{
                      fontSize: 18,
                      fontWeight: 700,
                      color:
                        item.status === "Cancelled" ? "#7D7F81" : "#001D5B",
                      lineHeight: "26px",
                      textDecorationLine:
                        item.status === "Cancelled" && "line-through",
                    }}
                    id="patientNameDivLabel"
                  >
                    {item.name}
                  </label>
                </div>
              </div>
              <div id="patientAddressDiv">
                <label
                  id="patientAddressLabel"
                  style={{
                    fontSize: 13,
                    fontWeight: 700,
                    display: "flex",
                    textDecorationLine:
                      item.status === "Cancelled"
                        ? "line-through"
                        : "underline",
                    color:
                      item.status === "Cancelled"
                        ? "#7D7F81"
                        : "var(--primary-70, #0C55B8)",
                    lineHeight: "26px",
                    cursor: item.status !== "Cancelled" && "pointer",
                  }}
                  onClick={() => {
                    if (item.status !== "Cancelled") {
                      handleAddressClick(item.address);
                    }
                  }}
                >
                  {item.address}{" "}
                  {item.status !== "Cancelled" && (
                    <LaunchIcon style={{ paddingLeft: "4px" }} />
                  )}
                </label>
              </div>
              {item.status !== "Cancelled" && (
                <>
                  <div id="patientDetailsSummaryDiv">
                    <label
                      id="patientDetailsSummaryLabel"
                      style={{
                        fontSize: 16,
                        fontWeight: 400,
                        marginRight: "20px",
                        color: "#001D5B",
                        lineHeight: "26px",
                      }}
                    >
                      <ReactMarkdown
                        rehypePlugins={[rehypeRaw]}
                        children={item.summary}
                        className="my-custom-class"  
                      />
                      {/* {item.summary} */}
                    </label>
                  </div>
               {
                 (item.scores === undefined || Object.keys(item.scores).length === 0 || item.scores.overall_score*100 < process.env.REACT_APP_MLRBQualityThreshold) ?
                 <FlagContainer > <FlagIcon style={{paddingTop:'5px',minHeight:'16px',maxHeight:'16px',minWidth:'16px',maxWidth:'16px', color:'#4B4D4F'}} />
                    <FlagMessage >
                    The complete summary and audio are currently unavailable, please check back later. We apologize for the inconvenience. 
                    </FlagMessage>
                 </FlagContainer>
                 :
                  <div id="patientDetailsButtonsDiv">
                    <Button
                      id="patientDetailsSummaryButton"
                      variant="contained"
                      style={{
                        backgroundColor: "#001D5B",
                        borderRadius: "100px",
                        height: "34px",
                      }}
                      onClick={() => {
                        handleSummaryNavigate(item);
                      }}
                    >
                      Summary{" "}
                      <ArrowForwardIosIcon
                        id="patientDetailsSummaryButtonIcon"
                        style={{
                          fontSize: 14,
                          marginLeft: "3px",
                         
                        }}
                      />
                    </Button>
                    <Button
                      id="patientDetailsPlayButton"
                      variant="outlined"
                      style={{
                        backgroundColor: "white",
                        borderRadius: "100px",
                        height: "34px",
                        marginLeft: "20px",
                        color: "#001D5B",
                      }}
                      onClick={() => {
                        handleListenSummaryClick(item);
                      }}
                    >
                      {minPlayScreen ? "Play" : "Listen to Summary "}
                      <PlayArrowIcon
                        id="patientDetailsPlayButtonIcon"
                        style={{
                          fontSize: 22,
                        }}
                      />
                    </Button>
                  </div>}
                </>
              )}
              <br></br>
              {data.length - 1 !== index && <hr />}
            </div>
          ))}
        </div>
      </div>

      <div
        id="marginForFooterDiv"
        style={{
          height: "90px",
        }}
      ></div>

      <Footer />
      <br />
      <AudioPlayerFooter
        screen = "patientList"
        openSnackbar={isAudioFooterOpen}
        age={age}
        name={currentName}
        nameForProfilePic = {currentNameForProfilePic}
        src={audioData}
        isPaused1={false}
      />
      
    </div>
  );
};

export default PatientList;
