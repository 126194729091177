import axios from "../interceptors/interceptors";




export const GetAppointmentsList = async (role,apcID,cancelledAppointments, callback) => {
  callback = (callback instanceof Function) ? callback : () => { };

  const todayDate = new Date();
  const currentDate = new Date();
  const NextDay = new Date();
  let formattedNextDay = "";
  let formattedCurrentDate = "";
  let startDateChangeBy = process.env.REACT_APP_appointmentStartDate;
  let endDateChangeBy = process.env.REACT_APP_appointmentEndDate;
  let changeType = process.env.REACT_APP_dateVariabletype
  const options = { year: 'numeric', month: '2-digit', day: '2-digit' };  

  // console.log("wdgu",startDateChangeBy, typeof parseInt(startDateChangeBy));

  if(changeType === "dynamic"){
    NextDay.setDate(todayDate.getDate() + parseInt(endDateChangeBy));
    currentDate.setDate(todayDate.getDate() + parseInt(startDateChangeBy));
    formattedCurrentDate = currentDate.toLocaleDateString('es-CL', options).split('-').reverse().join('-');      
    formattedNextDay = NextDay.toLocaleDateString('es-CL', options).split('-').reverse().join('-')//.split('. ').join('-');
 
  }
  else if(changeType === "fixed")
  {
    formattedCurrentDate = startDateChangeBy
    formattedNextDay = endDateChangeBy;
    
  }
else{
  NextDay.setDate(todayDate.getDate() + 30);
  currentDate.setDate(todayDate.getDate() - 30);
  //the function toLocaleDateString use the default local data on the device.
  //es-CL: is to format the local date in the format dd-mm-yyyy

   formattedCurrentDate = currentDate.toLocaleDateString('es-CL', options).split('-').reverse().join('-');      
   formattedNextDay = NextDay.toLocaleDateString('es-CL', options).split('-').reverse().join('-')//.split('. ').join('-');
}
if(role === "Demo User" || role === "Client Manager" || role === "Devops Admin") {
  
  startDateChangeBy = '-30';
  endDateChangeBy = '30';
  NextDay.setDate(todayDate.getDate() + parseInt(endDateChangeBy));
  currentDate.setDate(todayDate.getDate() + parseInt(startDateChangeBy));
  formattedCurrentDate = currentDate.toLocaleDateString('es-CL', options).split('-').reverse().join('-');      
  formattedNextDay = NextDay.toLocaleDateString('es-CL', options).split('-').reverse().join('-')//.split('. ').join('-');
 }

    axios.post(process.env.REACT_APP_GetPatientsData,
      {
        apcId: apcID, //providerDetails.apcId,
        apptStartDate: formattedCurrentDate, //formattedCurrentDate,
        apptEndDate: formattedNextDay,
        patientId: "",
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then(response => callback(response.data,cancelledAppointments))
        .catch(() => callback(null))

};
