// import { styled } from '@mui/material/styles';
// import { createGenerateClassName, createStyles, makeStyles } from "@mui/styles";
// import { Theme } from "@mui/material/styles";

// NOTE: Mui4v5 this is used to prevent the makeStyles from mui v4 and v5
// from using the same auto generated class namespace

/* declare module "@mui/styles/defaultTheme" {
  interface DefaultTheme extends Theme {}
} */

/*
export const generateAppClassName = createGenerateClassName({
  productionPrefix: "app-",
});
*/

// NOTE: these values needs to match value in App.css
export const appMaxWidth = 1500;
export const appMinHeight = 1500;

export const AppThemeColorKeys = {
  lightGray: "lightGray",
  mediumGray: "mediumGray",
  darkGray: "darkGray",
  white: "white",
  black: "black",
  scrimBlack: "scrimBlack",
  transparent: "transparent",
};

export const appFonts = {
  regular: "OptumSansRegular",
  bold: "OptumSansBold",
  // bolder: "OptumSansXBold",
  // boldItalic: "OptumSansBoldItalic",
  // italic: "OptumSansRegularItalic",
};

// font weight mappings
export const appFontWeightMap = {
  light: 300,
  regular: 400,
  medium: 500,
  semibold: 600,
  bold: 700,
};

export const appAlphas = {
  a01: 0.1,
  a02: 0.2,
  a03: 0.3,
  a04: 0.4,
  a05: 0.5,
  a06: 0.6,
  a07: 0.7,
  a08: 0.8,
  a09: 0.9,
  scrim: 0.6,
};

export const appDialogTransparentPropsNoBlur = {
  style: {
    backgroundColor: "transparent",
    boxShadow: "none",
  },
};

export const appDialogTransparentPaperProps = {
  style: {
    backgroundColor: "transparent",
    boxShadow: "none",
    backdropFilter: "blur(10px)",
  },
};

export const appDialogTransparentPaperPropsCenter = {
  style: {
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "transparent",
    boxShadow: "none",
    backdropFilter: "blur(10px)",
  },
};

export const appColors = {
  white: "#FFFFFF",
  black: "#000000",
  offwhite: "#F5F5F5",
  transparent: "transparent",

  whiteA07: "rgba(255,255,255,0.7)",
  whiteA05: "rgba(255,255,255,0.5)",
  whiteA03: "rgba(255,255,255,0.3)",
  whiteA01: "rgba(255,255,255,0.1)",
  blackA07: "rgba(0,0,0,0.7)",
  blackA05: "rgba(0,0,0,0.5)",
  blackA03: "rgba(0,0,0,0.3)",
  blackA01: "rgba(0,0,0,0.1)",
  blackScrim: "rgba(0,0,0,0.6)",

  success: "#0B8D0F",
  success1: "#18A468",
  success2: "#0B8D0F",
  warn: "#F5B700",
  warn1: "#F5B700",
  warn2: "#F5B700",
  danger: "#EE0010",
  danger1: "#EE0010",
  danger2: "#EE0010",
  selection: "#0090F3",
  focus: "#FFFFFF",
  focusA07: "rgba(255, 255, 255, 0.7)",
  focusA05: "rgba(255, 255, 255, 0.5)",
  focusA03: "rgba(255, 255, 255, 0.3)",
  focusA01: "rgba(255, 255, 255, 0.1)",

  lightGray0: "#FAFAFA",
  lightGray1: "#9E9E9E",
  lightGray2: "#939393",
  mediumGray0: "#E5E5E6",
  mediumGray1: "#6E6E6E",
  mediumGray2: "#636363",
  darkGray1: "#222222",
  darkGray2: "#444444",
  darkError: "#F05973",

  textDark: "#4B4D4F",

  accent1: "#0C55B8",
  accent1A01: "rgba(12, 85, 184, 0.1)",
  accent2: "#002677",
  accent3: "#D9F6FA",
};

/*
export const makeGradient = (
  color1,
  color2,
  deg = "0deg",
  start = "0%",
  stop = "100%"
) => {
  return `linear-gradient(${deg}, ${color1} ${start}, ${color2} ${stop})`;
};
*/

export const appStyles = {
  posrel: {
    position: "relative",
  },
  posabs: {
    position: "absolute",
  },

  // layout and utility styles
  fillxy: {
    width: "100%",
    height: "100%",
  },
  fillx: {
    width: "100%",
  },
  filly: {
    height: "100%",
  },
  flexfill: {
    flex: "1 0 auto",
  },

  absxy: {
    position: "absolute",
    top: "0",
    left: "0",
    right: "0",
    bottom: "0",
  },
  absx: {
    position: "absolute",
    left: "0",
    right: "0",
  },
  absy: {
    position: "absolute",
    top: "0",
    bottom: "0",
  },

  noscroll: {
    overflow: "hidden",
  },
  scrollx: {
    overflowX: "auto",
    overflowY: "hidden",
  },
  scrolly: {
    overflowX: "hidden",
    overflowY: "auto",
  },

  clickable: {
    cursor: "pointer",
  },
  nopointer: {
    pointerEvents: "none",
  },

  flexr: {
    display: "flex",
    flexDirection: "row",
  },
  flexc: {
    display: "flex",
    flexDirection: "column",
  },
  nowrap: {
    flexWrap: "nowrap",
  },

  // for accessibility
  focusVisible: {
    backgroundColor: appColors.focusA01,
    boxShadow: `0 0 1px 1px ${appColors.focusA03}`,
  },

  // common text styles
  textLeft: { textAlign: "left" },
  textCenter: { textAlign: "center" },
  textRight: { textAlign: "right" },

  // elements
  hdivider: {
    marginTop: "20px",
    marginBottom: "20px",
    width: "100%",
    maxWidth: "100%",
    height: "3px",
    borderRadius: "5px",
    background: "rgba(255, 255, 255, 0.5)",
  },

  //
  // app specific custom styles
  //

  // text font styles
  fontRegular: {
    fontFamily: appFonts.regular,
    fontWeight: appFontWeightMap.regular,
  },
  fontBold: {
    fontFamily: appFonts.bold,
    fontWeight: appFontWeightMap.bold,
  },

  // internal navigation link
  navLink: {
    position: "relative",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    textDecoration: "none",

    "&.active": {},
    "&.active:hover,&:hover": {
      textDecoration: "underline",
    },
  },

  // external link
  extLink: {},
};

/*
// NOTE: makeStyles is using an index, which causes the makeStyles
// to get injected at this index in the generated stylesheet.
// we want component specific makeStyles to be able to override these classes
export const useAppTheme: () => any = makeStyles(createStyles(appStyles), {
  name: "appTheme",
  index: 0,
});

*/
