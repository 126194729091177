import { ResponsivePie } from "@nivo/pie";

const MyResponsivePie = ({ totalCompleted, totalScheduled, totalApptScheduled, totalRescheduled, totalCancelled }) => {
  const customColors = ["#00E396", "#ffff99", "#ffdb4d", "#ffad33", "#FF4560"];
  const data = [
    {
      id: "Assesment Sent to CIP",
      label: "Assesment Sent to CIP",
      value: totalCompleted,
      color: "hsl(120, 70%, 50%)",
    },
    {
      id: "Scheduled",
      label: "Scheduled",
      value: totalScheduled,
      color: "hsl(240, 70%, 50%)",
    },
    {
      id: "Appointment Scheduled",
      label: "Appointment Scheduled",
      value: totalApptScheduled,
      color: "hsl(240, 70%, 50%)",
    },
    {
      id: "Reschedule Member Called",
      label: "Reschedule Member Called",
      value: totalRescheduled,
      color: "hsl(240, 70%, 50%)",
    },
    {
      id: "cancelled",
      label: "Cancelled",
      value: totalCancelled,
      color: "hsl(0, 70%, 50%)",
    },
  ];

  return (
    <ResponsivePie
      colors={customColors}
      data={data}
      margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
      innerRadius={0.5}
      padAngle={0.7}
      cornerRadius={3}
      activeOuterRadiusOffset={8}
      borderWidth={1}
      borderColor={{
        from: "color",
        modifiers: [["darker", 0.2]],
      }}
      arcLinkLabelsSkipAngle={1}
      arcLinkLabelsTextColor="#333333"
      arcLinkLabelsThickness={2}
      arcLinkLabelsColor={{ from: "color" }}
      arcLabelsSkipAngle={7}
      enableArcLinkLabels={false}
      arcLabelsTextColor={{
        from: "color",
        modifiers: [["darker", 2]],
      }}
      defs={[
        {
          id: "dots",
          type: "patternDots",
          background: "inherit",
          color: "rgba(255, 255, 255, 0.3)",
          size: 4,
          padding: 1,
          stagger: true,
        },
        {
          id: "lines",
          type: "patternLines",
          background: "inherit",
          color: "rgba(255, 255, 255, 0.3)",
          rotation: -45,
          lineWidth: 6,
          spacing: 10,
        },
      ]}
      fill={[
        {
          match: {
            id: "ruby",
          },
          id: "dots",
        },
        {
          match: {
            id: "c",
          },
          id: "dots",
        },
        {
          match: {
            id: "go",
          },
          id: "dots",
        },
        {
          match: {
            id: "python",
          },
          id: "dots",
        },
        {
          match: {
            id: "scala",
          },
          id: "lines",
        },
        {
          match: {
            id: "lisp",
          },
          id: "lines",
        },
        {
          match: {
            id: "elixir",
          },
          id: "lines",
        },
        {
          match: {
            id: "javascript",
          },
          id: "lines",
        },
      ]}
    />
  );
};

export default MyResponsivePie;
