import React from "react";
import { useEffect, useState, useRef } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { isMobile } from "react-device-detect";
import "./style.css";
import axios from "../../interceptors/interceptors";
import useWindowSize from "../../CustomHooks/useWindow";
import { Button, Tooltip } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  saveNotificationArray, saveShouldRefresh, saveCurrentScreen
} from "../../features/uiSlice";
import AudioPlayerFooter from "./AudioPlayerFooter";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import SummarizeIcon from "@mui/icons-material/Summarize";
import ReactMarkdown from "react-markdown";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import LaunchIcon from "@mui/icons-material/Launch";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import rehypeRaw from "rehype-raw";
import SummaryDrawer from "./DrawerForSummary";
import { GetAppointmentsList } from "../../api/GetAppointmentsList";
import { FetchCancelledAppointments } from "../../api/FetchCancelledAppointments";
import { FetchAudioURL } from "../../api/FetchAudioURL";
import { ReactComponent as FlagIcon } from "../../assets/flag.svg";
import { OpenNativeMaps } from "../../api/OpenNativeMaps";


const PatientList = (props) => {
  const { minPlayScreen } = useWindowSize();
  const providerDetails = useSelector(
    (state) => state.userDetails.providerDetails
  );
  const searchInput = useSelector((state) => state.userDetails.searchInput);
  const refresh = useSelector((state) => state.userDetails.refresh);
  const dispatch = useDispatch();
  const hasMounted = useRef(false); // This ref will keep track of whether the component has mounted 
  const [data, setData] = useState([]);
  const [isSummaryOpen, setIsSummaryOpen] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [isSummaryPageOpen, setIsSummaryPageOpen] = useState(false);
  const [currentName, setCurrentName] = useState("Test User");
  const [currentNameForProfilePic, setCurrentNameForProfilePic] = useState("Test User");
  const [currentAddress, setCurrentAddress] = useState("");
  const [currentDate, setCurrentDate] = useState("");
  const [currentSummary, setCurrentSummary] = useState("");
  const [selectedData, setSelectedSummary] = useState({});
  const [currentAudioFile, setCurrentAudioFile] = useState("");
  const [audioData, setAudioData] = useState("");
  const [appointmentID, setAppointmentID] = useState("");
  const [apcID, setApcID] = useState("");
  const [guid, setGuid] = useState("");
  const [age, setAge] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [showSummary, setShowSummary] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  useEffect(() => {
    dispatch(saveCurrentScreen("patientList"));
    // If the component has mounted before, only fetch data if `refresh` is true  
    if (hasMounted.current) {
      if (refresh) FetchData();
    } else {
      // If the component hasn't mounted before, fetch data without checking `refresh`  
      FetchData();
      hasMounted.current = true; // Set the ref to true after fetching data on mount  
    }

  }, [refresh, dispatch]);

  const FetchData = () => {
    FetchCancelledAppointments(JSON.parse(window.sessionStorage.getItem("UserOnehealthID")), (value) => {
      console.log("cancelledAppointments", value);
      fetchAppointmentsList(value);

    });
  };
  const fetchAppointmentsList = (cancelledAppointments) => {
    const apcID_forAPI = (providerDetails.role === "Demo User" ||
      providerDetails.role === "APC" || providerDetails.role === "Client Manager") ? providerDetails.apcId : "";
    GetAppointmentsList(providerDetails.role,apcID_forAPI, cancelledAppointments?.appointmentIds, (value, cancelledAppointments) => {
      getAppointments(value, cancelledAppointments);
    })
  };



  useEffect(() => {
    filterData(searchInput);
  }, [searchInput]);

  const filterData = (searchInput) => {
    if (!searchInput) {
      setFilteredData(data);
    } else {
      const lowerSearchInput = searchInput.toLowerCase();
      const filtered = data.filter(
        (item) =>
          item.name && item.name.trim().toLowerCase().includes(lowerSearchInput)
      );
      setFilteredData(filtered);
    }
  };




  const getAppointments = async (appointmentData, cancelledAppointments) => {
    try {

      let cancelledArray = [];
      const regex = /\*\*Demographic Information:\*\*(.*?)\*\*/s;

      const tempStore = appointmentData.hcdata.map((item) => {
        item = {
          name:
            item.customMetaData.member.mname !== ""
              ? item.customMetaData.member.fname +
              " " +
              item.customMetaData.member.mname +
              " " +
              item.customMetaData.member.lname +
              " "
              : item.customMetaData.member.fname +
              " " +
              item.customMetaData.member.lname +
              " ",
          nameForProfilePic: item.customMetaData.member.fname + " " + item.customMetaData.member.lname,
          summary: item.summarizedData.match(regex)[1].trim(),
          apcID: item.APCID,
          guid: item.guid,
          dob: item.customMetaData.member.dob,
          age: calculateAge(item.customMetaData.member.dob),
          appointmentDate: item.customMetaData.member.ScheduledDate,
          address: item.customMetaData.member.VisitAddress,
          status: item.status,

          appointmentType: convertToCST(
            item.customMetaData.member.ScheduledDate,
            "type"
          ),
          date: convertToCST(
            item.customMetaData.member.ScheduledDate,
            "date"
          ),
          time: convertToCST(
            item.customMetaData.member.ScheduledDate,
            "time"
          ),
          formattedDate: convertToCST(
            item.customMetaData.member.ScheduledDate,
            "formatDate"
          ),
          appointmentId: item.appointmentID,
          podcastTranscript: item.podcastTranscript,
          completeSummary: item.summarizedData,
          audioFile: item.generatedPodcastFileName,
          scores: item.scores
        };
        return item;
      });

      tempStore.sort((a, b) => {
        const dateA = new Date(a.appointmentDate);
        const dateB = new Date(b.appointmentDate);
        return dateA - dateB;
      });
      const removedNotificationAppointmentIDs = cancelledAppointments;
      tempStore.forEach((item) => {
        if (item.status === "Cancelled") {
          if (removedNotificationAppointmentIDs !== null) {
            if (!removedNotificationAppointmentIDs.includes(item.appointmentId)) {
              cancelledArray.push({
                name: item.name,
                guid: item.guid,
                appointmentId: item.appointmentId,
                date: item.formattedDate,
                time: item.time,
              });
            }
          } else {
            cancelledArray.push({
              name: item.name,
              guid: item.guid,
              appointmentId: item.appointmentId,
              date: item.formattedDate,
              time: item.time,
            });
          }
        }
      });

      dispatch(saveNotificationArray(cancelledArray));
      setData((data) => [...tempStore]);
      setFilteredData((data) => [...tempStore]);
    } catch (e) {
      console.log(e);
    }
  };

  const convertToCST = (val, type) => {
    const appointmentTime = new Date(val);
    const currentTime = new Date();
    const ScheduledTime = val; // Get current date and time

    const localCurrentTime = currentTime.toLocaleString([], {
      dateStyle: "short",
      timeStyle: "short",
    });

    const appointmentLocalTime = appointmentTime.toLocaleString([], {
      dateStyle: "short",
      timeStyle: "short",
    });
    const dateToCompare = appointmentLocalTime.split(", ")[0];
    const dateAndTime = ScheduledTime.split(" ");
    const date = dateAndTime[0];
    const time =
      dateAndTime[1].split(":")[0] +
      ":" +
      dateAndTime[1].split(":")[1] +
      " " +
      dateAndTime[2];

    const formatDate = new Date(date);

    const options = { month: "short", day: "numeric" };
    const formattedDate = formatDate.toLocaleDateString("en-US", options);

    const dateAndTimeCurrent = localCurrentTime.split(", ");
    const currentDate = dateAndTimeCurrent[0];
    let appointmentType = "";

    // Compare date and time with current date and time
    if (appointmentTime < currentTime) {
      appointmentType = dateToCompare === currentDate ? "Today_Past" : "PAST";
    } else if (
      dateToCompare === currentDate // Compare only the date part
    ) {
      appointmentType = "TODAY";
    } else {
      appointmentType = "FUTURE";
    }

    // Convert AM/PM to lowercase
    const formattedTime = time.replace("AM", "a.m.").replace("PM", "p.m.");

    if (type === "date") return date;
    else if (type === "type") return appointmentType;
    else if (type === "formatDate") return formattedDate;
    return formattedTime;
  };

  function calculateAge(dob) {
    const today = new Date();
    const birthDate = new Date(dob);

    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();

    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }

    return age;
  }

  const handleSummaryNavigate = (data, e) => {

    setSelectedSummary(data);
    setCurrentName(data.name);
    setCurrentNameForProfilePic(data.nameForProfilePic);
    setAppointmentID(data.appointmentId);
    setApcID(data.apcID);
    setGuid(data.guid);
    setAge(data.age);
    setCurrentAddress(data.address);
    setCurrentDate(data.date);
    setCurrentSummary(
      data.completeSummary === undefined ? "" : data.completeSummary
    );
    setCurrentAudioFile(data.audioFile);
    setIsSummaryPageOpen(true);
    toggleDrawer("right", true, e);
    setIsSummaryOpen(true);
    props.setTranscript(data.podcastTranscript);
    dispatch(saveShouldRefresh(false));

  };

  const handleListenSummaryClick = (data) => {
    fetchAudioFile(data.audioFile);
    setCurrentName(data.name);
    setCurrentNameForProfilePic(data.nameForProfilePic);
    setCurrentAddress(data.address);
    setAge(data.age);
    setCurrentDate(data.date);
    setCurrentSummary(data.summary);
    setCurrentAudioFile(data.audioFile);
    props.setTranscript(data.podcastTranscript);
    setOpenSnackbar(true);

  };

  const fetchAudioFile = (generatedPodcastFileName) => {


    FetchAudioURL(generatedPodcastFileName, (value) => {
      setAudioData(generatedPodcastFileName === undefined ? "" : value);
      props.setAudioClient(
        generatedPodcastFileName === undefined ? "" : value
      );
    });

  };

  const handleChangePage = (event, newPage) => {

    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {


    setRowsPerPage(+event.target.value);
    setPage(0);
  };


  const handleAddressClick = (address) => {
    OpenNativeMaps(address, (value) => {
      // console.log("OpenNativeMaps",value);

    });
  };

  const toggleDrawer = (anchor, open, event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setShowSummary({ ...showSummary, [anchor]: open });
  };

  const columns = [
    {
      label: "Appointment Time",
      id: "time",
      key: "time",
      width: "100px",
      minWidth: "100px",
      maxWidth: "100px",

    },
    {
      label: "Patient Details",
      id: "Patient_Details",
      key: "Patient_Details",

    },
    {
      label: "Address",
      id: "address",
      key: "address",

    },

    {
      label: "Listen Summary",
      id: "Listen_summary",
      key: "Listen_summary",
    },

    {
      label: "Open Summary",
      id: "Open_summary",
      key: "Open_summary",

    },


  ];

  return (
    <div id="desktop-root" style={{ marginTop: "100px", marginLeft: "50px" }}>

      <div id="desktop-1" style={{ display: "flex", position: "relative" }}>
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer sx={{ maxHeight: "100%" }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      style={{
                        width: "160px",
                        borderRight: "1px solid #CBCCCD",
                        fontSize: "16px",
                        fontWeight: "700",
                        color: "#002677",
                        backgroundColor: "azure",
                        minWidth: column.minWidth,
                        maxWidth: column.minWidth,
                      }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredData
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((item, index) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={item.code}
                      >
                        {columns.map((column) => {
                          const value = item[column.id];

                          return (
                            <TableCell
                              style={{
                                width: "160px",
                                minWidth: column.minWidth,
                                maxWidth: column.minWidth,
                                borderRight: "1px solid #CBCCCD",
                                background: index % 2 === 0 && "#f8fdff",
                              }}
                              key={column.id}
                            >
                              {column.id === "time" ? (
                                <div
                                  id="appointmentTimeDiv"
                                  className={
                                    item.status === "Cancelled"
                                      ? "cancelled_appointment"
                                      : item.appointmentType === "PAST" ||
                                        item.appointmentType === "Today_Past"
                                        ? "past_appointment"
                                        : item.appointmentType === "FUTURE"
                                          ? "future_appointment"
                                          : "todays_appointment"
                                  }
                                  style={{
                                    marginBottom: "10px",
                                    padding: "2px 8px",

                                    display: "flex",
                                    alignItems: "center",
                                    width: "fit-content",
                                  }}
                                >
                                  <div
                                    id="appointmentTimeIcon"
                                    style={{ marginRight: "5px" }}
                                  >
                                    {item.status === "Cancelled" ? (
                                      <ErrorOutlineIcon
                                        style={{
                                          height: "16px",
                                          width: "16px",
                                          color: "red",
                                          paddingTop: "2px",
                                        }}
                                      />
                                    ) : (
                                      item.appointmentType === "TODAY" && (
                                        <AccessTimeIcon
                                          style={{
                                            height: "16px",
                                            width: "16px",
                                            color: "#868686",
                                            paddingTop: "3px",
                                          }}
                                        />
                                      )
                                    )}
                                  </div>
                                  <>
                                    {item.appointmentType === "TODAY" ||
                                      item.appointmentType === "Today_Past" ? (
                                      <div
                                        id="TodayAppointmentTimeValue"
                                        style={{ marginBottom: "2px" }}
                                      >
                                        {item.status === "Cancelled" &&
                                          "Appointment canceled:"}{" "}
                                        Today at {item.time}
                                      </div>
                                    ) : (
                                      <div
                                        id="AppointmentDateValue"
                                        style={{ marginBottom: "2px" }}
                                      >
                                        {item.status === "Cancelled" &&
                                          "Appointment canceled:"}{" "}
                                        {item.formattedDate} at {item.time}
                                      </div>
                                    )}
                                  </>
                                </div>
                              ) : column.id === "Patient_Details" ? (
                                <>
                                  <div
                                    id="patientNameDivFlex"
                                    style={{ display: "flex" }}
                                  >
                                    <div
                                      id="patientNameDiv"
                                      style={{ width: "80%" }}
                                    >
                                      <label
                                        style={{
                                          fontSize: 18,
                                          fontWeight: 700,
                                          color:
                                            item.status === "Cancelled"
                                              ? "#7D7F81"
                                              : "#001D5B",
                                          lineHeight: "26px",
                                          textDecorationLine:
                                            item.status === "Cancelled" &&
                                            "line-through",
                                        }}
                                        id="patientNameDivLabel"
                                      >
                                        {item.name}
                                      </label>
                                    </div>
                                  </div>

                                  {item.status !== "Cancelled" && (
                                    <div id="patientDetailsSummaryDiv">
                                      <label
                                        id="patientDetailsSummaryLabel"
                                        style={{
                                          fontSize: 12,
                                          fontWeight: 500,
                                          marginRight: "20px",
                                          color: "#001D5B",
                                          lineHeight: "26px",
                                        }}

                                      >
                                        <ReactMarkdown
                                          rehypePlugins={[rehypeRaw]}
                                          children={item.summary}
                                        />

                                      </label>
                                    </div>
                                  )}
                                </>
                              ) : column.id === "address" ? (
                                <div id="patientAddressDiv">
                                  <Tooltip title={item.address}>
                                    <label
                                      id="patientAddressLabel"
                                      style={{
                                        fontSize: 13,
                                        fontWeight: 700,

                                        textDecorationLine:
                                          item.status === "Cancelled"
                                            ? "line-through"
                                            : "underline",
                                        color:
                                          item.status === "Cancelled"
                                            ? "#7D7F81"
                                            : "var(--primary-70, #0C55B8)",
                                        lineHeight: "26px",
                                        cursor:
                                          item.status !== "Cancelled" &&
                                          "pointer",
                                      }}
                                      onClick={() => {
                                        if (item.status !== "Cancelled") {
                                          handleAddressClick(item.address);
                                        }
                                      }}
                                    >

                                      {item.address.length > 28
                                        ? `${item.address.substring(0, 28)}...`
                                        : item.address}
                                      {item.status !== "Cancelled" && (
                                        <LaunchIcon
                                          style={{ paddingLeft: "2px" }}
                                        />
                                      )}
                                    </label>
                                  </Tooltip>
                                </div>
                              ) : column.id === "Listen_summary" ? (

                                <Button
                                  id="patientDetailsPlayButton"
                                  variant="outlined"
                                  style={{
                                    backgroundColor: "white",
                                    borderRadius: "100px",
                                    height: "10%",
                                    marginLeft: "20px",
                                    color: "#001D5B",
                                  }}
                                  onClick={() => {
                                    handleListenSummaryClick(item);
                                  }}
                                >
                                  {minPlayScreen
                                    ? "Play"
                                    : "Listen to Summary "}
                                  <PlayArrowIcon
                                    id="patientDetailsPlayButtonIcon"
                                    style={{
                                      fontSize: 22,

                                    }}
                                  />
                                </Button>
                              ) : column.id === "Open_summary" ? (

                                <Button
                                  id="patientDetailsPlayButton"
                                  variant="outlined"
                                  style={{
                                    backgroundColor: "white",
                                    borderRadius: "100px",
                                    height: "10%",
                                    marginLeft: "20px",
                                    color: "#001D5B",
                                  }}
                                  onClick={(e) => {
                                    handleSummaryNavigate(item, e);
                                  }}
                                >
                                  {minPlayScreen ? "Summary" : "View Summary "}
                                  <SummarizeIcon
                                    id="patientDetailsSummaryButtonIcon"
                                    style={{
                                      fontSize: 22,
                                      marginLeft: "5px",

                                    }}
                                  />
                                </Button>
                              ) : (
                                value
                              )}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[
              5,
              10,
              25,
              { label: "All", value: data.length },
            ]}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
        {isSummaryOpen && <SummaryDrawer
          setShowSummary={setShowSummary}
          showSummary={showSummary}
          currentSummary={currentSummary}
          age={age}
          name={currentName}
          nameForProfilePic={currentNameForProfilePic}
          generatedPodcastFileName={currentAudioFile}
        />}
        <AudioPlayerFooter
          screen="patientList"
          openSnackbar={openSnackbar}
          age={age}
          name={currentName}
          nameForProfilePic={currentNameForProfilePic}
          setOpen={setOpenSnackbar}
          src={audioData}
          isPaused1={false}
        />
      </div>
    </div>
  );
};

export default PatientList;
