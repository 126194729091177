import { ResponsiveLine } from '@nivo/line';

const MyResponsiveLine = ({ data }) => {
    const isDataValid = () => {
        if (!data || data.length === 0) return false;
        if (!data[0].data || data[0].data.length === 0) return false;
        return true;
    };

    const getNumberOfDays = () => {
        if (!isDataValid()) return 0;
        const startDate = new Date(data[0].data[0].x);
        const endDate = new Date(data[0].data[data[0].data.length - 1].x);
        const timeDifference = endDate.getTime() - startDate.getTime();
        const daysDifference = timeDifference / (1000 * 3600 * 24);
        return daysDifference;
    };

    const getCustomTickValues = () => {
        if (!isDataValid()) return [];
        const daysDifference = getNumberOfDays();
        if (daysDifference <= 7) return data[0].data.map((item) => item.x);
        return [data[0].data[0].x, data[0].data[data[0].data.length - 1].x];
    };

    return isDataValid() ? (
        <ResponsiveLine
            data={data}
            animate={false}
            colors={['#00b359']}
            margin={{ top: 50, right: 110, bottom: 70, left: 60 }}
            xScale={{ type: 'point' }}
            yScale={{
                type: 'linear',
                min: 'auto',
                max: 'auto',
                stacked: true,
                reverse: false,
            }}
            yFormat=" >-.2f"
            axisTop={null}
            axisRight={null}
            axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: 'appointment date',
                legendOffset: 36,
                legendPosition: 'middle',
                tickValues: getCustomTickValues(data),
            }}
            axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: 'score',
                legendOffset: -50,
                legendPosition: 'middle',
                truncateTickAt: 0,
            }}
            pointSize={7}
            pointColor={{ theme: 'background' }}
            pointBorderWidth={2}
            pointBorderColor={{ from: 'serieColor' }}
            pointLabelYOffset={-12}
            useMesh={true}
            legends={[
                {
                    anchor: 'bottom-right',
                    direction: 'column',
                    justify: false,
                    translateX: 100,
                    translateY: 0,
                    itemsSpacing: 0,
                    itemDirection: 'left-to-right',
                    itemWidth: 80,
                    itemHeight: 20,
                    itemOpacity: 0.75,
                    symbolSize: 12,
                    symbolShape: 'circle',
                    symbolBorderColor: 'rgba(0, 0, 0, .5)',
                    effects: [
                        {
                            on: 'hover',
                            style: {
                                itemBackground: 'rgba(0, 0, 0, .03)',
                                itemOpacity: 1,
                            },
                        },
                    ],
                },
            ]}
        />
    ) : null
};

export default MyResponsiveLine;  
