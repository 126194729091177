import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { Grid, Button } from "@mui/material";
import TextsmsIcon from "@mui/icons-material/Textsms";
import { ReactComponent as MenuSvg } from "./icons/menu.svg";
import DescriptionIcon from "@mui/icons-material/Description";
import FeaturedPlayListIcon from "@mui/icons-material/FeaturedPlayList";
import DashboardCustomizeIcon from "@mui/icons-material/DashboardCustomize";
import { appColors, appStyles } from "./Theme/AppTheme";
import Popover from "@mui/material/Popover";
import { saveIsMenuOpen } from "../features/uiSlice";
import { useSelector, useDispatch } from "react-redux";
import WatchLaterIcon from '@mui/icons-material/WatchLater';

const menuItemH = 55;

const styles = {
  root: {
    ...appStyles.fontBold,
    fontSize: "18px",
    color: appColors.accent1,
    textAlign: "left",

    "& .MuiListItemIcon-root svg .st0": {
      fill: appColors.accent1,
    },
  },
  linkItem: {
    position: "relative",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "stretch",
    alignSelf: "center",
    flex: "1 0 auto",
    margin: 0,
    padding: 0,
    width: "100%",
    height: "100%",

    "& .ns-navlink": {
      ...appStyles.navLink,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "flex-start",
      width: "100%",
      height: "100%",
      minHeight: `${menuItemH}px`,
      backgroundColor: "transparent",
      borderRadius: "8px",
      textDecoration: "none",

      "&.active": {
        backgroundColor: appColors.accent1A01,
        textDecoration: "none",
      },
      "&.active:hover,&:hover": {
        backgroundColor: appColors.blackA01,
        textDecoration: "none",
      },
    },
  },
  icon: {
    margin: "0 15px",

    "& svg .st0": {
      color: appColors.accent1,
      fill: appColors.accent1,
    },
  },
  label: {
    margin: "0 5px",
    color: appColors.accent1,
  },
};

const NavSidebar = () => {
  const dispatch = useDispatch();
  const providerDetails = useSelector(
    (state) => state.userDetails.providerDetails
  );
  const [openSubmenu, setOpenSubmenu] = useState(false); // Add state to track the submenu open/close

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    dispatch(saveIsMenuOpen(true));
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const menuItems = [
    {
      label: "Appointment Tab",
      link: "/Summary",
      icon: <FeaturedPlayListIcon style={{ color: "#0C55B8" }} />,
    },
    ...(providerDetails.role === "Client Manager"  
      ? []  
      : [  
          {  
            label: "Manage User Access",  
            link: "/ManageUserAccess",  
            icon: <TextsmsIcon style={{ color: "#0C55B8" }} />,  
          },  
        ]),  
    {
      label: "Client Reporting",
      link: "/APCApp",
      icon: <DescriptionIcon style={{ color: "#0C55B8" }} />,
    },
    {
      label: "User Logs",
      link: "/UserLogs",
      icon: <WatchLaterIcon style={{ color: "#0C55B8" }} />,
      },
      {
      label: "Analytics Dashboard",
      link: "/Dashboard",
      icon: <DashboardCustomizeIcon style={{ color: "#0C55B8" }} />,
    },
  ];



  const handleMenuClick = (link) => {
    setOpenSubmenu(true);
    handleClose();
  };

  return (
    <div>
      <Button
        id="buttonMenu"
        style={{ backgroundColor: "white" }}
        aria-describedby={id}
        variant="contained"
        onClick={handleClick}
      >
        <MenuSvg />
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Grid
          id="gridMenuItems"
          container
          direction="column"
          justifyContent="flex-start"
          sx={styles.root}
        >
          {menuItems.map((item, idx) => (
            <Grid
              id={`gridMenuItem${idx}`}
              item
              container
              key={idx}
              sx={styles.linkItem}
              style={{ display: "contents" }}
            >
              {item.submenuItems ? (
                <>
                  {/* Render main menu item */}

                  {/* Render submenu items if the submenu is open */}
                  {openSubmenu &&
                    item.submenuItems.map((subItem, subIdx) => (
                      <div id={`divSubmenuItem${subIdx}`} key={subIdx}>
                        {/* Render submenu items */}
                      </div>
                    ))}
                </>
              ) : (
                // Render regular menu items without submenu
                <NavLink
                  id={`navlinkMenuItem${idx}`}
                  to={item.link}
                  className="ns-navlink"
                  style={{ paddingLeft: "20px", paddingRight: "20px" }}
                  onClick={() => handleMenuClick(item.link)}
                >
                  <Grid container direction="row" sx={appStyles.fillxy}>
                    <Grid item sx={styles.icon}>
                      {item.icon}
                    </Grid>
                    <Grid item sx={styles.label}>
                      {item.label}
                    </Grid>
                  </Grid>
                </NavLink>
              )}
            </Grid>
          ))}
        </Grid>
      </Popover>
    </div>
  );
};

export default NavSidebar;
