import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

const AppointmentWidget = ({ title, count, color, bgColor }) => {
  return (
    <Card
      sx={{
        minWidth: "50%",
        maxWidth: "75%",
        backgroundColor: bgColor || "rgba(255, 255, 255, 0.3)",
        border: "2px solid white",
      }}
    >
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
        }}
      >
        <div style={{ fontWeight: "bold" }}>
          <label style={{ fontSize: "14px" }} color="text.secondary">
            {title}
          </label>
        </div>
        <Typography
          variant="h5"
          color={color}
          sx={{
            margin: 0,
            padding: 0,
            lineHeight: 3,
            alignSelf: "flex-end",
          }}
        >
          {count}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default AppointmentWidget;  
