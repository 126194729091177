import React from "react";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import profilePic from "../../assets/bgimage4.jpg";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import SummaryFeedback from "./SummaryFeedback";
import { FetchAudioURL } from "../../api/FetchAudioURL";
import styled from "styled-components";
import "./style.css";

const Root = styled.div`
  padding: 0 16px;
  margin-top: 40px;
  margin-bottom: 64px;
  `

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 32px;
  color: #001D5B;
  font-family: "Optum Sans", Helvetica, Arial;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px; 
  text-transform: capitalize;

  & img {
    width: 100%;
    height: 100%;
    border-radius: 87px;
    border: 0.5px solid var(--neutral-80, #4B4D4F);
  }

  & > div:last-child {
    display: none;

    width: 87px;
    height: 87px;
    visible: 
    & div {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 40px;
      color: #4B4D4F;
    }
  }
  `

const Body = styled.div`
  margin-bottom: 40px;
  `

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  bottom: "50%",
  transform: "translate(-50%, -50%)",
  width: 625,
  height: 600,
  bgcolor: "background.paper",
  overflow: "scroll",
  // border: "1px solid #000",
  // boxShadow: 24,
  p: 4,
};

const style2 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70%",
  bgcolor: "background.paper",
  display: "flex",
  padding: "16px 24px",
  alignItems: "flex-start",
  alignContent: "flex-start",
  gap: "16px",
  flexWrap: "wrap",
  borderRadius: "12px",
  boxShadow: "0px 4px 12px 0px rgba(25, 25, 26, 0.16)",

  p: 4,
};

const PatientSummary = ({
  name,
  initials,
  currentSummary,
  generatedPodcastFileName,
  setAudioClient,
  transcript,
  guid,
  apcID,
  age,
  appointmentID,
}) => {

  const location = useLocation();
  const [patientData, setPatinetData] = useState();
  const [audioData, setAudioData] = useState("");

  const currentScreen = useSelector((state) => state.userDetails.currentScreen);

  useEffect(() => {
    if (currentScreen?.toUpperCase() !== 'SUMMARY') return;

    window.scrollTo(0, 0);
  },[currentScreen])

  useEffect(() => {
   if(currentSummary!=="") fetchAudioFile(generatedPodcastFileName);
  }, [currentSummary]);

  useEffect(() => {
    if (location.state) {
      const { data } = location.state;
      setPatinetData(data);
    }
  }, [location.state]);

  const fetchAudioFile = (generatedPodcastFileName) => {
    FetchAudioURL(generatedPodcastFileName, (value) => {
      setAudioData(generatedPodcastFileName === undefined ? "" : value);
      setAudioClient(generatedPodcastFileName === undefined ? "" : value);
    });
  };

  return (
    <Root id="SummaryRootDiv" >

      <Header id="SummaryPage_NameContainer">
        <div>
          {(name?.trim() || '') + ', ' + age}
        </div>

        <div id="SummaryPage_ProfilePicContainer" className="image-container">
          <img
            id="SummaryPage_ProfilePic"
            src={profilePic}
            alt="Profile Pic"
          />
          <div id="SummaryPage_ProfileIconText">
            {initials}
          </div>
        </div>
      </Header>

      <Body>
        <ReactMarkdown
          rehypePlugins={[rehypeRaw]}
          children={currentSummary}
        />
      </Body>

      <SummaryFeedback
        guid={guid}
        apcID={apcID}
        age={age}
        appointmentID={appointmentID}
      />
    </Root>
  );
};

export default PatientSummary;
