import React from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { App } from "antd";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import AddchartIcon from "@mui/icons-material/Addchart";
import PieChart from "../Charts/PieChart";

const bull = (
  <Box
    component="span"
    sx={{ display: "inline-block", mx: "2px", transform: "scale(0.8)" }}
  >
    •
  </Box>
);

const AppointmentWidget = () => {
  return (
    <>
      <Card
        sx={{
          minWidth: "21%",
          maxWidth: "23%",
          backgroundColor: "rgba(255, 255, 255, 0.3)",
          border: "2px solid white",
        }}
      >
        <CardContent>
          <div style={{ marginBottom: "20px" }}>
            <lable>
              <PendingActionsIcon style={{ color: "blue", fontSize: "20px" }} />
            </lable>
            <label style={{ fontSize: "16px" }} color="text.secondary">
              Total Appointments
            </label>
            <label style={{ marginLeft: "16px" }}>
              <AddchartIcon style={{ color: "darkgoldenrod" }} />
            </label>
          </div>
          <Typography variant="h5" component="div">
            {/* be{bull}nev{bull}o{bull}lent */}
          </Typography>

          <Typography variant="h5" color="green">
            275
          </Typography>
          {/* <PieChart /> */}
        </CardContent>
        {/* <CardActions></CardActions> */}
      </Card>
    </>
  );
};

export default AppointmentWidget;
