import React, { useContext, useEffect, useRef } from "react";
import TypingAnimation from "./TypingAnimation";
// import add from "../ChatSection/img/img.png";
import "./style.scss";
import "./styles.scss";

const TypingAnimationChatBot = ({ message, loader }) => {
  // const { data } = useContext();

  const ref = useRef(null);

  useEffect(() => {
    // console.log("message.text",message.text);
    ref.current?.scrollIntoView({ behavior: "smooth" });
  }, []);

  return (
    <div ref={ref} className={`message ${"GPT"}`}>
      <div className="messageInfo">
        <img
          src={
            "https://yt3.ggpht.com/a/AATXAJxu9w5u9ArDMIFTUyWejlB_59h6ZX_wRi_zgg=s900-c-k-c0xffffffff-no-rj-mo"
          }
          alt={""}
        />
      </div>
      <div className="messageContent" style={{ overflowWrap: "anywhere" }}>
        {/* <p >
          {message.text}
          
          </p> */}
        <TypingAnimation />

        {/* <div className="messageContent" dangerouslySetInnerHTML={{__html:message.text}}></div> */}
        {/* <img
          src={
            "https://media.istockphoto.com/vectors/purple-user-icon-in-the-circle-a-solid-gradient-vector-id1095289632?k=20&m=1095289632&s=612x612&w=0&h=_glHdsV95Q3uZHTpFNIeaJpzGMpy6fplJP5G6YUgfmk="
          }
          alt={add}
        /> */}
      </div>
    </div>
  );
};

export default TypingAnimationChatBot;
