import React, { useState, useEffect } from "react";
import { Box, Button, Grid } from "@mui/material";
import { ReactComponent as CloxeXSvg } from "../../assets/close-x.svg";
import { ReactComponent as SendSvg } from "../../assets/send.svg";
import CustomSearchInput from "../custom-search-input/CustomSearchInputForChatGPT";
// import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { useDispatch, useSelector } from "react-redux";
// import { appColors, appFonts } from "../../theme";
import Chat from "../ChatCorner/Chat";
import axios from "../../interceptors/interceptors";

const styles = {
  panelWrapper: {
    position: "absolute",
    top: "10px",
    right: "45px",
    bottom: "250px",
    minWidth: "90%",
    maxWidth: "90%",
    minHeight: "75%",
    maxHeight: "80%",
    backgroundColor: "white",
    borderRadius: "12px",
    margin: 0,
  },
  panel: {
    position: "relative",
    height: "100%",
  },
  header: {
    position: "relative",
    width: "100%",
    padding: "15px 30px",
  },
  content: {
    position: "relative",
    width: "100%",
    flex: "1 0 auto",
    padding: "15px 30px",
    borderTop: `1px solid grey`,
    borderBottom: `1px solid grey`,
    overflowY: "auto",
  },
  actions: {
    position: "relative",
    width: "100%",
    padding: "15px 30px",
  },
  headerTitle: {
    fontFamily: "sans-serif",
    fontSize: "32px",
    fontWeight: "700",
    padding: "5px 0",
  },
  headerText: {
    fontFamily: "sans-serif",
    fontSize: "16px",
    padding: "5px 0",
  },
  headerCloseButton: {
    position: "absolute",
    top: 0,
    right: 0,
  },
};

// interface ChatWidgetProps {
//   onClose?: () => {};
//   FaxFileIds?:any,
// }

const ChatWidget = () => {
  const dynamicPrompt = useSelector((state) => state.userDetails.dynamicPrompt);
  const currentScreen = useSelector((state) => state.userDetails.currentScreen);
  console.log("dynamicPrompt", dynamicPrompt, currentScreen);
  const handleClickOnPrompt = () => {
    console.log("qwedb,eb.qea");
  };
  let tempStr = "";

  dynamicPrompt.forEach((item, index) => {
    tempStr =
      index === 0
        ? tempStr + "<li style={{marginTop:'10px}}>" + item + "</li>"
        : tempStr + "<li style={{marginTop:'10px}}>" + item + "</li>";
  });

  console.log("tempStr", tempStr);
  // Create a temporary div element
  const tempDiv = document.createElement("div");

  // Set the innerHTML of the temporary div with the message
  tempDiv.innerHTML = tempStr;

  // Get all the list items within the temporary div
  const listItems = tempDiv.getElementsByTagName("li");

  // Convert the HTMLCollection to an array to use forEach()
  Array.from(listItems).forEach((item, index) => {
    // Attach a click event listener to each list item
    item.addEventListener("click", function () {
      // Handle the click event here
      console.log(`Clicked on Message ${index + 1}`);
    });
  });

  // Get a reference to your UI element
  const uiElement = document.getElementById("your-ui-element");

  // Check if the UI element exists before appending
  if (uiElement) {
    // Append the temporary div to your UI element
    uiElement.appendChild(tempDiv);
  } else {
    console.error("UI element not found");
  }

  // let tempStr = "<a href ='' onClick((e)=>{handleClickOnPrompt()})>HI THEWRE</a>"
  // const dispatch = useAppDispatch();
  const [loader, setLoader] = useState(false);
  const [messages, setMessages] = useState(
    currentScreen === "fax-workflow-manual-edit" &&
      dynamicPrompt.length > 0 &&
      dynamicPrompt[0] !== ""
      ? [
          {
            id: "2",
            // text: <p >ChatGPT can answer a wide variety of questions related to data analysis using all the knowledge sets available within the selected application. Please refer the <a href="/KnowledgeBase">Knowledge sets</a>  and advanced Data exploration capabilities.</p>,
            text: "ChatGPT is an AI-powered assistant that has access to a diverse range of Easy Check Application related Knowledge Base categories such as Pilot Stats, Patient and Appointment, Incident, Email Errors, and Communication Service.",
            senderId: "Assistant",
          },
          {
            id: "2",
            // text: <p >ChatGPT can answer a wide variety of questions related to data analysis using all the knowledge sets available within the selected application. Please refer the <a href="/KnowledgeBase">Knowledge sets</a>  and advanced Data exploration capabilities.</p>,
            text: "<b>Here are some sample prompts:</b>",
            senderId: "Assistant",
          },
          {
            id: "2",
            // text: <p >ChatGPT can answer a wide variety of questions related to data analysis using all the knowledge sets available within the selected application. Please refer the <a href="/KnowledgeBase">Knowledge sets</a>  and advanced Data exploration capabilities.</p>,
            text: tempStr,
            senderId: "Assistant",
          },
        ]
      : [
          {
            id: "2",
            // text: <p >ChatGPT can answer a wide variety of questions related to data analysis using all the knowledge sets available within the selected application. Please refer the <a href="/KnowledgeBase">Knowledge sets</a>  and advanced Data exploration capabilities.</p>,
            text: "ChatGPT is an AI-powered assistant that has access to a diverse range of Easy Check Application related Knowledge Base categories such as Pilot Stats, Patient and Appointment, Incident, Email Errors, and Communication Service.",
            senderId: "Assistant",
          },
        ]
  );
  // const { onClose, FaxFileIds } = props;

  // useEffect(() => {
  //   // console.log("FaxFileIds",FaxFileIds, typeof FaxFileIds);
  // }, [FaxFileIds]);

  const setMsgState = async (value) => {
    // console.log("user:", value + '. Please make it point wise answere and beautify the result.');
    const currentMessages = [...messages];
    let tempUser = {
      id: "2",
      text: value,
      senderId: "User",
    };
    setMessages([...currentMessages, tempUser]);
    setLoader(true);
    try {
      const response = await axios.post(
        "https://westus2.api.cognitive.microsoft.com/language/:analyze-conversations?api-version=2022-10-01-preview",
        {
          kind: "Conversation",
          analysisInput: {
            conversationItem: {
              id: "PARTICIPANT_ID_HERE",
              text: value,
              modality: "text",
              language: "en-us",
              participantId: "PARTICIPANT_ID_HERE",
            },
          },
          parameters: {
            projectName: "GenerativeEval",
            verbose: true,
            deploymentName: "GenerativeEval",
            stringIndexType: "TextElement_V8",
          },
        },
        {
          headers: {
            "Ocp-Apim-Subscription-Key": "6a383ef0777148f5b85451c901b5aa8d",
            "Apim-Request-Id": "4ffcac1c-b2fc-48ba-bd6d-b69d9942995a",
            "Content-Type": "application/json",
          },
        }
      );
      let topIntentArray = [];
      response.data.result.prediction.intents.forEach((item) => {
        if (item.confidenceScore > 0.8) {
          topIntentArray.push(item.category);
        }
      });
      let topIntent = []; //response.data.result.prediction.topIntent;//[]
      if (topIntentArray.length >= 3) {
        topIntent = topIntentArray.slice(0, 3);
      } else {
        topIntent = topIntentArray;
      }

      // console.log("topIntent",topIntent);
      const gptresponse = await axios.post(
        "https://faxautomation-orchestrator.azurewebsites.net/ask",
        {
          gptOptions: {
            engine: "gpt-4",
            temperature: 0,
            maxTokens: 5000,
          },
          cognitiveSearchApikey: process.env.REACT_APP_CogApiKey,
          patientId: "85400628",
          prompt:
            value +
            ". Please beautify the result by adding point wise answere with html tags and inline styling. Please complete the answeres by closing all the tags.", //"Give me a summary of incident data?",
          intent: topIntentArray, //currentScreen==="fax-workflow-manual-edit" ? ["FA-ListData"] : topIntent,//"AIOPS-Incident"
          processingType: "FaxReport",
          // FileIDs: FaxFileIds.fileIds,
          // FileTypes: FaxFileIds.fileTypes,
          Data: "",
        }
      );

      let responseMessage = gptresponse.data.message;

      // if(topIntent.includes("AIOPS-Graph")){
      //     const gptData1 = gptresponse.data.message;
      //     const jsonStartIndex = gptData1.indexOf("<!DOCTYPE html>");
      //     const jsonEndIndex = gptData1.lastIndexOf("</html>");
      //     const urlGraph = gptData1.substring(jsonStartIndex + 1, jsonEndIndex);
      //     responseMessage= urlGraph;
      // }

      if (responseMessage.includes("https://quickchart.io/chart?c={")) {
        // console.log(responseMessage)
        const gptData = gptresponse.data.message;
        const jsonStartIndex = gptData.indexOf("(");
        const jsonEndIndex = gptData.lastIndexOf(")");
        const urlGraph = gptData.substring(jsonStartIndex + 1, jsonEndIndex);
        // setdynamicGraphString(urlGraph);
      }

      // console.log("GPT Response:", gptresponse.data)

      const tempAssistent = {
        id: "2",
        text: gptresponse.data.message, //'Top Intent:' + topIntent,
        senderId: "Assistant",
      };
      setMessages([...currentMessages, tempUser, tempAssistent]);
      setLoader(false);
    } catch (e) {
      setLoader(false);
      console.log(e);
    }
  };

  const handleCloseClicked = () => {
    // if (onClose) {
    //   onClose();
    // }
  };

  return (
    <Box sx={styles.panelWrapper}>
      <Grid
        container
        sx={styles.panel}
        direction="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        wrap="nowrap"
        flexWrap="nowrap"
      >
        <Grid item sx={styles.header}>
          <Grid container direction="column">
            <Grid item sx={styles.headerTitle}>
              Ask AI
            </Grid>
            <Grid item sx={styles.headerText}>
              ChatGPT is an AI-powered assistant, ask a question or describe
              what you're looking for.
            </Grid>
          </Grid>
          <Button
            sx={styles.headerCloseButton}
            variant="text"
            onClick={handleCloseClicked}
          >
            {/* <CloxeXSvg /> */}
          </Button>
        </Grid>
        <Grid item flex="1 0 auto" sx={styles.content}>
          {/* Body */}
          <Chat messages={messages} loader={loader} type="AskAI" />
        </Grid>
        <Grid item sx={styles.actions}>
          <CustomSearchInput
            size="medium"
            fullWidth={true}
            setMsgState={setMsgState}
            placeholder="Describe what you're looking for"
            searchIcon={<SendSvg />}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

ChatWidget.defaultProps = {};

export default ChatWidget;
