import React, { useState } from "react";
import useId from "@mui/utils/useId/useId";
import {
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  OutlinedInputProps,
} from "@mui/material";
import { ReactComponent as SearchIconSvg } from "../../assets/search.svg";
// import { updateSearchText } from '../../features/ui/uiSlice';
// import { useAppDispatch,useAppSelector } from '../../app/hooks';
import { useDispatch } from "react-redux";

const styles = {
  root: {},
  labelArea: {
    fontSize: "16px",
    fontWeight: 700,
  },
  controlArea: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
  },
  formControl: {
    width: "100%",
    maxWidth: "600px",
  },
  searchInput: {
    width: "100%",

    "&.MuiOutlinedInput-root": {
      backgroundColor: "#ffffff",
      borderRadius: "999px",

      "& fieldset": {
        "& legend": {},
        "&.MuiOutlinedInput-notchedOutline": {
          borderRadius: "999px",
        },
      },
    },
  },
  searchIcon: {},
};

// interface CustomSearchInputProps extends OutlinedInputProps {
//   searchIcon?: React.ReactElement;
//   setMsgState?: Function;
// }

const CustomSearchInput = (props) => {
  const dispatch = useDispatch();
  const labelId = useId();
  const inputId = useId();
  const [text, setText] = useState("");
  const [img, setImg] = useState(null);

  const handleSend = async () => {
    // console.log("text", text);
    props.setMsgState(text);
    setText("");
    setImg(null);
  };

  const {
    label,
    value,
    defaultValue = undefined,
    placeholder,
    size,
    setMsgState,
    fullWidth,
    searchIcon,
    ...inputProps
  } = props;
  const renderedValue = value !== undefined ? value : defaultValue;

  const renderedEndAdornment = searchIcon ? (
    <InputAdornment sx={styles.searchIcon} position="end">
      {searchIcon}
    </InputAdornment>
  ) : undefined;

  const handleSearchClicked = () => {};

  return (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      sx={styles.root}
    >
      {label && (
        <Grid item sx={styles.labelArea}>
          <InputLabel id={labelId} htmlFor={inputId}>
            {label}
          </InputLabel>
        </Grid>
      )}
      <Grid item sx={styles.controlArea}>
        <FormControl size="small" sx={styles.formControl}>
          <OutlinedInput
            id={inputId}
            sx={styles.searchInput}
            type="text"
            size={size}
            fullWidth={fullWidth}
            color="primary"
            multiline={false}
            label={null}
            notched={false}
            placeholder={placeholder}
            endAdornment={renderedEndAdornment}
            {...inputProps}
            onChange={(e) => {
              // callAPIForFaxes();
              // dispatch(updateSearchText(e.target.value));
              setText(e.target.value);
            }}
            onKeyPress={(e) => {
              // console.log("rndkn",e.key);
              if (e.key === "Enter") {
                handleSend();
              }
            }}
            value={text}
          />
        </FormControl>
      </Grid>
    </Grid>
  );
};

CustomSearchInput.defaultProps = {
  label: undefined,
  value: undefined,
  defaultValue: undefined,
  placeholder: undefined,
  size: "small",
  fullWidth: true,
  searchIcon: <SearchIconSvg />,
};

export default CustomSearchInput;
